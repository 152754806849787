import ReactDOM from 'react-dom';
import './scss/index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as clearServiceWorkersAndCashes from './clearServiceWorkersAndCashes';
import reportWebVitals from './reportWebVitals';
import { GlobalUserState } from './hooks/useGlobalUserState';
import { GlobalFilesState } from './hooks/useGlobalFilesState';

import Home from './routes/Home';
import WhatIsDstor from './routes/product/WhatIsDstor';
import WhyDstor from './routes/product/WhyDstor';
import UserGuide from './routes/product/UserGuide';
import Pricing from './routes/Pricing';
import About from './routes/company/About';
import Careers from './routes/company/Careers';
import Documentation from './routes/developers/Documentation';
import StorageNode from './routes/developers/StorageNode';

import Register from './routes/Register';
import Users from './routes/account/Users';
import Settings from './routes/account/Settings';
import Security from './routes/account/Security';
import Notifications from './routes/account/Notifications';
import Funding from './routes/billing/Funding';
import Usage from './routes/billing/Usage';
import Files from './routes/Files';
import Search from './routes/Search';
import SpeedTest from './routes/SpeedTest';
import ChangePassword from './routes/ChangePassword';
import Invoices from './routes/billing/Invoices';
import PrivacyPolicy from './routes/about/PrivacyPolicy';
import TermsOfUse from './routes/about/TermsOfUse';
import Nodes from './routes/account/Nodes';
import People from './routes/account/People';
import NotFound from './routes/NotFound';
import Encryption from './routes/account/Encryption';
import ApiKeys from './routes/account/ApiKeys';
import { CookiesProvider } from 'react-cookie';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Organizations from './routes/account/Organizations';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

clearServiceWorkersAndCashes.clear();

ReactDOM.render(
  <CookiesProvider>
    <GlobalUserState>
      <GlobalFilesState>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route
              path='/about/privacy-policy'
              element={<PrivacyPolicy />}
            ></Route>
            <Route path='/about/terms-of-use' element={<TermsOfUse />}></Route>
            <Route
              path='/product/what-is-dstor'
              element={<WhatIsDstor />}
            ></Route>
            <Route path='/product/why-dstor' element={<WhyDstor />}></Route>
            <Route path='/product/user-guide' element={<UserGuide />}></Route>
            <Route path='/pricing' element={<Pricing />}></Route>
            <Route path='/company/about' element={<About />}></Route>
            <Route path='/company/careers' element={<Careers />}></Route>
            <Route
              path='/developers/documentation'
              element={<Documentation />}
            ></Route>
            <Route
              path='/developers/storage-node'
              element={<StorageNode />}
            ></Route>
            <Route path='/change-password' element={<ChangePassword />}></Route>
            <Route path='/files' element={<Files />}>
              <Route path='/files/*' element={<Files />}></Route>
            </Route>
            <Route path='/search' element={<Search />}></Route>
            <Route path='/speed-test' element={<SpeedTest />}></Route>
            <Route path='/register' element={<Register />}></Route>

            <Route path='/account/users' element={<Users />}></Route>
            <Route path='/account/security' element={<Security />}></Route>
            <Route path='/account/settings' element={<Settings />}></Route>
            <Route path='/account/nodes' element={<Nodes />}></Route>
            <Route path='/account/people' element={<People />}></Route>
            <Route
              path='/account/organizations'
              element={<Organizations />}
            ></Route>
            <Route path='/account/encryption' element={<Encryption />}></Route>
            <Route path='/account/api-keys' element={<ApiKeys />}></Route>
            <Route
              path='/account/notifications'
              element={<Notifications />}
            ></Route>
            <Route path='/billing/funding' element={<Funding />}></Route>
            <Route path='/billing/usage' element={<Usage />}></Route>
            <Route path='/billing/invoices' element={<Invoices />}></Route>
            <Route path='*' element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </GlobalFilesState>
    </GlobalUserState>
  </CookiesProvider>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();
reportWebVitals();
