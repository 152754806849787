import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
export interface PropsShape {
  title: string;
  text: string;
  link?: string;
  image?: any;
  imageAlign?: 'left' | 'right';
  mode?: 'light' | 'dark';
}

const TextImageMicro = ({
  title,
  text,
  link,
  image,
  imageAlign = 'left',
  mode,
}: PropsShape): ReactElement => {
  const navigate = useNavigate();

  const doClick = (): void => {
    if (link && link.substring(0, 8) === 'https://') {
      window.open(link, '_blank');
      return;
    }
    navigate(`${link}`);
  };
  const textAlign = imageAlign === 'right' ? 'left' : 'right';
  return (
    <div className={`TextImageMicro ${mode}`} onClick={doClick}>
      <div
        className={`TextImageMicro--center ${mode}`}
        style={{
          gridTemplateColumns: `${
            imageAlign === 'right' ? ' 1fr 35px' : ' 35px 1fr'
          }`,
        }}
      >
        <div
          className={`TextImageMicro--text ${mode}`}
          style={{ gridArea: `${textAlign}` }}
        >
          <h1 className={`TextImageMicro--title ${mode}`}>{title}</h1>
          <div></div>
          <p className={`TextImageMicro--text ${mode}`}>{text}</p>
        </div>
        <div
          className={`TextImageMicro--image ${mode}`}
          style={{ gridArea: `${imageAlign}` }}
        >
          <img src={image} width={35} height={35} />
        </div>
      </div>
    </div>
  );
};
export { TextImageMicro };
