import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import SideMenu from '../SideMenu';
import { Heading } from '../Heading';
import { StepOne, StepThree, StepTwo } from './Steps';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export interface PropsShape {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface OrganizationShape {
  name: string;
}

const AddOrganizationModal = ({
  isOpen,
  setIsOpen,
}: PropsShape): ReactElement => {
  const [step, setStep] = useState<number>(0);
  const [transitionClassName, setTransitionClassName] = useState<string>('');
  const nextStep = (): void => {
    setTransitionClassName('AddOrganizationModal--stepTransitionLeft');
    setStep(step + 1);
  };

  const prevStep = (): void => {
    setTransitionClassName('AddOrganizationModal--stepTransitionRight');
    setStep(step - 1);
  };

  const steps = [
    <StepOne nextStep={nextStep} />,
    <StepTwo nextStep={nextStep} />,
    <StepThree prevStep={prevStep} />,
  ];

  const generateSubtitle = (): string => {
    switch (step) {
      case 0:
        return 'Step 1: Enter details about your organization';
      case 1:
        return 'Step 2: Please, enter your payment information so that we can issue billing invoices';
      case 2:
        return 'Step 3: Please, enter a username, full name or email to invite a user to your organization';
      default:
        return '';
    }
  };

  return (
    <SideMenu
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position='CENTER'
      width='REGULAR'
    >
      <div className='Header--newfolder'>
        <Heading title='Add Organization' subtitle={generateSubtitle()} />
        <TransitionGroup className='AddOrganizationModal--steps'>
          {
            <CSSTransition
              key={step}
              timeout={300}
              classNames={transitionClassName}
            >
              {steps[step]}
            </CSSTransition>
          }
        </TransitionGroup>
      </div>
    </SideMenu>
  );
};
export { AddOrganizationModal };
