import { ReactElement } from 'react';

export interface PointShape {
  title: string;
  text: string;
}

export interface PropsShape {
  pointOne: PointShape;
  pointTwo: PointShape;
  pointThree: PointShape;
  color: string;
}

const ThreePoints = ({
  pointOne,
  pointTwo,
  pointThree,
  color,
}: PropsShape): ReactElement => {
  return (
    <div className='ThreePoints'>
      <div className='ThreePoints--center'>
        <div>
          <div style={{ color }} className='ThreePoints--title'>
            {pointOne.title}
          </div>
          <div style={{ color }}>{pointOne.text}</div>
        </div>
        <div>
          <div style={{ color }} className='ThreePoints--title'>
            {pointTwo.title}
          </div>
          <div style={{ color }}>{pointTwo.text}</div>
        </div>
        <div>
          <div style={{ color }} className='ThreePoints--title'>
            {pointThree.title}
          </div>
          <div style={{ color }}>{pointThree.text}</div>
        </div>
      </div>
    </div>
  );
};
export { ThreePoints };
