import {
  createContext,
  useContext,
  useState,
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { filesStateShape } from '../interfaces/files';

interface PropsShape {
  children: ReactElement;
}

interface StateShape {
  filesState: filesStateShape;
  setFilesState: Dispatch<SetStateAction<filesStateShape>>;
}

const initVal: StateShape = {
  filesState: JSON.parse(`${localStorage.getItem('filesState')}`) || {},
  setFilesState: () => {},
};

const GlobalContext = createContext<StateShape>(initVal);

const useGlobalFilesState = (): StateShape => {
  return useContext(GlobalContext);
};

const GlobalFilesState = ({ children }: PropsShape): ReactElement => {
  const [filesState, setFilesState] = useState<filesStateShape>(
    initVal.filesState
  );

  useEffect(() => {
    localStorage.setItem('filesState', JSON.stringify(filesState));
  }, [filesState]);

  useEffect(() => {
    const filesStateString = localStorage.getItem('filesState');
    filesStateString && setFilesState(JSON.parse(filesStateString));
  }, []);

  const value = {
    filesState,
    setFilesState,
  };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export { GlobalFilesState, useGlobalFilesState };
