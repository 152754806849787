import { ReactElement } from 'react';

export interface PropsShape {
  title: string;
  subTitle: string;
  mode?: 'light' | 'dark';
  children?: any;
  align?: 'left' | 'center' | 'right';
}

const Title = ({
  title,
  subTitle,
  mode,
  children,
  align = 'center',
}: PropsShape): ReactElement => {
  return (
    <div className={`Title ${mode}`}>
      <div className={`Title--center ${mode}`}>
        <h1 className={`Title--title ${mode}`} style={{ textAlign: align }}>
          {title}
        </h1>
        <div></div>
        {subTitle && (
          <h2
            className={`Title--sub-title ${mode}`}
            style={{ textAlign: align }}
          >
            {subTitle}
          </h2>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};
export { Title };
