const isEmail = (email: string): boolean => {
  try {
    if (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    return false;
  } catch (error) {
    return false;
  }
};

const isUuid = (uuid: string): boolean => {
  try {
    if (uuid) {
      const re =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
      return re.test(String(uuid));
    }
    return false;
  } catch (error) {
    return false;
  }
};

const isPassword = (password: string): boolean => {
  try {
    if (password) {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
      return re.test(String(password));
    }
    return false;
  } catch (error) {
    return false;
  }
};

const isUrl = (string: string): boolean => {
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
};

const isIpAddress = (ipAddress: string): boolean => {
  try {
    if (ipAddress) {
      const re =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return re.test(String(ipAddress));
    }
    return false;
  } catch (error) {
    return false;
  }
};

export { isEmail, isUuid, isPassword, isUrl, isIpAddress };
