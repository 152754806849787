import { ReactElement } from 'react';
import { Header } from '../../components/Header';
import { Title } from '../../components/Title';
import { Footer } from '../../components/Footer';
import { Background } from '../../components/Background';
import { ThreePoints } from '../../components/ThreePoints';
import { SplitCenter } from '../../components/SplitCenter';
import { TextImage } from '../../components/TextImage';
import greenTick from '../../images/green-tick.svg';

function WhatIsDstor(): ReactElement {
  return (
    <div className='WhatIsDStor'>
      <Header />
      <Background color='#F8FAFC' borderTop>
        <>
          <Title
            title='What is dStor?'
            subTitle='Fast, Affordable & Easy Global Cloud Storage'
          />
        </>
      </Background>
      <Background color='#30385E'>
        <>
          <ThreePoints
            color='#FFFFFF'
            pointOne={{
              title: 'Green',
              text: 'By using excess capacity from underutilized, dStor lowers the carbon footprint of cloud storage. Better using the infrastructure we already have is better that building massive new data centers.',
            }}
            pointTwo={{
              title: 'Efficient',
              text: "Storing multiple copies of your data around the world protects from loss and downtime while improving the speed of access for users in all global regions. Now that's efficient!",
            }}
            pointThree={{
              title: 'Inexpensive',
              text: "Your cloud storage bill shouldn't be holding back your business or making you decide where you can afford to operate. dStor lets you be everywhere, for 80% less than the major providers",
            }}
          />
        </>
      </Background>
      <Background color='#fff'>
        <SplitCenter>
          <div>
            <Title
              align='left'
              title='Affordable'
              subTitle="Your cloud storage bill shouldn't be holding back your business or making you decide where you can afford to operate. dStor lets you be everywhere, for 80% less than the major providers"
            />
          </div>
          <div>
            <Title
              align='left'
              title='Secure'
              subTitle="You can't afford to lose a single file so neither can we. dStor stores multiple copies of your data around the world, monitors its status and automatically refreshes and forward-replicates your data as needed."
            />
          </div>
        </SplitCenter>
        <SplitCenter>
          <div>
            <TextImage
              imageAlign='left'
              title='Efficient'
              text="Storing multiple copies of your data around the world protects from loss and downtime while improving the speed of access for users in all global regions. Now that's efficient!"
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Easy'
              text="dStor manages all the complexities of where to store your data around the world, regularly checking that it's instantly available and tracking usage, so all you have to do is upload your data. You don't have to make deals with individual storage providers as other decentralized storage systems require."
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Green'
              text='By using excess capacity from underutilized, dStor lowers the carbon footprint of cloud storage. Better using the infrastructure we already have is better that building massive new data centers.'
              image={greenTick}
              padding='12px'
            />
          </div>
          <div>
            <TextImage
              imageAlign='left'
              title='Geographic security'
              text="Storing your data in various locations around the world secures it against extreme weather, power outages and other risks. Your data is safe because it's not all in one place."
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='File persistence security'
              text="Even the largest Cloud storage providers sometimes lose data but dStor ensures that all your data is where you expect it to be. With dStor's multiple instances of every file, any single copy that goes missing is quickly replicated by other storage nodes in the system."
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Access control'
              text='Some data you want to share with the world, but some you want to be in control of who can see. dStor is the only decentralized system that gives you this level of control over your data. '
              image={greenTick}
              padding='12px'
            />
          </div>
        </SplitCenter>
      </Background>

      <Background color='#FFFFFF'>
        <Footer />
      </Background>
      <div className='Pricing--center'></div>
    </div>
  );
}

export default WhatIsDstor;
