import { ReactElement } from 'react';

export interface PropsShape {
  data: {
    name: string;
    image?: any;
    position: string;
    link: string;
  }[];
}

const Team = ({ data }: PropsShape): ReactElement => {
  const doClick = (link: string): any => {
    window.open(link, '_blank');
  };
  return (
    <div className='Team'>
      <div className='Team--center'>
        {data.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className='Team--item'
              onClick={(): void => doClick(item.link)}
            >
              <div className='Team--name'>{item.name}</div>
              <div className='Team--position'>{item.position}</div>
              <img src={item.image} className='Team--image' />
            </div>
          );
        })}
      </div>
      <div className='Team--center-text'>
        dStor is GoodBlock Technologies Inc., a Delaware C-corporation. Our
        business address is 1420 NW Gilman Blvd, #2264, Issaquah, WA 98027. You
        can reach us with questions at info@dstor.cloud.
      </div>
    </div>
  );
};
export { Team };
