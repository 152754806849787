import { ReactElement, useState, useEffect } from 'react';
import SideMenu from '../components/SideMenu';
import { Heading } from '../components/Heading';
import { TextInput } from '../components/TextInput';
import { Button } from '../components/Button';
import { Password } from '../components/Password';
import { ServerError } from '../components/ServerError';
import { isEmail, isPassword, isUuid } from '../functions/validators';
import { callApi } from '../functions/callApi';
import { useNavigate } from 'react-router-dom';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { useSearchParams } from 'react-router-dom';

function Register(): ReactElement {
  const navigate = useNavigate();
  const { setUserState } = useGlobalUserState();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [inviteCode, setInviteCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordMatch, setPasswordMatch] = useState<boolean>(false);

  const signIn = async (): Promise<void> => {
    try {
      const loginResult = await callApi<any>(
        'auth/local/login',
        'POST',
        JSON.stringify({
          email,
          password,
        })
      );
      if (loginResult.access_token) {
        setUserState((prevState: any) => ({
          ...prevState,
          currentOrganization: null,
          data: {
            ...prevState.data,
            loggedIn: true,
            accountKey: loginResult.account_key,
            accessToken: loginResult.access_token,
            name: loginResult.owner_name,
            email: loginResult.owner_email,
            lastUpload: Date.now(),
            userRole: loginResult.account_role.type,
            has_credit_card: loginResult.has_credit_card,
            pulse: {
              accountDeletedMail: loginResult.account_deleted_mail,
              accountDeletedPulse: loginResult.account_deleted_pulse,
              accountFrozenMail: loginResult.account_frozen_mail,
              accountFrozenPulse: loginResult.account_frozen_pulse,
              failedPaymentMail: loginResult.failed_payment_mail,
              failedPaymentPulse: loginResult.failed_payment_pulse,
              invoiceGeneratedMail: loginResult.invoice_generated_mail,
              invoiceGeneratedPulse: loginResult.invoice_generated_pulse,
              noPaymentMethodMail: loginResult.no_payment_method_mail,
              noPaymentMethodPulse: loginResult.no_payment_method_pulse,
              successfulPaymentMail: loginResult.successful_payment_mail,
              successfulPaymentPulse: loginResult.successful_payment_pulse,
              thresholdNotReachedMail: loginResult.threshold_not_reached_mail,
              thresholdNotReachedPulse: loginResult.threshold_not_reached_pulse,
            },
          },
        }));
        navigate('/files');
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const join = async (): Promise<void> => {
    setLoading(true);
    setError('');
    if (!isEmail(email)) {
      setLoading(false);
      setError('Invalid email');
    } else if (!isUuid(inviteCode)) {
      setLoading(false);
      setError('Invalid invite code');
    } else {
      try {
        const result = await callApi<any>(
          'auth/local/register',
          'POST',
          JSON.stringify({
            email,
            inviteCode,
            password,
          })
        );
        if (result.status === 200 && result.account_key) {
          signIn();
        } else if (
          result.status === 400 &&
          result.message === 'Invalid invite code'
        ) {
          setError('Email and invite code dont match');
          setLoading(false);
        } else if (
          result.status === 400 &&
          result.message.includes('There is already an account for')
        ) {
          setError('Account already exists');
          setLoading(false);
        } else if (
          result.message.startsWith('body.password should match pattern')
        ) {
          setError('Please check your password. It has not been validated');
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.log('API never returns an error', error);
        // TODO: Ensure API returns an error
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setError('');
    email && inviteCode && password ? setDisabled(false) : setDisabled(true);
  }, [email, inviteCode, password]);

  useEffect(() => {
    setInviteCode(searchParams.get('dev') || '');
    setEmail(searchParams.get('email') || '');
  }, []);

  useEffect(() => {
    if (confirmPassword === password) setPasswordMatch(true);
    else setPasswordMatch(false);
  }, [password, confirmPassword]);

  useEffect(() => {
    let validNewPass: boolean = false;
    validNewPass = isPassword(password);
    setDisabled(!(validNewPass && passwordMatch));
  }, [passwordMatch, passwordMatch]);

  return (
    <div className='Register'>
      <SideMenu isOpen={true} setIsOpen={(): void => {}} position='CENTER'>
        <>
          <div className='Register--modal'>
            <Heading
              title='You&#39;re In!'
              subtitle={`To activate your account, create and confirm a secure password and click the button below.`}
            />
            <ServerError error={error} />
            <TextInput
              label
              type='email'
              name='Email'
              value={email}
              placeHolder='Email'
              setValue={setEmail}
              focus
            />
            <TextInput
              label
              type='text'
              name='Invite Code'
              value={inviteCode}
              placeHolder='Invite code'
              setValue={setInviteCode}
              disabled={true}
            />
            <Password
              label
              name='Password'
              value={password}
              placeHolder='Password'
              setValue={setPassword}
              strong
            />
            <div>
              <Password
                name='Confirm Password'
                label
                value={confirmPassword}
                setValue={setConfirmPassword}
              />
              <div
                style={{
                  color: passwordMatch ? 'green' : 'red',
                  paddingLeft: '1px',
                }}
              >
                {confirmPassword === ''
                  ? ''
                  : passwordMatch
                  ? 'Password match'
                  : 'Password mismatch'}
              </div>
            </div>
            <Button
              name='Join dStor'
              click={join}
              loading={loading}
              disabled={disabled}
            />
            <p className='Register--agree'>
              By joining you agree to our{' '}
              <a href='/about/terms-of-service'>
                <span>Terms of use</span>
              </a>{' '}
              and{' '}
              <a href='/about/privacy-policy'>
                <span>Privacy policy</span>
              </a>
            </p>
          </div>
        </>
      </SideMenu>
    </div>
  );
}

export default Register;
