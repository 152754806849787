import { ReactElement } from 'react';

export interface PropsShape {
  setView: any;
  selected: 'ROW' | 'LARGE' | 'SMALL';
}

const ViewSelector = ({ setView, selected }: PropsShape): ReactElement => {
  return (
    <div className='ViewSelector'>
      <div
        className={
          selected === 'ROW'
            ? 'ViewSelector--row ViewSelector--row-selected'
            : 'ViewSelector--row'
        }
        onClick={(): void => setView('ROW')}
      />
      <div
        className={
          selected === 'LARGE'
            ? 'ViewSelector--large ViewSelector--large-selected'
            : 'ViewSelector--large'
        }
        onClick={(): void => setView('LARGE')}
      />
      <div
        className={
          selected === 'SMALL'
            ? 'ViewSelector--small ViewSelector--small-selected'
            : 'ViewSelector--small'
        }
        onClick={(): void => setView('SMALL')}
      />
    </div>
  );
};
export { ViewSelector };
