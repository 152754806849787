import { ReactElement } from 'react';

export interface PropsShape {
  title: string;
  subtitle?: string;
  rightTitle?: string;
}

const Heading = ({ title, subtitle, rightTitle }: PropsShape): ReactElement => {
  return (
    <div className='Heading'>
      <div className='Heading--title-wrapper'>
        <h2 className='Heading--title'>{title}</h2>
        {rightTitle && <p className='Heading--right-title'>{rightTitle}</p>}
      </div>
      {subtitle && <p className='Heading--subtitle'>{subtitle}</p>}
    </div>
  );
};
export { Heading };
