import { ReactElement } from 'react';
import { Header } from '../../components/Header';
import { Title } from '../../components/Title';
import { Footer } from '../../components/Footer';
import { Background } from '../../components/Background';
import { SplitCenter } from '../../components/SplitCenter';
import { TextImage } from '../../components/TextImage';
import { Team } from '../../components/Team';
import greenTick from '../../images/green-tick.svg';
import douglasHorn from '../../images/douglas-horn.jpg';
import erikaElder from '../../images/erika-elder.jpg';
import cjWalters from '../../images/cj-walters.jpg';
import seanAnderson from '../../images/sean-anderson.jpg';
import leahPeterson from '../../images/leah-peterson.jpg';
import williamHill from '../../images/william-hill.jpg';
import nathanialMoss from '../../images/nathanial-moss.jpg';

function About(): ReactElement {
  return (
    <div className='Pricing'>
      <Header />
      <Background color='#F8FAFC' borderTop>
        <>
          <Title
            title='Building the file storage layer for modern applications'
            subTitle='Checkout our getting started guide or explore the ready to run examples on GitHub. Join out thriving community on Telegram for help and ideas.'
          />
        </>
      </Background>
      <Background color='#30385E'>
        <SplitCenter>
          <div>
            <TextImage
              mode='dark'
              title='Bringing Web3 Data Security to Any Business'
              text="The world needs to move beyond centralized cloud services in a way that doesn't leave traditional businesses out. dStor aims to provide more efficient and affordable cloud-based data storage to the world so that any business can afford to reach a global audience. Monolithic cloud providers build bigger, more resource-intensive data centers at a furious pace while a previous generation of high capacity data centers remains vastly underutilized. dStor gets more use out of these existing data centers by bringing them incremental income for incremental resource usage--without having to build new ones. The Web3 revolution will bypass the giant companies that currently stand between users and the internet resources they want to use--demanding a high toll. This current Web2 infrastructure is like a tax on every Internet user and developer that gets in the way of innovation."
            />
          </div>
          <div>
            <TextImage
              mode='dark'
              title='Leading the Decentralized Transition'
              text="Earlier attempts to create a decentralized cloud storage infrastructure have encountered a major problem: starting out with complete decentralization makes the system too challenging to build and use. Despite the technical strengths of Filecoin, Storj and Sia Skynet, these systems have not lived up to their promised growth. The requirements to use a volatile payment token is too onerous to build a sustainable network and onboard customers. dStor storage is globally decentralized but currently retains a licensed company and gateway system so that it can offer the payment methods and customer service that real economy businesses need. The dStor system is meant to evolve past the need for any company to exist as these functions will gradually be replaced by decentralized structures. In fact, we intend dStor to be a leader in showing the world's businesses how to make the transition to decentralized future."
            />
          </div>
        </SplitCenter>
      </Background>
      <Background color='#FFF'>
        <Title
          title='Team'
          subTitle="We're a diverse team of highly entrepreneurial technology professionals dedicated to building the foundational technologies of Web3 to create a more freely accessible Internet for everyone, all around the world."
        />
        <Team
          data={[
            {
              name: 'Douglas Horn',
              position: 'CEO',
              image: douglasHorn,
              link: 'https://www.linkedin.com/in/douglashorn/',
            },
            {
              name: 'Erika Elder',
              position: 'COO',
              image: erikaElder,
              link: 'https://www.linkedin.com/in/erika-e-25b21a6b/',
            },
            {
              name: 'CJ Walters',
              position: 'Product Owner',
              image: cjWalters,
              link: 'https://github.com/andcjane',
            },
            {
              name: 'Sean Anderson',
              position: 'Growth',
              image: seanAnderson,
              link: 'https://www.linkedin.com/in/seanxanderson/',
            },
            {
              name: 'Leah Peterson',
              position: 'Business Development and Investor Relations',
              image: leahPeterson,
              link: 'https://www.linkedin.com/in/leah-petersen-goodblock',
            },
            {
              name: 'William Hill',
              position: 'Lead Developer',
              image: williamHill,
              link: 'https://github.com/bkawk/',
            },
            {
              name: 'Nathanial Moss',
              position: 'Systems Engineer',
              image: nathanialMoss,
              link: 'https://www.linkedin.com/in/nathanielrmoss/',
            },
          ]}
        />
      </Background>
      <Background color='#FFF'>
        <Title
          title='Many ways to help build dStor'
          subTitle='Find your path to get involved'
        />
        <SplitCenter>
          <div>
            <TextImage
              imageAlign='left'
              title='Join us in blazing the trail'
              text='If the idea of more equitable access to internet resources for businesses and users appeals to you, there are many ways to join us. '
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Operate a node'
              text="If your Internet infrastructure isn't being fully utilized, you can make more income while supporting the next wave of computing by connecting to the dStor network."
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Refer users'
              text='Know a business or developer that could benefit from the high speed and security and low prices of dStor? Refer them and earn a reward when they become dStor customers.'
              image={greenTick}
              padding='12px'
            />
          </div>
          <div>
            <TextImage
              imageAlign='left'
              title='Become a customer'
              text='Our global system can cut your operational costs, provide great speed and security and allow you to reach all global regions at high speed with one price.'
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Develop on dStor'
              text="A wealth of new Web3 applications are already being developed on dStor that will ultimately replace most of the leading apps and services of today. It's still early and developers will find amazing opportunities to redefine computing through decentralization."
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Invest in dStor'
              text='In Q1 2023, dStor will offer a limited investment opportunity which will allow us to bring in individual investors at a low minimum investments and the security and convenience of a US SEC Reg CF equity raise through Republic.com, the leader in crowd-investing.'
              image={greenTick}
              padding='12px'
            />
          </div>
        </SplitCenter>
      </Background>

      <Background color='#FFFFFF'>
        <Footer />
      </Background>
      <div className='Pricing--center'></div>
    </div>
  );
}

export default About;
