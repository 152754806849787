import { ReactElement } from 'react';

export interface PropsShape {
  topColor: string;
  bottomColor: string;
  tickArrayLeft: string[];
  titleLeft: string;
  tickArrayCenter: string[];
  titleCenter: string;
  tickArrayRight: string[];
  titleRight: string;
}

const PointBox = ({
  topColor,
  bottomColor,
  tickArrayLeft,
  titleLeft,
  tickArrayCenter,
  titleCenter,
  tickArrayRight,
  titleRight,
}: PropsShape): ReactElement => {
  return (
    <div
      className='PointBox'
      style={{
        background: `linear-gradient(180deg, ${topColor} 0%, ${topColor} 50%, ${bottomColor} 50%, ${bottomColor} 100%)`,
      }}
    >
      <div className='PointBox--center'>
        <div>
          <div className='PointBox--title'>{titleLeft}</div>
          {tickArrayLeft?.map((item: any, index: number) => {
            return (
              <div key={index} className='PointBox--item'>
                {item}
              </div>
            );
          })}
        </div>
        <div>
          <div className='PointBox--title'>{titleCenter}</div>
          {tickArrayCenter?.map((item: any, index: number) => {
            return (
              <div key={index} className='PointBox--item'>
                {item}
              </div>
            );
          })}
        </div>
        <div>
          <div className='PointBox--title'>{titleRight}</div>
          {tickArrayRight?.map((item: any, index: number) => {
            return (
              <div key={index} className='PointBox--item'>
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export { PointBox };
