import { ReactElement } from 'react';

export interface PropsShape {
  value: number;
}

const FormatBytes = ({ value }: PropsShape): ReactElement => {
  const formatBytes = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };
  return <>{formatBytes(value)}</>;
};
export { FormatBytes };
