import { ReactElement, Dispatch, SetStateAction } from 'react';

interface PropsShape {
  setValue?: Dispatch<SetStateAction<boolean>>;
  setValueId?: Dispatch<SetStateAction<object>>;
  value: boolean;
  label?: string;
  id: string;
  reverse?: boolean;
}

function CheckBox({
  setValue,
  setValueId,
  value,
  label = 'Disabled',
  id,
  reverse = false,
}: PropsShape): ReactElement {
  return (
    <p className='CheckBox'>
      <input
        className='CheckBox--input'
        type='checkbox'
        name={id}
        id={id}
        checked={reverse ? !value : value}
        onChange={(): void => {
          if (setValue) setValue(!value);
          if (setValueId) setValueId({ value: !value, id });
        }}
        data-testid={`check-${id}`}
      />
      <label className='CheckBox--label' htmlFor={id}>
        {label}
      </label>
    </p>
  );
}

export { CheckBox };
