import { ReactElement } from 'react';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';
export interface PropsShape {
  title: string;
  text: string;
  buttonText?: string;
  buttonLink?: string;
  image?: any;
  imageAlign?: 'left' | 'right';
  mode?: 'light' | 'dark';
  padding?: string;
}

const TextImage = ({
  title,
  text,
  buttonText,
  buttonLink,
  image,
  imageAlign = 'right',
  mode,
  padding = '48px 12px',
}: PropsShape): ReactElement => {
  const navigate = useNavigate();
  const doClick = (): void => {
    if (buttonLink && buttonLink.substring(0, 8) === 'https://') {
      window.open(buttonLink, '_blank');
      return;
    }
    navigate(`${buttonLink}`);
  };
  const textAlign = imageAlign === 'right' ? 'left' : 'right';
  return (
    <div className={`TextImage ${mode}`}>
      <div
        className={`TextImage--center-${textAlign} ${mode} `}
        style={{ padding }}
      >
        <div
          className={`TextImage--text ${mode}`}
          style={{ gridArea: `${textAlign}` }}
        >
          <h1 className={`TextImage--title ${mode}`}>{title}</h1>
          <div></div>
          <p className={`TextImage--text ${mode}`}>{text}</p>
          <div className={`TextImage--button ${mode}`}>
            {buttonText && <Button name={buttonText} click={doClick} />}
          </div>
        </div>
        <div
          className={`TextImage--image ${mode}`}
          style={{ gridArea: `${imageAlign}` }}
        >
          <img src={image} />
        </div>
      </div>
    </div>
  );
};
export { TextImage };
