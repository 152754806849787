import { ReactElement } from 'react';

export interface PropsShape {
  children: any;
}

const SplitCenter = ({ children }: PropsShape): ReactElement => {
  return (
    <div className='SplitCenter'>
      <div className='SplitCenter--center'>
        <div>{children[0]}</div>
        <div>{children[1]}</div>
      </div>
    </div>
  );
};
export { SplitCenter };
