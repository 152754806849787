const readableBytes = (x: number): any => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  let n = parseInt(`${x}`, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

const centsToDollars = (x: number, y: number = 4): any => {
  return x === 0 ? 0 : (x / 100).toFixed(y);
};

const epochToDate = (epoch: number, showTime: boolean): string => {
  const epochDate = new Date(0);
  epochDate.setUTCSeconds(epoch / 1000);

  const locale = (): string => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.language || 'en-gb';
    }
  };
  const addZeroBefore = (n: number): string => {
    return (n < 10 ? '0' : '') + n;
  };
  const month = epochDate?.toLocaleDateString(locale(), {
    month: 'short',
  });
  const day = epochDate?.toLocaleDateString(locale(), {
    day: 'numeric',
  });
  const year =
    `${new Date().getFullYear()}` ===
    epochDate?.toLocaleDateString(locale(), { year: 'numeric' })
      ? ''
      : `, ${epochDate?.toLocaleDateString(locale(), {
          year: 'numeric',
        })}`;

  let hours = epochDate?.getHours()!;
  const amOrPm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;
  return (
    `${month} ${day}${year}` +
    (showTime
      ? `, ${addZeroBefore(hours)}:${addZeroBefore(
          epochDate?.getMinutes()
        )} ${amOrPm}`
      : '')
  );
};

export { readableBytes, centsToDollars, epochToDate };
