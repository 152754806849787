import { ReactElement } from 'react';
import { Header } from '../../components/Header';
import { Title } from '../../components/Title';
import { Footer } from '../../components/Footer';
import { Background } from '../../components/Background';
import { ThreePoints } from '../../components/ThreePoints';
import { SplitCenter } from '../../components/SplitCenter';
import { TextImage } from '../../components/TextImage';
import greenTick from '../../images/green-tick.svg';

function WhyDstor(): ReactElement {
  return (
    <div className='Pricing'>
      <Header />
      <Background color='#F8FAFC'>
        <>
          <Title
            title='Why dStor?'
            subTitle='Fast, Affordable & Easy Global Cloud Storage'
          />
        </>
      </Background>
      <Background color='#30385E'>
        <>
          <ThreePoints
            color='#FFFFFF'
            pointOne={{
              title: 'Business',
              text: 'Lorem ipsum dolor siximus facilisis ma Lorem ipsum dolor sit amet, facilisis ma',
            }}
            pointTwo={{
              title: 'Scalability',
              text: 'Lorem ipsum dolor siximus facilisis ma Lorem ipsum dolor sit amet, facilisis ma',
            }}
            pointThree={{
              title: 'Speed',
              text: 'Lorem ipsum dolor siximus facilisis ma Lorem ipsum dolor sit amet, facilisis ma',
            }}
          />
        </>
      </Background>
      <Background color='#fff'>
        <SplitCenter>
          <div>
            <Title
              align='left'
              title='You Need dStor'
              subTitle="Cloud computing costs are one of the largest Internet costs for many small and medium-sized businesses. dStor can save almost any business serious money without sacrificing security, speed or ease of use. We're sure you can find a lot better uses for that money than paying high rates to some of the biggest companies in the world just to let your customers access your data."
            />
          </div>
          <div>
            <Title
              align='left'
              title='The World Needs dStor'
              subTitle='Using dStor not only helps you, it helps build a better world with greater access and reach.'
            />
          </div>
        </SplitCenter>
        <SplitCenter>
          <div>
            <TextImage
              imageAlign='left'
              title='Automated monitoring'
              text='Lorem ipsum dolor sit'
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Onboarding'
              text='Lorem ipsum dolor sit'
              image={greenTick}
              padding='12px'
            />
          </div>
          <div>
            <TextImage
              imageAlign='left'
              title='Lower cost means better access'
              text='When the cost of cloud storage drops it allows more people to launch web-based businesses, particularly those in communities with less affluence and access to technology. With dStor a new class of entrepreneurs and innovators can afford to launch projects to serve more communities.'
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Think locally, act globally '
              text="dStor inherently brings global reach to your data. Traditional cloud storage companies serve data in distinct regions and charge more for each region added. Traffic from outside these paid regions is served much slower than from within. Businesses are forced to choose what users will get rapid data delivery and which won't. But dStor users get fast data access for the whole world at one low price, meaning your global users get a local experience so you don't have do decide where you can afford to grow."
              image={greenTick}
              padding='12px'
            />
            <TextImage
              imageAlign='left'
              title='Smaller footprint'
              text="How can you save 100% of the environmental impact of a new data center? Don't even build it! dStor wrings more utility from the thousands of under-used co-location data centers around the world so no new data centers need to be constructed. These data centers are already running and the additional load from dStor comes at a small incremental increase in their current resource usage and with no impact from new construction."
              image={greenTick}
              padding='12px'
            />
          </div>
        </SplitCenter>
      </Background>
      <Background color='#FFFFFF'>
        <Footer />
      </Background>
      <div className='Pricing--center'></div>
    </div>
  );
}

export default WhyDstor;
