import { ReactElement, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

export interface PropsShape {
  title: string;
  children?: ReactElement;
  style?: 'button' | 'text';
}

const DropDown = ({
  title,
  children,
  style = 'button',
}: PropsShape): ReactElement => {
  const menuTransition = useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className='DropDown'>
      {isOpen && (
        <div
          className='DropDown--overlay'
          onClick={(): void => setIsOpen(!isOpen)}
        ></div>
      )}
      {style === 'button' && (
        <button
          className='DropDown--button'
          onClick={(): void => setIsOpen(!isOpen)}
        >
          {title}
        </button>
      )}
      {style === 'text' && (
        <div
          className='DropDown--text'
          onClick={(): void => setIsOpen(!isOpen)}
        >
          {title}
        </div>
      )}

      <CSSTransition
        nodeRef={menuTransition}
        in={isOpen}
        timeout={300}
        classNames={`DropDown--transition`}
        unmountOnExit
      >
        <div className='DropDown--menu' ref={menuTransition}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};
export { DropDown };
