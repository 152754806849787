import { ReactElement, Dispatch, SetStateAction } from 'react';

export interface PropsShape {
  label?: boolean;
  name: string;
  info?: string;
  error?: string;
  optional?: boolean;
  description?: string;
  options: { value: string; name: string }[];
  selectPrompt?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  mode?: 'LIGHT' | 'DARK';
}

const SelectInput = ({
  label = false,
  name,
  info,
  error,
  optional,
  description,
  options,
  selectPrompt = 'Select...',
  value,
  setValue,
  mode,
}: PropsShape): ReactElement => {
  return (
    <div
      className='SelectInput'
      style={{ color: mode === 'LIGHT' ? '#434343' : '#FFF' }}
    >
      {info && <>{info}</>}
      {error && <div className='SelectInput--error'>{error}</div>}
      {optional && <div className='SelectInput--optional'>Optional</div>}
      {label && (
        <label className='SelectInput--label' htmlFor={name}>
          {name}
        </label>
      )}
      <select
        name={name}
        id={name}
        value={value}
        onChange={(e): void => setValue(e.target.value)}
        className={value === '' ? 'SelectInput--none' : 'SelectInput--select'}
      >
        {selectPrompt && (
          <option value='' disabled={true}>
            {selectPrompt}
          </option>
        )}
        {options?.map(
          (option: { value: string; name: string }, index: number) => {
            return (
              <option value={option.value.replace(/\s/g, '')} key={index}>
                {option.name}
              </option>
            );
          }
        )}
      </select>
      {description && (
        <small className='SelectInput--description'>{description}</small>
      )}
    </div>
  );
};
export { SelectInput };
