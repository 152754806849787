import { ReactElement } from 'react';

export interface PropsShape {
  title?: string;
  topRight?: ReactElement;
  bottomRight?: ReactElement;
  bottomLeft?: ReactElement;
  children: ReactElement | ReactElement[] | string;
}

const Card = ({
  title,
  topRight,
  bottomRight,
  bottomLeft,
  children,
}: PropsShape): ReactElement => {
  return (
    <>
      <div className='Card'>
        {title && (
          <div className='Card--header'>
            <div className='Card--header-title'>{title}</div>
            <div>{topRight}</div>
          </div>
        )}
        <div>{children}</div>
        {bottomLeft ||
          (bottomRight && (
            <div className='Card--footer'>
              <div>{bottomLeft}</div>
              <div>{bottomRight}</div>
            </div>
          ))}
      </div>
    </>
  );
};
export { Card };
