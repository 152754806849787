import {
  ReactElement,
  useEffect,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { ServerError } from './ServerError';
import { Password } from './Password';
import { Button } from './Button';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { callApi } from '../functions/callApi';
import { isPassword } from '../functions/validators';
import { Heading } from './Heading';

interface ChangePasswordLoggedInProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ChangePasswordLoggedIn = ({
  setIsOpen,
}: ChangePasswordLoggedInProps): ReactElement => {
  const { userState, setUserState } = useGlobalUserState();
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [newPasswordMatch, setNewPasswordMatch] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const changePassword = async (): Promise<void> => {
    setError('');
    setLoading(true);
    try {
      const result = await callApi<any>(
        'account/change-password-logged-in',
        'POST',
        JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        })
      );
      if (result?.status === 200) {
        const loginResult = await callApi<any>(
          'auth/local/login',
          'POST',
          JSON.stringify({
            email: userState?.data?.email,
            password: newPassword,
          })
        );
        loginResult.message && setError(result.message);
        setUserState((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            loggedIn: true,
            accountKey: loginResult.account_key,
            accessToken: loginResult.access_token,
            name: loginResult.owner_name,
            email: loginResult.owner_email,
            lastUpload: Date.now(),
            userRole: loginResult.account_role.type,
            isOnline: loginResult.is_online,
            pulse: {
              accountDeletedMail: loginResult.account_deleted_mail,
              accountDeletedPulse: loginResult.account_deleted_pulse,
              accountFrozenMail: loginResult.account_frozen_mail,
              accountFrozenPulse: loginResult.account_frozen_pulse,
              failedPaymentMail: loginResult.failed_payment_mail,
              failedPaymentPulse: loginResult.failed_payment_pulse,
              invoiceGeneratedMail: loginResult.invoice_generated_mail,
              invoiceGeneratedPulse: loginResult.invoice_generated_pulse,
              noPaymentMethodMail: loginResult.no_payment_method_mail,
              noPaymentMethodPulse: loginResult.no_payment_method_pulse,
              successfulPaymentMail: loginResult.successful_payment_mail,
              successfulPaymentPulse: loginResult.successful_payment_pulse,
              thresholdNotReachedMail: loginResult.threshold_not_reached_mail,
              thresholdNotReachedPulse: loginResult.threshold_not_reached_pulse,
            },
          },
        }));
        setIsOpen(false);
        setLoading(false);
      } else {
        setError(result.message);
        setLoading(false);
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (confirmPassword === newPassword) setNewPasswordMatch(true);
    else setNewPasswordMatch(false);
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    let validNewPass: boolean = false;
    validNewPass = isPassword(newPassword);
    setDisabled(!(validNewPass && newPasswordMatch));
  }, [newPasswordMatch, newPasswordMatch]);

  return (
    <div className='ChangePassword--modal'>
      <Heading
        title='Change Password'
        subtitle='Kindly input your current password and the new password to initiate the password change process'
      />
      <ServerError error={error} />
      <Password
        name='Current Password'
        label
        value={oldPassword}
        setValue={setOldPassword}
      />
      <Password
        name='New Password'
        label
        value={newPassword}
        setValue={setNewPassword}
        strong
      />
      <div>
        <Password
          name='Confirm Password'
          label
          value={confirmPassword}
          setValue={setConfirmPassword}
        />
        <div
          style={{
            color: newPasswordMatch ? 'green' : 'red',
            paddingLeft: '1px',
          }}
        >
          {confirmPassword === ''
            ? ''
            : newPasswordMatch
            ? 'Password match'
            : 'Password mismatch'}
        </div>
      </div>
      <Button
        name='Change'
        click={changePassword}
        loading={loading}
        disabled={disabled}
      />
    </div>
  );
};

export default ChangePasswordLoggedIn;
