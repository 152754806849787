import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';
import { callApi } from '../../functions/callApi';
import { DateTime } from '../../components/DateTime';
import { Copy } from '../../components/Copy';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { Menu } from '../../components/Menu';
import { SettingsItem } from '../../components/SettingsItem';
import { Card } from '../../components/Card';
import { useMainNav } from '../../hooks/useMainNav';

function ApiKeys(): ReactElement {
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [expiryDays, setExpiryDays] = useState<string>('365');
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [addDisabled, setAddDisabled] = useState<boolean>(true);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const navList = useMainNav();

  const addApiKey = async (): Promise<void> => {
    setAddLoading(true);
    try {
      await callApi(
        `dev/api-key`,
        'POST',
        JSON.stringify({
          comment: description,
          expiration: Math.ceil((Date.now() + +expiryDays * 86400) / 1000),
        })
      );
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    const apiKeys = await callApi<any>(
      `dev/api-keys?limit=100&offset=0&account_key=${userState.data?.accountKey}`
    );
    if (!apiKeys.error) {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          apiKeys,
        },
      }));
    }
    setAddLoading(false);
    setIsOpen(false);
  };

  const deleteApiKey = async (): Promise<void> => {
    setDeleteLoading(true);
    try {
      await callApi(`dev/api-key/${deleteId}`, 'DELETE');
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    const apiKeys = await callApi<any>(
      `dev/api-keys?limit=100&offset=0&account_key=${userState.data?.accountKey}`
    );
    if (!apiKeys.error) {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          apiKeys,
        },
      }));
    }
    setIsDeleteOpen(false);
    setDeleteLoading(false);
  };

  useEffect(() => {
    if (description && expiryDays) {
      setAddDisabled(false);
    } else setAddDisabled(true);
  }, [description, expiryDays]);

  useEffect(() => {
    setDeleteConfirmation('');
  }, [isDeleteOpen]);

  useEffect(() => {
    setDescription('');
    setExpiryDays('365');
  }, [isOpen]);

  useEffect(() => {
    if (deleteConfirmation === 'delete') {
      setDeleteDisabled(false);
    } else setDeleteDisabled(true);
  }, [deleteConfirmation]);

  useEffect(() => {
    !userState.data?.loggedIn && navigate('/');
    const getApiKeys = async (): Promise<void> => {
      const apiKeys = await callApi<any>(
        `dev/api-keys?limit=100&offset=0&account_key=${userState.data?.accountKey}`
      );
      if (!apiKeys.error) {
        setUserState((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            apiKeys,
          },
        }));
      }
    };
    getApiKeys();
  }, []);

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <div className='ApiKeys--title'>Account</div>
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading title='API Keys' subtitle='Add a new API Key.' />
            <TextInput
              type='text'
              name='Description'
              value={description}
              setValue={setDescription}
              focus
              label
            />
            <TextInput
              type='number'
              name='Days before API key expires'
              value={expiryDays}
              setValue={setExpiryDays}
              label
            />
            <Button
              name='Add'
              click={addApiKey}
              loading={addLoading}
              disabled={addDisabled}
            />
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isDeleteOpen}
          setIsOpen={setIsDeleteOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Delete API Key'
              subtitle='Confirm your intent to delete this API key.'
            />
            <TextInput
              type='text'
              name="Enter 'delete' to confirm"
              value={deleteConfirmation}
              setValue={setDeleteConfirmation}
              focus
              label
            />
            <Button
              name='Delete'
              color='RED'
              click={deleteApiKey}
              loading={deleteLoading}
              disabled={deleteDisabled}
            />
          </div>
        </SideMenu>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={navList} selected='API Keys' />
          </div>

          <Card>
            <SettingsItem
              title='Manage your keys'
              subtitle='Add and delete your api keys carefully!'
              button='Add Key'
              buttonClick={(): void => setIsOpen(true)}
            >
              <Card>
                <div>
                  {userState?.data?.apiKeys?.length !== 0 &&
                    userState?.data?.apiKeys?.map((key, index) => (
                      <div key={index}>
                        <SettingsItem
                          inner
                          title={
                            <>
                              <Copy
                                value={key.api_key}
                                type={'BUTTON'}
                                text={key.api_key}
                              />
                            </>
                          }
                          subtitle={
                            <div>
                              <DateTime
                                showTime
                                value={new Date(key.created)}
                              />{' '}
                              '{key.comment}'
                            </div>
                          }
                          border
                        >
                          <Button
                            name='Delete'
                            margin={0}
                            click={(): void => {
                              setIsDeleteOpen(true);
                              setDeleteId(key.api_key);
                            }}
                          />
                        </SettingsItem>
                      </div>
                    ))}
                  {!userState.data?.apiKeys ||
                    (userState.data?.apiKeys.length === 0 && (
                      <div style={{ padding: '12px', textAlign: 'center' }}>
                        No Api Keys created, please tap "Add Key"
                      </div>
                    ))}
                </div>
              </Card>
            </SettingsItem>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ApiKeys;
