import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { callApi } from '../functions/callApi';
import { DateTime } from '../components/DateTime';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { Link, useNavigate } from 'react-router-dom';
import { organizationShape, Notifications } from '../interfaces/user';

export interface PulseShape {
  created_at: number;
  id: number;
  is_read: boolean;
  link: string;
  message: string;
  title: string;
  type: Notifications;
  data: any;
}
const Pulse = (): ReactElement => {
  const menuTransition = useRef<null | HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<PulseShape[]>();
  const { userState, setUserState } = useGlobalUserState();
  const navigate = useNavigate();

  const getPulse = async (): Promise<void> => {
    const messages = await callApi<any>(
      'account/notifications?status=all',
      'GET'
    );
    if (!messages.error) {
      setNotifications(messages);
    }
  };
  const handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    notification: Notifications,
    data: string
  ): Promise<void> => {
    if (notification && notification === Notifications.ORG_WELCOME) {
      try {
        event.preventDefault();
        setUserState((prevState: any) => ({
          ...prevState,
          isSwitchingAccount: true,
        }));
        const response = await callApi<any>(
          `organizations/select_active`,
          'POST',
          JSON.stringify({
            organization_id: JSON.parse(data).org_key,
            isUserAccount: false,
          })
        );

        if (response && response.status === 200) {
          const updatedOrganizations = userState?.data?.organizations?.map(
            (org: organizationShape) => {
              const updatedOrg = response.organizations.find(
                (o: any) => o.organization_id === org.key
              );
              if (updatedOrg) {
                return { ...org, is_active: updatedOrg.is_active };
              }
              return org;
            }
          );

          setUserState((prevState: any) => ({
            ...prevState,
            data: {
              ...prevState.data,
              isActive: false,
              organizations: updatedOrganizations,
            },
            isSwitchingAccount: false,
            currentOrganization: {
              ...prevState.currentOrganization,
              maxThreshold: response.currentOrganization.maximum_threshold_usd,
              isPreviewOn: response.currentOrganization.is_preview_on,
              accountKey: response.currentOrganization.account_key,
              accessToken: response.currentOrganization.access_token,
              name: response.currentOrganization.owner_name,
              email: response.currentOrganization.owner_email,
              encryption: {
                publicKey: response.currentOrganization.rsa_pub_key,
                selfHint: response.currentOrganization.self_hint,
              },
              lastUpload: Date.now(),
              isNodeOperator: response.currentOrganization.has_nodes,
              has_credit_card: response.currentOrganization.has_credit_card,
              pulse: {
                accountDeletedMail:
                  response.currentOrganization.account_deleted_mail,
                accountDeletedPulse:
                  response.currentOrganization.account_deleted_pulse,
                accountFrozenMail:
                  response.currentOrganization.account_frozen_mail,
                accountFrozenPulse:
                  response.currentOrganization.account_frozen_pulse,
                failedPaymentMail:
                  response.currentOrganization.failed_payment_mail,
                failedPaymentPulse:
                  response.currentOrganization.failed_payment_pulse,
                invoiceGeneratedMail:
                  response.currentOrganization.invoice_generated_mail,
                invoiceGeneratedPulse:
                  response.currentOrganization.invoice_generated_pulse,
                noPaymentMethodMail:
                  response.currentOrganization.no_payment_method_mail,
                noPaymentMethodPulse:
                  response.currentOrganization.no_payment_method_pulse,
                successfulPaymentMail:
                  response.currentOrganization.successful_payment_mail,
                successfulPaymentPulse:
                  response.currentOrganization.successful_payment_pulse,
                thresholdNotReachedMail:
                  response.currentOrganization.threshold_not_reached_mail,
                thresholdNotReachedPulse:
                  response.currentOrganization.threshold_not_reached_pulse,
              },
              unpaidInvoice: response.currentOrganization.unpaid_invoice,
            },
          }));

          navigate(userState.data?.loggedIn ? '/files' : '/');
        } else if (response.message) {
          // eslint-disable-next-line no-console
          console.error('Error:', response.message);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      const getMessages = async (): Promise<void> => {
        if (notifications && notifications.length) {
          const notRead = notifications?.filter((msg) => msg.is_read === false);
          const ids: number[] = [];
          notRead?.forEach((e: PulseShape) => {
            ids.push(e.id);
          });
          await callApi<any>(
            'account/notifications-batch',
            'PUT',
            JSON.stringify({
              ids,
              data: {
                is_read: true,
              },
            })
          );
          getPulse();
        }
      };
      getMessages();
    }
  }, [isOpen]);
  const countUnread = (): number => {
    if (notifications && notifications.length) {
      const notRead = notifications?.filter((msg) => msg.is_read === false);
      return notRead?.length || 0;
    }
    return 0;
  };
  useEffect(() => {
    const loggedIn = JSON.parse(`${localStorage.getItem('userState')}`).data
      ?.loggedIn;
    if (loggedIn) {
      getPulse();
    }
  }, []);
  useEffect(() => {
    getPulse();
  }, [userState.data?.isActive]);
  return (
    <div className='Pulse'>
      {isOpen && (
        <div
          className='Pulse--overlay'
          onClick={(): void => setIsOpen(!isOpen)}
        ></div>
      )}
      <div className='Pulse--button' onClick={(): void => setIsOpen(!isOpen)}>
        <div className='Pulse--avatar' />
        {countUnread() > 0 && (
          <div className='Pulse--count'>{countUnread()}</div>
        )}
      </div>
      <CSSTransition
        nodeRef={menuTransition}
        in={isOpen}
        timeout={300}
        classNames={`Pulse--transition`}
        unmountOnExit
      >
        <div className='Pulse--menu' ref={menuTransition}>
          <div className='Pulse--title'>The pulse</div>
          <div className='Pulse--scroll'>
            {notifications &&
              notifications.length > 0 &&
              notifications?.map((key: PulseShape, index: number) => (
                <Link
                  to={key.link}
                  style={{ textDecoration: 'none' }}
                  onClick={async (
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ): Promise<void> =>
                    await handleClick(event, key.type, key.data)
                  }
                  key={index}
                >
                  <div className='Pulse--item'>
                    <div className='Pulse--subject'>{key.title}</div>
                    <div className='Pulse--subtitle'>{key.message}</div>
                    <div className='Pulse--time'>
                      <DateTime showTime value={new Date(key.created_at)} />
                    </div>
                    <div className='Pulse--subtitle'>{key.message}</div>
                  </div>
                </Link>
              ))}
            {notifications && notifications.length == 0 && (
              <div style={{ padding: '12px' }}>You have no notifications</div>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
export { Pulse };
