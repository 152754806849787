import { ReactElement } from 'react';
import { Header } from '../../components/Header';
import { Title } from '../../components/Title';
import { Footer } from '../../components/Footer';
import { Background } from '../../components/Background';

function UserGuide(): ReactElement {
  return (
    <div className='Pricing'>
      <Header />
      <Background color='#F8FAFC'>
        <>
          <Title
            title='User Guides'
            subTitle='This section includes end-to-end guides for upgrading and deploying applications that use dStor, as well as guides for common file-related tasks.'
          />
        </>
      </Background>

      <Background color='#FFFFFF'>
        <Footer />
      </Background>
      <div className='Pricing--center'></div>
    </div>
  );
}

export default UserGuide;
