import { ReactElement } from 'react';
import { Header } from '../components/Header';
import { Background } from '../components/Background';
import { Footer } from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/Button';

export interface NotFoundProps {
  mode?: 'light' | 'dark';
}

const NotFound = ({ mode }: NotFoundProps): ReactElement => {
  const navigate = useNavigate();

  const goHome = (): void => {
    navigate('/');
  };

  return (
    <div className={`Home ${mode}`}>
      <Header />
      <Background color='#FFFFFF'>
        <div className='NotFound'>
          <div className='NotFound--404'>404</div>
          <div className='NotFound--title'>Page not found</div>
          <div className='NotFound--sub-title'>Oops! You seem to be lost.</div>
          <div className='NotFound--button'>
            <Button name='Home' click={goHome} />
          </div>
        </div>
      </Background>
      <Background color='#FFFFFF'>
        <Footer />
      </Background>
      <div className='Home--center'></div>
    </div>
  );
};

export default NotFound;
