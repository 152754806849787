import { ReactElement } from 'react';

export interface PropsShape {
  error: string;
}

const ServerError = ({ error }: PropsShape): ReactElement => {
  return <>{error && <div className='ServerError'>{error}</div>}</>;
};
export { ServerError };
