import { ReactElement } from 'react';

export interface PropsShape {
  name: string;
  minValue?: number;
  maxValue?: number;
  value?: number;
  setValue: any;
  unitOfMeasure?: string;
  infoWidth?: number;
}

const Slider = ({
  name,
  minValue = 0,
  maxValue = 10,
  value = 10,
  setValue,
  unitOfMeasure = '',
  infoWidth = 50,
}: PropsShape): ReactElement => {
  const calked = ((value - minValue) / (maxValue - minValue)) * 100;
  return (
    <div className='Slider'>
      <div className='Slider--value-area'>
        <div
          className='Slider--value'
          style={{
            width: `${infoWidth}px`,
            left: `calc(${
              ((value - minValue) / (maxValue - minValue)) * 100
            }% - ${infoWidth / 2 - 12 + calked * 0.25}px)`,
          }}
        >
          {value} <span>{unitOfMeasure}</span>
          <div
            className='Slider--tick'
            style={{ right: `${infoWidth / 2 - 5}px` }}
          />
        </div>
      </div>
      <input
        style={{
          background: `linear-gradient(to right, #B395E1 0%, #B395E1 ${
            ((value - minValue) / (maxValue - minValue)) * 100
          }%, #D9D9D9 ${
            ((value - minValue) / (maxValue - minValue)) * 100
          }%, #D9D9D9 100%)`,
        }}
        className='Slider--input'
        type='range'
        id='slider'
        name='slider'
        min={minValue}
        max={maxValue}
        value={value}
        onChange={(e: any): void => setValue(e.target.value)}
      />
      <label htmlFor='slider' className='Slider--label'>
        {name}
      </label>
    </div>
  );
};
export { Slider };

// document.getElementById('myinput').oninput = function () {
//   var value = ((this.value - this.min) / (this.max - this.min)) * 100;
//   this.style.background =
//     'linear-gradient(to right, #82CFD0 0%, #82CFD0 ' +
//     value +
//     '%, #fff ' +
//     value +
//     '%, white 100%)';
// };
