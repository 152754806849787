import { ReactElement, useEffect } from 'react';
import { Header } from '../components/Header';
import { Jumbo } from '../components/Jumbo';
import { Title } from '../components/Title';
import { Footer } from '../components/Footer';
import { TextImage } from '../components/TextImage';
import { Background } from '../components/Background';
import { BenefitBox } from '../components/BenefitBox';

import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';

import allRegion from '../images/all-region.png';
import dataAccess from '../images/data-access.png';
import simplePricing from '../images/simple-pricing.png';
import selfManaged from '../images/self-managed.png';

function Home(): ReactElement {
  const navigate = useNavigate();
  const { userState } = useGlobalUserState();

  useEffect(() => {
    userState.data?.loggedIn && navigate('/files');
  }, [userState.data?.loggedIn]);

  return (
    <div className='Home'>
      <Header />
      <Jumbo
        title='Web3 storage, ready for business'
        subTitle='dStor unlocks a new level of file management thanks to its distributed nature, access control features, & security.'
        buttonText='Request Invite'
      />

      <Background color='#FFFFFF'>
        <>
          <Title
            title='Enterprise-grade cloud storage'
            subTitle='Speed and affordability with the security of Web3'
          />
          <TextImage
            title='All-Region, All of the time'
            text='dStor is global storage, storing seed copies of your data across multiple regions. Centralized cloud storage services advise their customers to host data in multiple regions but add more charges for each region you add. dStor is inherently all-region service with no additional costs. The more regions customers would have needed on another service, the more they save with dStor.'
            buttonText='What is dStor?'
            buttonLink='/product/what-is-dstor'
            image={allRegion}
          />
          <TextImage
            title='Control data access'
            text='Decentralized cloud storage systems like Filecoin and Storj are storage provider marketplaces that force their customers to find and monitor their own storage providers within the marketplace. With dStor, the decentralized gateways constantly select which storage nodes are used based on our performance-improving algorithm. dStor monitors nodes to ensure they have all the files they are expected to hold.'
            buttonText='Developer Documentation'
            buttonLink='https://goodblock.gitbook.io/dstor/developer-documentation'
            image={dataAccess}
            imageAlign='left'
          />
        </>
      </Background>
      <BenefitBox
        topColor='#FFFFFF'
        bottomColor='#30385E'
        tickArray={[
          'Secure, tamper-proof decentralized storage',
          'Decentralized storage without the hassles',
          'Speed rivals AWS, Google, Azure at 80% savings',
          'Simple pricing, Stripe invoicing, file access control',
        ]}
      />
      <Background color='#30385E'>
        <>
          <Title
            title='Ready for business'
            subTitle='Upload files and folders. Manage your distributions. Ensure no single entity is the gatekeeper to your files.'
            mode='dark'
          />
          <TextImage
            title='Simple pricing, simple payments'
            text='dStor offers a single price regardless of volume so everyone gets the cheapest price, and can pay their monthly invoices using a credit card via Stripe. Other cloud storage systems complicate things. Between the multi-tiered pricing gimmicks of centralized systems and having to pay for decentralized solutions using specific cryptocurrency tokens with ever-changing prices, using the cloud is more complex than it needs to be. Until now.'
            buttonText='dStor Pricing'
            buttonLink='/pricing#top'
            image={simplePricing}
            mode='dark'
          />
          <TextImage
            title='Self-managing and monitoring'
            text='Decentralized cloud storage systems like Filecoin and Storj are storage provider marketplaces that force their customers to find and monitor their own storage providers within the marketplace. With dStor, the decentralized gateways constantly select which storage nodes are used based on our performance-improving algorithm. dStor monitors nodes to ensure they have all the files they are expected to hold.'
            buttonText='What is dStor?'
            buttonLink='/product/what-is-dstor'
            image={selfManaged}
            imageAlign='left'
            mode='dark'
          />
        </>
      </Background>
      <Background color='#FFFFFF'>
        <Footer />
      </Background>
      <div className='Home--center'></div>
    </div>
  );
}

export default Home;
