import { ReactElement, useState } from 'react';

export interface PropsShape {
  data: {
    display: {
      value: string | ReactElement;
      weight: 'bold' | 'normal';
      align: 'left' | 'right' | 'center';
      width: string;
      textTransform?: 'uppercase' | 'lowercase' | 'capitalize';
      iconUrl?: string;
    }[];
    expand?: {
      value: string | ReactElement;
      weight: 'bold' | 'normal';
      align: 'left' | 'right' | 'center';
      width: string;
    }[];
  };
}

const SideDataGridItem = ({ data }: PropsShape): ReactElement => {
  const [expandedOpen, setExpandedOpen] = useState<boolean>(false);

  const calcWidths = (): string => {
    let res: string = '';
    data.display.forEach((i: any) => {
      res += `${i.width} `;
    });
    return res;
  };
  const calcExpandedWidths = (): string => {
    let res: string = '';
    data.expand!.forEach((i: any) => {
      res += `${i.width} `;
    });
    return res;
  };
  return (
    <>
      <div
        className='SideDataGridItem--one'
        onClick={(): void => setExpandedOpen(!expandedOpen)}
      >
        {data.expand && (
          <div
            className={
              expandedOpen
                ? 'SideDataGridItem--expander-open'
                : 'SideDataGridItem--expander-closed'
            }
          ></div>
        )}
        {!data.expand && <div></div>}
        <div
          className='SideDataGridItem--inner-grid'
          style={{
            gridTemplateColumns: calcWidths(),
          }}
        >
          {data?.display?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={`${
                  item.iconUrl
                    ? 'SideDataGridItem--inner-grid-icon-item'
                    : 'SideDataGridItem--inner-grid-item'
                }`}
                style={{
                  fontWeight: item.weight,
                  textAlign: item.align,
                  textTransform: item.textTransform || 'none',
                }}
              >
                {item.iconUrl ? (
                  <img
                    className='SideDataGridItem--inner-grid-icon'
                    src={item.iconUrl}
                    alt={item.value}
                  />
                ) : (
                  item.value
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        {data.expand && expandedOpen && (
          <>
            <div
              className='SideDataGridItem--expanded'
              style={{
                gridTemplateColumns: calcExpandedWidths(),
              }}
            >
              {data?.expand?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{ fontWeight: item.weight, textAlign: item.align }}
                  >
                    {item.value}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export { SideDataGridItem };
