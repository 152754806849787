import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import SideMenu from './SideMenu';
import { Heading } from './Heading';
import { TextInput } from './TextInput';
import { callApi } from '../functions/callApi';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { Button } from './Button';
import { ServerError } from './ServerError';
import { memberShape } from '../interfaces/user';
import { SelectInput } from './SelectInput';
import { useNavigate } from 'react-router-dom';

export interface PropsShape {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  member: memberShape;
  getActiveMember: () => void;
  setStateAfterDelete: (memberAccountKey: string) => void;
}

export interface OrganizationShape {
  name: string;
}

const options = [
  { value: 'OWNER', name: 'Owner' },
  { value: 'MANAGER', name: 'Manager' },
  { value: 'BILLING', name: 'Billing' },
  { value: 'GENERAL_USER', name: 'General User' },
];

const EditMemberModal = ({
  isOpen,
  setIsOpen,
  member,
  getActiveMember,
  setStateAfterDelete,
}: PropsShape): ReactElement => {
  const { userState, setUserState } = useGlobalUserState();
  const [name, setName] = useState<string>(member.name);
  const [role, setRole] = useState<any>(member.role.type);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isCancelDeleteOpen, setIsCancelDeleteOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const getCurrentUserRole = (): string => {
    return (
      userState.currentOrganization?.members?.find(
        (item) => item.accountKey === userState.data?.accountKey
      )?.role.type || ''
    );
  };
  const getDeleteButton = (): boolean => {
    const ownerCount = userState.currentOrganization?.members?.filter(
      (item) => item.role.type === 'OWNER'
    ).length;
    if (
      ownerCount &&
      ownerCount > 1 &&
      member.role.type === 'OWNER' &&
      member.accountKey === userState.data?.accountKey
    ) {
      return true;
    } else if (
      getCurrentUserRole() !== 'OWNER' &&
      getCurrentUserRole() !== 'MANAGER' &&
      member.accountKey === userState.data?.accountKey
    )
      return true;
    else
      return (
        (getCurrentUserRole() === 'OWNER' ||
          getCurrentUserRole() === 'MANAGER') &&
        member.role.type !== 'OWNER'
      );
  };
  const handleEditMember = async (): Promise<void> => {
    try {
      const res = await callApi<any>(
        `organizations/members/${member.accountKey}`,
        'PUT',
        JSON.stringify({
          organization_key: userState.currentOrganization?.accountKey,
          role: role,
          name: name,
        })
      );
      if (res && res.status === 200) {
        await getActiveMember();
        setIsOpen(false);
      } else if (res && res.message) {
        setErrorMessage(res.message);
      }
    } catch (err) {
      setErrorMessage('Something went wrong. Please try again.');
    }
  };

  const handleDeleteMember = async (): Promise<void> => {
    try {
      setDeleteLoading(true);
      const res = await callApi<any>(
        `organizations/delete_member`,
        'DELETE',
        JSON.stringify({
          organization_key: userState.currentOrganization?.accountKey,
          member_account_key: member.accountKey,
        })
      );
      if (res.status !== 200) setErrorMessage(res.message);
      else {
        if (member.accountKey === userState.data?.accountKey) {
          setUserState((prevState: any) => ({
            data: {
              ...prevState.data,
              isActive: true,
              organizations: prevState.data.organizations.filter(
                (item: any) =>
                  item.organization_data.account_key !==
                  userState.currentOrganization?.accountKey
              ),
            },
            isSwitchingAccount: prevState.isSwitchingAccount,
          }));
          navigate('/files');
        } else {
          setUserState((prevState: any) => ({
            ...prevState,
            currentOrganization: {
              ...prevState.currentOrganization,
              members: prevState.currentOrganization.members.filter(
                (item: any) => item.accountKey !== member.accountKey
              ),
            },
          }));
          setStateAfterDelete(member.accountKey);
        }
        setIsOpen(false);
      }
    } catch (e) {
      setErrorMessage('Something went wrong. Please try again.');
    } finally {
      setIsCancelDeleteOpen(false);
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    setName(member.name);
    setRole(member.role.type);
  }, [member]);

  useEffect(() => {
    if (isOpen && errorMessage) {
      setErrorMessage('');
    }
  }, [isOpen]);

  return (
    <SideMenu
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position='CENTER'
      width='REGULAR'
    >
      <div className='Header--newfolder'>
        <Heading
          title='Edit Member'
          subtitle='You are about to change this users role and name, be careful!'
        />
        <TextInput
          placeHolder='Search by username, full name or email'
          name='Name'
          value={name}
          setValue={setName}
        />
        <SelectInput
          name='Role'
          value={role}
          setValue={setRole}
          options={options}
        />
        <ServerError error={errorMessage} />
        <Button
          name='Edit Member'
          click={handleEditMember}
          disabled={name === member.name && role === member.role.type}
        />
        {getDeleteButton() && (
          <Button
            name='Delete Member'
            color='RED'
            click={(): void => setIsCancelDeleteOpen(true)}
            loading={deleteLoading}
          />
        )}
        <SideMenu
          isOpen={isCancelDeleteOpen}
          setIsOpen={setIsCancelDeleteOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Deleting a Member'
              subtitle={
                member.accountKey === userState.data?.accountKey
                  ? 'Are you sure you want to delete yourself from the organization? In this case, you will leave it'
                  : 'Are you sure you want to delete the member from the organization? In this case, member will leave it'
              }
            />
            <ServerError error={errorMessage} />
            <div className='AddMemberModal--button-wrapper'>
              <Button
                name='Yes'
                click={async (): Promise<void> => await handleDeleteMember()}
                color='RED'
              />
              <Button
                name='No'
                click={(): void => {
                  setErrorMessage('');
                  setIsCancelDeleteOpen(false);
                }}
              />
            </div>
          </div>
        </SideMenu>
      </div>
    </SideMenu>
  );
};

export { EditMemberModal };
