import { useMemo, useEffect, useState } from 'react';
import { useGlobalUserState } from './useGlobalUserState';

export const useBillingNav = (): any[] => {
  const { userState } = useGlobalUserState();
  const [isFundingAllowed, setIsFundingAllowed] = useState<boolean>(false);

  useEffect(() => {
    if (!userState.data?.isActive) {
      if (
        userState.data?.organizations &&
        userState.data?.organizations.length > 0
      ) {
        const orgRole = userState.data?.organizations.find(
          (org: any) => org.is_active
        );
        setIsFundingAllowed(
          orgRole?.role === 'owner' || orgRole?.role === 'billing'
        );
      }
    } else {
      setIsFundingAllowed(true);
    }
  }, [userState.data?.isActive]);

  const navArr = useMemo(() => {
    return isFundingAllowed
      ? [
          { name: 'Usage', path: '/billing/usage' },
          { name: 'Funding', path: '/billing/funding' },
          { name: 'Invoices', path: '/billing/invoices' },
        ]
      : [
          { name: 'Usage', path: '/billing/usage' },
          { name: 'Invoices', path: '/billing/invoices' },
        ];
  }, [isFundingAllowed]);

  return navArr;
};
