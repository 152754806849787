/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ReactElement,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from './Button';

export interface PropsShape {
  stripeClientSecret: string;
  isAddOrgModal?: boolean;
  setIsPaymentAdded?: Dispatch<SetStateAction<boolean>>;
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const SaveCard = ({
  stripeClientSecret,
  isAddOrgModal,
  setIsPaymentAdded,
}: PropsShape): ReactElement => {
  const options = {
    clientSecret: stripeClientSecret!,
    appearance: {},
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm
        isAddOrgModal={isAddOrgModal}
        setIsPaymentAdded={setIsPaymentAdded}
      />
    </Elements>
  );
};

const SetupForm = ({ isAddOrgModal, setIsPaymentAdded }: any): ReactElement => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    if (clientSecret) {
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        switch (setupIntent?.status) {
          case 'succeeded':
            setMessage('Success! Your payment method has been saved.');
            break;
          case 'processing':
            setMessage(
              "Processing payment details. We'll update you when processing is complete."
            );
            break;
          case 'requires_payment_method':
            setMessage(
              'Failed to process payment details. Please try another payment method.'
            );
            break;
        }
      });
    }
  }, [stripe]);

  const handleSubmit = async (
    event: any,
    isAddOrgModal?: boolean
  ): Promise<any> => {
    event.preventDefault();
    setIsSuccess(false);
    if (setIsPaymentAdded) {
      setIsPaymentAdded(false);
    }
    if (!stripe || !elements) {
      return;
    }
    const { error } = isAddOrgModal
      ? await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${process.env.REACT_APP_FRONT_END_URL}/account/settings`,
          },
          redirect: 'if_required',
        })
      : await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${process.env.REACT_APP_FRONT_END_URL}/billing/funding`,
          },
        });
    if (error) {
      setErrorMessage(error.message!);
    } else {
      setIsSuccess(true);
      if (setIsPaymentAdded) {
        setIsPaymentAdded(true);
      }
    }
  };
  return (
    <form onSubmit={async (e): Promise<void> => handleSubmit(e, isAddOrgModal)}>
      {message}
      {!isSuccess && <PaymentElement />}
      {errorMessage && <div>{errorMessage}</div>}
      {isSuccess ? (
        <div style={{ color: '#357142', marginTop: '12px' }}>
          Success! Your payment method has been saved.
        </div>
      ) : (
        <Button name='Save Card' type='submit' />
      )}
    </form>
  );
};
export { SaveCard };
