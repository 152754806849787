import { ReactElement } from 'react';
import { DateTime } from './DateTime';

export interface PropsShape {
  children?: ReactElement | ReactElement[] | string;
  name: ReactElement | string;
  email: ReactElement | string;
  role: string;
  time: ReactElement | number;
  active?: boolean;
  border?: boolean;
}

const PeopleItem = ({
  children,
  name,
  email,
  role,
  time,
  active = false,
  border = false,
}: PropsShape): ReactElement => {
  return (
    <div
      className='SettingsItem--inner'
      style={{
        borderBottom: border ? '1px solid #e1e1e1' : 'none',
      }}
    >
      <div className='People--left'>
        <div className='People--avatar'></div>
        <div className='People--left-wrapper'>
          <div className='People--left-wrapper-title'>{name}</div>
          <div className='People--left-wrapper-subtitle'>{email}</div>
        </div>
        <div className='People--left-wrapper'>
          <div className='People--left-wrapper-title'>{role}</div>
          <div className='People--left-wrapper-subtitle'>
            {active ? <span>{'Seen: '}</span> : <span>{'Invited: '}</span>}
            <DateTime showTime value={new Date(time as number)} />
          </div>
        </div>
      </div>
      <div className='SettingsItem--right'>{children}</div>
    </div>
  );
};
export { PeopleItem };
