// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function clear() {
  window.addEventListener('load', async () => {
    if ('serviceWorker' in navigator) {
      try {
        // Unregister all service workers
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
          await registration.unregister();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Service Worker unregister failed: ', e);
      }
    }

    // Clear all caches
    if ('caches' in window) {
      try {
        const cacheNames = await caches.keys();
        for (const name of cacheNames) {
          await caches.delete(name);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Cache deletion failed: ', e);
      }
    }
  });
}
