import { ReactElement } from 'react';

interface PropsShape {
  label: boolean;
  name: string;
  description: string;
  onButtonClick: () => void;
  isActive?: boolean;
}

const ButtonInput = ({
  label = false,
  name,
  description,
  onButtonClick,
  isActive = false,
}: PropsShape): ReactElement => {
  return (
    <div className='ButtonInput' style={{ color: '#D9D9D9' }}>
      {label && (
        <label
          className='ButtonInput--label'
          style={isActive ? { color: '#2d73ff' } : { color: 'initial' }}
          htmlFor={name}
        >
          {name}
        </label>
      )}
      <>
        <button
          className={
            !isActive ? 'ButtonInput--input' : 'ButtonInput--input active'
          }
          onClick={(): void => onButtonClick()}
        />
      </>
      {description && (
        <small className='ButtonInput--description'>{description}</small>
      )}
    </div>
  );
};

export default ButtonInput;
