interface useEncryptionShape {
  transformStringToUint8Array: (stringValue: string) => Uint8Array;
  transformUint8ArrayToString: (stringValue: Uint8Array) => string;
  syncEncrypt: (
    data: any,
    password: string,
    salt: Uint8Array | string,
    iv: Uint8Array | string
  ) => Promise<ArrayBuffer>;
  syncDecrypt: (
    data: any,
    password: string,
    salt: Uint8Array | string,
    iv: Uint8Array | string
  ) => Promise<ArrayBuffer>;
  asyncEncrypt: (
    data: any,
    pubKey: Uint8Array | string
  ) => Promise<ArrayBuffer>;
  asyncDecrypt: (
    encryptedData: any,
    privateKey: Uint8Array | string
  ) => Promise<ArrayBuffer>;
}

const useEncryption = (): useEncryptionShape => {
  const transformUint8ArrayToString = (stringValue: Uint8Array): string => {
    let binaryString = '';
    stringValue.forEach((byte) => (binaryString += String.fromCharCode(byte)));
    return btoa(binaryString);
  };

  const transformStringToUint8Array = (stringValue: string): Uint8Array => {
    const binaryString = atob(stringValue);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  const derivePBKDF2Key = async (
    password: string,
    salt: Uint8Array
  ): Promise<CryptoKey> => {
    const encodedPassword = new TextEncoder().encode(password);
    try {
      const keyMaterial = await window.crypto.subtle.importKey(
        'raw',
        encodedPassword,
        { name: 'PBKDF2' },
        false,
        ['deriveKey']
      );
      return await window.crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          hash: 'SHA-256',
          salt,
          iterations: 1000,
        },
        keyMaterial,
        { name: 'AES-GCM', length: 256 },
        true,
        ['encrypt', 'decrypt']
      );
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error.message || error?.body?.message || error);
    }
  };

  const syncEncrypt = async (
    data: any,
    password: string,
    salt: Uint8Array | string,
    iv: Uint8Array | string
  ): Promise<ArrayBuffer> => {
    try {
      const bytesSalt =
        typeof salt === 'string' ? transformStringToUint8Array(salt) : salt;
      const bytesIv =
        typeof iv === 'string' ? transformStringToUint8Array(iv) : iv;
      const key = await derivePBKDF2Key(password, bytesSalt);
      const encryptedData = await window.crypto.subtle.encrypt(
        {
          name: 'AES-GCM',
          iv: bytesIv,
        },
        key,
        data
      );
      return encryptedData;
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error.message || error?.body?.message || error);
    }
  };

  const syncDecrypt = async (
    data: any,
    password: string,
    salt: Uint8Array | string,
    iv: Uint8Array | string
  ): Promise<ArrayBuffer> => {
    try {
      const bytesSalt =
        typeof salt === 'string' ? transformStringToUint8Array(salt) : salt;
      const bytesIv =
        typeof iv === 'string' ? transformStringToUint8Array(iv) : iv;
      const key = await derivePBKDF2Key(password, bytesSalt);
      const decryptedData = await window.crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv: bytesIv,
        },
        key,
        data
      );
      return decryptedData;
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error.message || error?.body?.message || error);
    }
  };

  const asyncEncrypt = async (
    data: any,
    pubKey: Uint8Array | string
  ): Promise<ArrayBuffer> => {
    try {
      const bytesPubKey =
        typeof pubKey === 'string'
          ? transformStringToUint8Array(pubKey)
          : pubKey;
      const cryptoKey = await window.crypto.subtle.importKey(
        'spki',
        bytesPubKey.buffer,
        {
          name: 'RSA-OAEP',
          hash: 'SHA-256',
        },
        true,
        ['encrypt']
      );
      const encryptedData = await window.crypto.subtle.encrypt(
        {
          name: 'RSA-OAEP',
        },
        cryptoKey,
        data
      );
      return encryptedData;
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error.message || error?.body?.message || error);
    }
  };

  const asyncDecrypt = async (
    encryptedData: any,
    privateKey: Uint8Array | string
  ): Promise<ArrayBuffer> => {
    try {
      const bytesPrivateKey =
        typeof privateKey === 'string'
          ? transformStringToUint8Array(privateKey)
          : privateKey;
      const cryptoKey = await window.crypto.subtle.importKey(
        'pkcs8',
        bytesPrivateKey.buffer,
        {
          name: 'RSA-OAEP',
          hash: 'SHA-256',
        },
        true,
        ['decrypt']
      );
      const decryptedData = await window.crypto.subtle.decrypt(
        {
          name: 'RSA-OAEP',
        },
        cryptoKey,
        encryptedData
      );
      return decryptedData;
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error.message || error?.body?.message || error);
    }
  };

  return {
    transformStringToUint8Array,
    transformUint8ArrayToString,
    syncEncrypt,
    syncDecrypt,
    asyncEncrypt,
    asyncDecrypt,
  };
};

export default useEncryption;
