import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { Menu } from '../../components/Menu';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { Card } from '../../components/Card';
import { SettingsItem } from '../../components/SettingsItem';
import { PeopleItem } from '../../components/PeopleItem';
import { Button } from '../../components/Button';
import { AddMemberModal } from '../../components/AddMemberModal';
import { callApi } from '../../functions/callApi';
import { memberRoles, memberShape } from '../../interfaces/user';
import { EditMemberModal } from '../../components/EditMemberModal';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { ServerError } from '../../components/ServerError';
import { TextInput } from '../../components/TextInput';
// import { useNavigate } from 'react-router-dom';
import { useMainNav } from '../../hooks/useMainNav';

const options = [
  { value: 'OWNER', name: 'Owner' },
  { value: 'MANAGER', name: 'Manager' },
  { value: 'BILLING', name: 'Billing' },
  { value: 'GENERAL_USER', name: 'General User' },
];

function People(): ReactElement {
  const { userState, setUserState } = useGlobalUserState();
  // const navigate = useNavigate();
  const [isAddMemberOpen, setIsAddMemberOpen] = useState<boolean>(false);
  const [isEditMemberOpen, setIsEditMemberOpen] = useState<boolean>(false);
  const [isCancelInviteOpen, setIsCancelInviteOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [cancelInvite, setCancelInvite] = useState<any>('');
  const [editMember, setEditMember] = useState<memberShape>();
  const [members, setMembers] = useState<memberShape[]>([]);
  const [invites, setInvites] = useState<[]>([]);
  const [newOrgName, setNewOrgName] = useState<string>(
    userState.currentOrganization?.name || ''
  );
  const [isEditOrgOpen, setIsEditOrgOpen] = useState<boolean>(false);
  // const [isDeleteOrgOpen, setIsDeleteOrgOpen] = useState<boolean>(false);
  const navList = useMainNav();

  const adaptMembersData = (data: any[]): memberShape[] => {
    return data.map((item) => ({
      accountKey: item.account_id,
      name: item.organization_name,
      email: item.owner_email,
      avatar: item.owner_avatar,
      role: {
        id: item.id,
        type: item.type.toUpperCase() as memberRoles,
      },
      isActive: item.is_active,
      lastUpload: new Date(item.last_seen_at).getTime(),
    }));
  };

  const getActiveMember = async (): Promise<void> => {
    const res = await callApi<any>(
      `organizations/members`,
      'POST',
      JSON.stringify({
        organization_id: userState.currentOrganization?.accountKey,
      })
    );

    if (res && res.members) {
      const membersToAdd = adaptMembersData(res.members);
      setUserState((prevState: any) => ({
        ...prevState,
        currentOrganization: {
          ...prevState.currentOrganization,
          members: membersToAdd,
        },
      }));
      setMembers(membersToAdd);
    }
  };

  const getPendingInvites = async (): Promise<void> => {
    const res = await callApi<any>(
      `invites/org-invites`,
      'POST',
      JSON.stringify({
        organization_key: userState.currentOrganization?.accountKey,
      })
    );

    if (res && res.results) {
      setUserState((prevState: any) => ({
        ...prevState,
        currentOrganization: {
          ...prevState.currentOrganization,
          invites: res.results,
        },
      }));
      setInvites(res.results);
    } else {
      setInvites([]);
    }
  };

  const correctName = (name: string, accKey: string): string => {
    if (userState.data?.accountKey === accKey) {
      return `${name} (you)`;
    }
    return name;
  };

  const roleToView = (role: string): string => {
    return options.find((item) => item.value === role)?.name || 'General User';
  };

  const handleCancelInvite = async (email: any): Promise<void> => {
    setErrorMessage('');
    const res = await callApi<any>(
      `invite/dev/update-is-archived`,
      'POST',
      JSON.stringify({ email })
    );
    if (res && res.status === 200) {
      setIsCancelInviteOpen(false);
      await getPendingInvites();
    } else if (res && res.message) {
      setErrorMessage(res.message);
    }
  };

  const handleOrgNameEdit = async (): Promise<void> => {
    setErrorMessage('');
    const res = await callApi<any>(
      `organizations/${userState.currentOrganization?.accountKey}`,
      'PUT',
      JSON.stringify({
        organization_name: newOrgName,
      })
    );
    if (res && res.status === 200) {
      setUserState((prevState: any) => ({
        ...prevState,
        currentOrganization: {
          ...prevState.currentOrganization,
          name: res.organization_name,
        },
      }));
      setIsEditOrgOpen(false);
      await getActiveMember();
    } else if (res && res.message) {
      setErrorMessage(res.message);
    }
  };

  // const handleDeleteOrg = async (): Promise<void> => {
  //   setErrorMessage('');
  //   setUserState((prevState: any) => ({
  //     ...prevState,
  //     isSwitchingAccount: true,
  //   }));
  //   const selectPrivateAccRes = await callApi<any>(
  //     `organizations/select_active`,
  //     'POST',
  //     JSON.stringify({
  //       organization_id: userState.currentOrganization?.accountKey,
  //       isUserAccount: true,
  //     })
  //   );
  //   if (selectPrivateAccRes && selectPrivateAccRes.status === 200) {
  //     const updatedOrganizations = userState?.data?.organizations?.map(
  //       (org: organizationShape) => {
  //         const updatedOrg = selectPrivateAccRes.organizations.find(
  //           (o: any) => o.organization_id === org.key
  //         );
  //         if (updatedOrg) {
  //           return { ...org, is_active: updatedOrg.is_active };
  //         }
  //         return org;
  //       }
  //     );

  //     setUserState((prevState: any) => ({
  //       ...prevState,
  //       data: {
  //         ...prevState.data,
  //         isActive: true,
  //         organizations: updatedOrganizations,
  //       },
  //       isSwitchingAccount: false,
  //     }));

  //     const deleteRes = await callApi<any>(
  //       `organizations/${userState.currentOrganization?.accountKey}`,
  //       'DELETE'
  //     );
  //     if (deleteRes && deleteRes.status === 200) {
  //       setUserState((prevState: any) => ({
  //         ...prevState,
  //         data: {
  //           ...prevState.data,
  //           organizations: prevState.data.organizations.filter(
  //             (org: any) =>
  //               org.key !== userState.currentOrganization?.accountKey
  //           ),
  //         },
  //         currentOrganization: null,
  //       }));

  //       setIsDeleteOrgOpen(false);
  //       setIsEditOrgOpen(false);
  //       navigate('/files');
  //     } else if (deleteRes && deleteRes.message) {
  //       setErrorMessage(deleteRes.message);
  //     }
  //   }
  // };
  const handleDeleteMemberFromState = (memberAccountKey: string): void => {
    setMembers((prevState: any) =>
      prevState.filter((item: any) => item.accountKey !== memberAccountKey)
    );
  };

  useEffect(() => {
    (async (): Promise<void> => {
      await getActiveMember();
      await getPendingInvites();
    })();
  }, []);

  return (
    <div className='ApiKeys'>
      <Header />
      <div className='ApiKeys--center'>
        <AddMemberModal
          isOpen={isAddMemberOpen}
          setIsOpen={setIsAddMemberOpen}
          getActiveMember={getActiveMember}
          getPendingInvites={getPendingInvites}
        />
        {editMember && (
          <EditMemberModal
            isOpen={isEditMemberOpen}
            setIsOpen={setIsEditMemberOpen}
            member={editMember}
            getActiveMember={getActiveMember}
            setStateAfterDelete={handleDeleteMemberFromState}
          />
        )}
        <SideMenu
          isOpen={isCancelInviteOpen}
          setIsOpen={setIsCancelInviteOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Cancel Invite'
              subtitle='Are you sure you want to cancel this invite?'
            />
            <ServerError error={errorMessage} />
            <div className='AddMemberModal--button-wrapper'>
              <Button
                name='Yes'
                click={async (): Promise<void> =>
                  await handleCancelInvite(cancelInvite.email)
                }
                color='RED'
              />
              <Button
                name='No'
                click={(): void => {
                  setErrorMessage('');
                  setIsCancelInviteOpen(false);
                }}
              />
            </div>
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isEditOrgOpen}
          setIsOpen={setIsEditOrgOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading title='Edit Organization' subtitle='' />
            <TextInput
              name='Name'
              value={newOrgName}
              setValue={setNewOrgName}
              label
            />
            <ServerError error={errorMessage} />
            <Button name='Edit Name' click={handleOrgNameEdit} />
            {/* <Button
              name='Delete Organization'
              color='RED'
              click={(): void => setIsDeleteOrgOpen(true)}
            /> */}
          </div>
        </SideMenu>
        {/* <SideMenu
          isOpen={isDeleteOrgOpen}
          setIsOpen={setIsDeleteOrgOpen}
          position='CENTER'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Delete Organization'
              subtitle='Are you sure you want to delete this organization?'
            />
            <ServerError error={errorMessage} />
            <div className='AddMemberModal--button-wrapper'>
              <Button
                name='Yes'
                click={async (): Promise<void> => await handleDeleteOrg()}
                color='RED'
              />
              <Button
                name='No'
                click={(): void => {
                  setErrorMessage('');
                  setIsDeleteOrgOpen(false);
                }}
              />
            </div>
          </div>
        </SideMenu> */}
        <div className='ApiKeys--title'>Account</div>
        <div className='ApiKeys--grid'>
          <div>
            <Menu items={navList} selected='People' />
          </div>

          <Card>
            <SettingsItem
              title='Organization'
              subtitle={`Welcome to the ${userState.currentOrganization?.name} page. Here you can manage your organization's details, view and interact with current members, and track pending invitations.`}
              border
            >
              <Card>
                <SettingsItem
                  inner
                  title={
                    <div className='People--grid-organization'>
                      <div className='People--avatar' />
                      <div className='People--name'>
                        {userState.currentOrganization?.name}
                      </div>
                    </div>
                  }
                  subtitle=''
                >
                  <Button
                    name='Edit'
                    margin={0}
                    click={(): void => {
                      setErrorMessage('');
                      setIsEditOrgOpen(!isEditOrgOpen);
                    }}
                  />
                </SettingsItem>
              </Card>
              <div style={{ padding: '15px 0', height: '1px' }} />
            </SettingsItem>
            <div style={{ padding: '15px 0', height: '1px' }} />
            <SettingsItem
              title='Active people'
              subtitle='This section lists all the current members of your organization. You can see their roles and their contact details. Use this list to manage your team and collaborate effectively.'
              button='Add a person'
              buttonClick={(): void => setIsAddMemberOpen(!isAddMemberOpen)}
              border
            >
              <Card>
                <>
                  {members &&
                    members.length > 0 &&
                    members.map((item: memberShape, index: number) => {
                      return (
                        <PeopleItem
                          active
                          key={index}
                          name={correctName(item.name, item.accountKey)}
                          email={item.email}
                          role={roleToView(item.role.type)}
                          time={item.lastUpload}
                          border
                        >
                          <Button
                            name='Edit'
                            margin={0}
                            click={(): void => {
                              setEditMember(item);
                              setIsEditMemberOpen(!isEditMemberOpen);
                            }}
                          />
                        </PeopleItem>
                      );
                    })}
                </>
              </Card>
              <div style={{ padding: '15px 0', height: '1px' }} />
            </SettingsItem>
            <div style={{ padding: '15px 0', height: '1px' }} />
            <SettingsItem
              title='Pending Invites'
              subtitle={`Here you'll find a list of users who have been invited to join your organization but have not yet accepted the invitation. You can manage pending requests from this section.`}
            >
              <Card>
                <>
                  {invites.map((item: any, index: number) => {
                    return (
                      <PeopleItem
                        key={index}
                        name={''}
                        email={item.email}
                        role={'General User'}
                        time={item.date_requested}
                        border
                      >
                        <Button
                          name='Cancel'
                          margin={0}
                          click={(): void => {
                            setErrorMessage('');
                            setCancelInvite(item);
                            setIsCancelInviteOpen(!isCancelInviteOpen);
                          }}
                        />
                      </PeopleItem>
                    );
                  })}
                  {!invites?.length && (
                    <div style={{ textAlign: 'center', padding: '12px' }}>
                      There are no invitations yet or you don't have permission
                      to view.
                    </div>
                  )}
                </>
              </Card>
            </SettingsItem>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default People;
