import { ReactElement } from 'react';
import { Button } from './Button';

export interface PropsShape {
  barText: string;
  buttonName: string;
  stripeInvoiceLink?: string;
  onButtonClick?: () => void;
}

const TopNagBar = ({
  stripeInvoiceLink,
  barText,
  buttonName,
  onButtonClick,
}: PropsShape): ReactElement => {
  return (
    <div
      className={`TopNagBar TopNagBar--${stripeInvoiceLink ? 'red' : 'green'}`}
    >
      <div className='TopNagBar--text'>{barText}</div>
      {stripeInvoiceLink ? (
        <a
          className='TopNagBar--button'
          href={`${stripeInvoiceLink}`}
          target='_blank'
        >
          <Button name={buttonName} size='MEDIUM' color='RED' margin={8} />
        </a>
      ) : (
        <Button
          name={buttonName}
          size='MEDIUM'
          color='GREEN'
          margin={8}
          click={onButtonClick}
        />
      )}
    </div>
  );
};
export { TopNagBar };
