import { useGlobalUserState } from './useGlobalUserState';
import { useCookies } from 'react-cookie';

export const useMainNav = (): any[] => {
  const { userState } = useGlobalUserState();
  const [cookie] = useCookies(['webAuthn']);

  const calcNav = (): any[] => {
    const navArr = [
      { name: 'Security', path: '/account/security' },
      { name: 'Notifications', path: '/account/notifications' },
      { name: 'Settings', path: '/account/settings' },
      { name: 'API Keys', path: '/account/api-keys' },
      ...(cookie.webAuthn
        ? [{ name: 'Encryption', path: '/account/encryption' }]
        : []),
    ];

    switch (userState.data?.userRole) {
      case 'SUPERADMIN': {
        navArr.push({ name: 'Storage Nodes', path: '/account/nodes' });
        navArr.push({ name: 'Users', path: '/account/users' });
        navArr.push({
          name: 'Organizations',
          path: '/account/organizations',
        });
        break;
      }
      case 'ADMIN':
        navArr.push({ name: 'Users', path: '/account/users' });
        navArr.push({
          name: 'Organizations',
          path: '/account/organizations',
        });
        break;
      case 'NODE_HANDLER':
        navArr.push({ name: 'Storage Nodes', path: '/account/nodes' });
        break;
    }

    if (
      userState.currentOrganization &&
      !userState.data?.isActive &&
      !(
        userState.data?.userRole === 'ADMIN' ||
        userState.data?.userRole === 'SUPERADMIN'
      ) &&
      userState.currentOrganization.userRole === 'NODE_HANDLER'
    ) {
      const isNameExists = navArr.some((item) => item.name === 'Storage Nodes');
      if (!isNameExists) {
        navArr.push({
          name: 'Storage Nodes',
          path: `/account/nodes`,
        });
      }
    }
    if (userState.currentOrganization && !userState.data?.isActive) {
      navArr.push({
        name: 'People',
        path: `/account/people`,
      });
    }
    return navArr;
  };

  return calcNav();
};
