import { ReactElement, useState, useEffect } from 'react';
import { TextInput } from './TextInput';
import { isEmail } from '../functions/validators';
import logo from '../images/dStor.svg';
import linkedinGrey from '../images/linkedin-grey.svg';
import twitterGrey from '../images/twitter-grey.svg';
import telegramGrey from '../images/telegram-grey.svg';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const Footer = (): ReactElement => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [error]);

  return (
    <div className='Footer'>
      <div className='Footer--center'>
        <div className='Footer--top'>
          <div>
            <div className='Footer--bottom-title'>Product</div>
            <ul>
              <a href='https://goodblock.gitbook.io/dstor/'>
                <li>User guide</li>
              </a>
              <a href='/product/what-is-dstor'>
                <li>What is dStor</li>
              </a>
              {/* <a href='/product/why-dstor'>
                <li>Why dStor</li>
              </a> */}
              {/* <a href='#'>
                <li>dStor in your stack</li>
              </a> */}
              <a href='/pricing'>
                <li>Pricing</li>
              </a>
            </ul>
          </div>
          <div>
            <div className='Footer--bottom-title'>Developers</div>
            <ul>
              {/* <a href='/developers/documentation'>
                <li>Documentation</li>
              </a> */}
              {/* <a href='#'>
                <li>Start integrating</li>
              </a>
              <a href='#'>
                <li>Community</li>
              </a> */}
              <a href='/developers/storage-node'>
                <li>Operate a storage node</li>
              </a>
            </ul>
          </div>
          {/* <div>
            <div className='Footer--bottom-title'>Use Case</div>
            <ul>
              <a href='#'>
                <li>Customer stories</li>
              </a>
              <a href='#'>
                <li>Enterprise</li>
              </a>
            </ul>
          </div> */}
          <div>
            <div className='Footer--bottom-title'>Company</div>
            <ul>
              <a href='/company/about'>
                <li>About</li>
              </a>
              {/* <a href='#'>
                <li>Blog</li>
              </a> */}
              {/* <a href='/company/careers'>
                <li>Careers</li>
              </a> */}
              {/* <a href='#'>
                <li>Events</li>
              </a> */}
            </ul>
          </div>
          <div>
            <div className='Footer--bottom-title'>Newsletter</div>
            <MailchimpSubscribe
              url='//cloud.us21.list-manage.com/subscribe/post-json?u=9b36c3f060d5c8a4c1b772750&id=31e0cec785'
              render={({ subscribe, status, message }): any => (
                <div>
                  <form
                    onSubmit={(e: any): any => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (isEmail(email)) {
                        setError('');
                        subscribe({ EMAIL: email });
                      } else {
                        setError('Invalid Email');
                      }
                    }}
                  >
                    <div style={{ marginTop: '12px', position: 'relative' }}>
                      <TextInput
                        name='email'
                        value={email}
                        setValue={setEmail}
                        placeHolder='Email address'
                      />
                      <button
                        className='Footer--bottom-subscribe'
                        type='submit'
                      />
                    </div>
                  </form>
                  {status === 'sending' && (
                    <div style={{ color: 'blue' }}>sending...</div>
                  )}

                  {error !== '' && <div>{'Invalid Email'}</div>}
                  {status === 'error' && <div>{message}</div>}
                  {status === 'success' && (
                    <div style={{ color: 'green' }}>Subscribed !</div>
                  )}
                </div>
              )}
            />
          </div>
        </div>
        <div className='Footer--bottom'>
          <img src={logo} />
          <div className='Footer--bottom-links'>
            <div>© 2023 GoodBlock Technologies, Inc. </div>
            <a href='/about/terms-of-use'>
              <div>Terms of use </div>
            </a>
            <a href='/about/privacy-policy'>
              <div>Privacy policy</div>
            </a>
            <div></div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto 1fr',
              gridGap: '12px',
            }}
          >
            <a href='https://www.linkedin.com/company/dstor/'>
              <img src={linkedinGrey} />
            </a>
            <a href='https://twitter.com/dStor_cloud'>
              <img src={twitterGrey} />
            </a>
            <a href='https://t.me/dStor_cloud'>
              <img src={telegramGrey} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Footer };
