import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { TextInput } from '../TextInput';
import { ServerError } from '../ServerError';
import { Heading } from '../Heading';
import { SaveCard } from '../SaveCard';
import { callApi } from '../../functions/callApi';
import { Button } from '../Button';
import image from '../../images/sean-anderson.jpg';
import defaultImage from '../../images/no-avatar.jpg';
import { AvatarPicker } from '../AvatarPicker';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { isEmail } from '../../functions/validators';
import { AddMemberSection } from '../AddMemberSection';
import { organizationShape } from '../../interfaces/user';
import { useNavigate } from 'react-router-dom';
import arrowIcon from '../../images/arrow-right-white.svg';
import arrowIconLeft from '../../images/arrow-left-white.svg';

export interface OrgFormData {
  orgName: string;
  orgEmail: string;
  orgImage?: File;
  cardNumber?: string;
  expiryDate?: string;
  cvc?: string;
  emails?: string[];
}

export interface StepProps {
  nextStep?: () => void;
  prevStep?: () => void;
  setIsPaymentAdded?: Dispatch<SetStateAction<boolean>>;
}

const StepOne: FC<StepProps> = ({ nextStep }) => {
  const { setUserState } = useGlobalUserState();
  const [formData, setFormData] = useState<OrgFormData>({
    orgName: '',
    orgEmail: '',
  });
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeName = (name: any): void => {
    setFormData({ ...formData, orgName: name });
  };

  const handleChangeEmail = (email: any): void => {
    setFormData({ ...formData, orgEmail: email });
  };

  // const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
  //   if (e.target.files && e.target.files[0]) {
  //     setFormData({ ...formData, orgImage: e.target.files[0] });
  //   }
  // };

  const handleNext = async (): Promise<void> => {
    if (!formData?.orgName && formData?.orgName.length < 1) {
      setError('Organization name is required');
    } else if (!formData.orgEmail && formData.orgEmail.length < 1) {
      setError('Organization email is required');
    } else if (!isEmail(formData.orgEmail)) {
      setError('Email invalid');
    } else {
      setError('');
      setIsLoading(true);
      try {
        const res = await callApi<any>(
          `organizations/add`,
          'POST',
          JSON.stringify({
            organization_name: formData.orgName,
            organization_email: formData.orgEmail,
          })
        );
        if (res.status === 200 && nextStep) {
          setUserState((prevState: any) => ({
            ...prevState,
            data: {
              ...prevState.data,
              newOrganization: res.newOrganization,
              organizations: res.organizations,
            },
          }));
          setIsLoading(false);
          nextStep();
        } else if (res.status !== 200) {
          setError(res.message);
          setIsLoading(false);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <TextInput
        type='text'
        name='Organization Name:'
        placeHolder='For Example: "Design Storage", "Development Storage"'
        value={formData.orgName || ''}
        setValue={handleChangeName}
        label
      />
      <TextInput
        type='email'
        name='Organization Email:'
        placeHolder='example@gmail.com'
        value={formData.orgEmail || ''}
        setValue={handleChangeEmail}
        label
      />
      <AvatarPicker
        onImageUpload={(): void => {}}
        initialImage={image}
        noImage={defaultImage}
      />
      <ServerError error={error} />
      <Button name='Create' click={handleNext} loading={isLoading} />
    </div>
  );
};

const StepTwo: FC<StepProps> = ({ nextStep, setIsPaymentAdded }) => {
  const { userState } = useGlobalUserState();
  const [clientSecret, setClientSecret] = useState('');
  // const [lodingSecret, setLoadingSecret] = useState(false);

  const doAddCard = useCallback(async () => {
    // setLoadingSecret(true);
    const response = await callApi<any>(
      'payment/setup-intent-org-secret',
      'POST',
      JSON.stringify({
        org_owner_key: userState.data?.newOrganization?.account_key,
      })
    );
    const clientSecret = response.secret;
    setClientSecret(clientSecret);
    // setLoadingSecret(false);
  }, []);

  useEffect(() => {
    doAddCard();
  }, [doAddCard]);

  return (
    <div>
      <div className='AddMemberModal--button-wrapper'>
        <div />
        <Button
          name='Skip'
          click={nextStep}
          icon={arrowIcon}
          iconPosition='right'
          size='SMALL'
        />
      </div>
      <div>
        <Heading
          title='Add Card'
          subtitle='Enter card details below to add this card to organization funding methods'
        />
        {clientSecret && (
          <SaveCard
            stripeClientSecret={clientSecret}
            isAddOrgModal={true}
            setIsPaymentAdded={setIsPaymentAdded}
          />
        )}
      </div>
    </div>
  );
};

const StepThree: FC<StepProps> = ({ prevStep }) => {
  const { userState, setUserState } = useGlobalUserState();
  const navigate = useNavigate();

  const goToOrg = async (): Promise<void> => {
    try {
      setUserState((prevState: any) => ({
        ...prevState,
        isSwitchingAccount: true,
      }));
      const response = await callApi<any>(
        `organizations/select_active`,
        'POST',
        JSON.stringify({
          organization_id: userState.data?.newOrganization?.account_key,
          isUserAccount: false,
        })
      );

      if (response && response.status === 200) {
        const updatedOrganizations = userState?.data?.organizations?.map(
          (org: organizationShape) => {
            const updatedOrg = response.organizations.find(
              (o: any) => o.organization_id === org.key
            );
            if (updatedOrg) {
              return { ...org, is_active: updatedOrg.is_active };
            }
            return org;
          }
        );

        setUserState((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            isActive: false,
            organizations: updatedOrganizations,
          },
          isSwitchingAccount: false,
          currentOrganization: {
            ...prevState.currentOrganization,
            maxThreshold: response.currentOrganization.maximum_threshold_usd,
            isPreviewOn: response.currentOrganization.is_preview_on,
            accountKey: response.currentOrganization.account_key,
            accessToken: response.currentOrganization.access_token,
            name: response.currentOrganization.owner_name,
            email: response.currentOrganization.owner_email,
            encryption: {
              publicKey: response.currentOrganization.rsa_pub_key,
              selfHint: response.currentOrganization.self_hint,
            },
            lastUpload: Date.now(),
            isNodeOperator: response.currentOrganization.has_nodes,
            has_credit_card: response.currentOrganization.has_credit_card,
            pulse: {
              accountDeletedMail:
                response.currentOrganization.account_deleted_mail,
              accountDeletedPulse:
                response.currentOrganization.account_deleted_pulse,
              accountFrozenMail:
                response.currentOrganization.account_frozen_mail,
              accountFrozenPulse:
                response.currentOrganization.account_frozen_pulse,
              failedPaymentMail:
                response.currentOrganization.failed_payment_mail,
              failedPaymentPulse:
                response.currentOrganization.failed_payment_pulse,
              invoiceGeneratedMail:
                response.currentOrganization.invoice_generated_mail,
              invoiceGeneratedPulse:
                response.currentOrganization.invoice_generated_pulse,
              noPaymentMethodMail:
                response.currentOrganization.no_payment_method_mail,
              noPaymentMethodPulse:
                response.currentOrganization.no_payment_method_pulse,
              successfulPaymentMail:
                response.currentOrganization.successful_payment_mail,
              successfulPaymentPulse:
                response.currentOrganization.successful_payment_pulse,
              thresholdNotReachedMail:
                response.currentOrganization.threshold_not_reached_mail,
              thresholdNotReachedPulse:
                response.currentOrganization.threshold_not_reached_pulse,
            },
            unpaidInvoice: response.currentOrganization.unpaid_invoice,
          },
        }));
        navigate(userState.data?.loggedIn ? '/account/people' : '/');
      } else if (response.message) {
        // eslint-disable-next-line no-console
        console.error('Error:', response.message);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <div className='AddMemberModal--button-wrapper'>
        <Button
          name='Back'
          click={prevStep}
          icon={arrowIconLeft}
          size='SMALL'
        />
        <Button
          name='Go to organization'
          click={goToOrg}
          secondary
          noBorder
          icon={arrowIcon}
          iconPosition='right'
          size='SMALL'
        />
      </div>
      <AddMemberSection
        organizationKey={userState.data?.newOrganization?.account_key || ''}
      />
    </div>
  );
};

export { StepOne, StepTwo, StepThree };
