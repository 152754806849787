import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { callApi, callApiUpload, callApiUploadXhr } from '../functions/callApi';
import { useParams } from 'react-router-dom';
import { useGlobalUserState } from './useGlobalUserState';
import useEncryption from './useEncryption';
import generatePassword from '../functions/generatePassword';
import { FileShape } from '../interfaces/files';

const SUCCESS_UPLOAD_STATUS = 200;
const SUCCESS_UPLOAD_CHUNKS_STATUS = 'DONE';

export interface UploadErrorShape {
  error: string;
  message: string;
}

export interface FileDecryptionParamsShape {
  password: string;
  iv: string | Uint8Array;
  salt: string | Uint8Array;
}

interface useFilesTransferShape {
  doUpload: (
    fileArray: File[],
    isEncrypt: boolean,
    filesKey: (string | undefined)[]
  ) => Promise<void>;
  doDownload: (
    data: FileShape,
    isDecrypt: boolean,
    fileDecryptionParams?: FileDecryptionParamsShape
  ) => Promise<void>;
  uploading: boolean;
  setUploading: Dispatch<SetStateAction<boolean>>;
  filesToUpload: number;
  uploadError: UploadErrorShape;
  batchSize: number;
  batchesToUpload: string;
  uploadErrorMessage: string;
  uploadAbortMessage: string;
  progress: number;
  serverError: string;
  uploadLoading: boolean;
  uploadedBatches: number;
  numberOfBatches: number;
  isProgressValid: boolean;
  downloadLoading: boolean;
  downloadError: string;
  existingFilesName: string[];
}

interface encryptionParamsShape {
  fileName: string;
  password: string;
  iv: string;
  salt: string;
}

const useFilesTransfer = (): useFilesTransferShape => {
  const { userState, setUserState } = useGlobalUserState();
  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
  const CHUNK_SIZE_DECRYPT = 5 * 1024 * 1024 + 16;
  const params = useParams();
  const paramsArr = params['*']?.split('/') || [];
  const paramsKey = paramsArr[paramsArr.length - 1] || '';
  const [uploading, setUploading] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<number>(0);
  const [uploadError, setUploadError] = useState<UploadErrorShape | null>(null);
  const [batchSize, setBatchSize] = useState<number>(0);
  const [numberOfBatches, setNumberOfBatches] = useState<number>(0);
  const [completeBatches, setCompleteBatches] = useState<number>(0);
  const [requestFiles, setRequestFiles] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>('');
  const [uploadAbortMessage, setUploadAbortMessage] = useState<string>('');
  const [uploadStatus, setUploadStatus] = useState<number>(0);
  const [uploadedBatches, setUploadedBatches] = useState<number>(0);
  const [filesUploadProgress, setFilesUploadProgress] = useState<number>(0);
  const [filesUploadProgressArr, setFilesUploadProgressArr] = useState<
    Array<object>
  >([]);
  const [totalFilesSize, setTotalFilesSize] = useState<number>(0);
  const [uploadToken, setUploadToken] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [isEncryption, setIsEncryption] = useState<boolean>(false);
  const [keys, setKeys] = useState<string[]>([]);
  const [encryptionParams, setEncryptionParams] = useState<
    encryptionParamsShape[]
  >([]);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<string>('');
  const [existingFilesName, setExistingFilesName] = useState<string[]>([]);
  const {
    syncEncrypt,
    transformUint8ArrayToString,
    asyncEncrypt,
    syncDecrypt,
  } = useEncryption();

  useEffect(() => {
    (async (): Promise<void> => {
      if (uploadStatus === SUCCESS_UPLOAD_STATUS) {
        await getStatus(uploadToken);
        if (uploadedBatches === numberOfBatches) {
          setTimeout(async () => {
            if (isEncryption) {
              await callApi(
                'encryption/file/save-encryption-params',
                'POST',
                JSON.stringify({
                  files_params: encryptionParams,
                })
              );
              if (keys.length !== 0) {
                await callApi(
                  'file',
                  'DELETE',
                  JSON.stringify({
                    file_hash: '',
                    file_key: keys[0],
                    organization_key:
                      userState.currentOrganization?.accountKey || null,
                  })
                );
              }
            }
            setFilesToUpload(0);
            setUploadedBatches(0);
            setUploading(false);
            setEncryptionParams([]);
            setUserState((prevState: any) => ({
              ...prevState,
              data: {
                ...prevState.data,
                lastUpload: Date.now(),
              },
            }));
          }, 5000);
        }
      }
    })();
  }, [uploadStatus, uploadedBatches]);

  useEffect(() => {
    let interval: any;
    const getFiles = (): any => {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
    };
    if (requestFiles) {
      getFiles();
      interval = setInterval(() => getFiles(), 5000);
      return (): any => {
        clearInterval(interval);
      };
    } else {
      setTimeout(getFiles(), 2000);
      setTimeout(getFiles(), 4000);
      setTimeout(getFiles(), 6000);
      setTimeout(getFiles(), 10000);
      clearInterval(interval);
    }
  }, [requestFiles]);

  useEffect(() => {
    if (filesUploadProgressArr.length) {
      const filesUploadProgressArrCopy = [...filesUploadProgressArr];
      const reversedArr = filesUploadProgressArrCopy.reverse();
      const lastAdded = reversedArr.shift();
      const previousAdded = reversedArr.find(
        (fileProgress) =>
          Object.keys(fileProgress)[0] === Object.keys(lastAdded!)[0]
      );
      if (previousAdded) {
        setFilesUploadProgress(
          (prevState) =>
            prevState +
            Object.values(lastAdded!)[0] -
            Object.values(previousAdded!)[0]
        );
      } else {
        setFilesUploadProgress(
          (prevState) => prevState + Object.values(lastAdded!)[0]
        );
      }
    }
  }, [filesUploadProgressArr]);

  useEffect(() => {
    if (completeBatches && completeBatches === numberOfBatches) {
      setTimeout(() => {
        setUploading(false);
      }, 1000);
    }
  }, [completeBatches]);

  const calculateChecksum = async (data: any): Promise<string> => {
    const buffer = await data.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  };

  const resetProgress = (): void => {
    setCompleteBatches(0);
    setTotalFilesSize(0);
    setFilesUploadProgress(0);
    setFilesUploadProgressArr([]);
  };

  const createChunks = async (
    file: any,
    chunks: any[],
    isEncrypt: boolean = false,
    fileEncryptParams?: FileDecryptionParamsShape
  ): Promise<Array<any>> => {
    const chunksResult = [];
    if (isEncrypt && fileEncryptParams) {
      for await (const chunk of chunks) {
        const chunkArrayBuffer = await chunk.arrayBuffer();
        const encryptedChunk = new Blob([
          await syncEncrypt(
            chunkArrayBuffer,
            fileEncryptParams.password,
            fileEncryptParams.salt,
            fileEncryptParams.iv
          ),
        ]);
        chunksResult.push({
          chunk: encryptedChunk,
          name:
            chunks.length > 1
              ? `${file.webkitRelativePath || file.name}_${chunks.indexOf(
                  chunk
                )}`
              : `${file.webkitRelativePath || file.name}`,
          hash: await calculateChecksum(encryptedChunk),
        });
      }
    } else {
      for await (const chunk of chunks) {
        chunksResult.push({
          chunk,
          name:
            chunks.length > 1
              ? `${file.webkitRelativePath || file.name}_${chunks.indexOf(
                  chunk
                )}`
              : `${file.webkitRelativePath || file.name}`,
          hash: await calculateChecksum(chunk),
        });
      }
    }
    return chunksResult;
  };

  const encryptHandler = async (
    file: any,
    chunks: any[],
    pubKey: Uint8Array | string
  ): Promise<Array<any>> => {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const password = generatePassword(24, true, true, true, true);
    const encryptedPassword = new Uint8Array(
      await asyncEncrypt(new TextEncoder().encode(password).buffer, pubKey)
    );

    setEncryptionParams((prevState) => [
      ...prevState,
      {
        fileName: file.name,
        iv: transformUint8ArrayToString(iv),
        salt: transformUint8ArrayToString(salt),
        password: transformUint8ArrayToString(encryptedPassword),
      },
    ]);
    return await createChunks(file, chunks, true, { password, salt, iv });
  };

  const splitFileIntoChunks = async (
    file: any,
    chunkSize: number,
    isEncrypt: boolean = false
  ): Promise<Array<any>> => {
    const chunks = [];
    const pubKey =
      userState?.currentOrganization?.encryption?.publicKey ||
      userState.data?.encryption?.publicKey;
    let offset = 0;

    while (offset < file.size) {
      const end = Math.min(offset + chunkSize, file.size);
      const chunk = file.slice(offset, end);
      chunks.push(chunk);
      offset += chunkSize;
    }

    if (isEncrypt && pubKey) {
      return await encryptHandler(file, chunks, pubKey);
    } else {
      return await createChunks(file, chunks);
    }
  };

  const getTempBatchSize = (totalChunks: number): number => {
    if (1 < totalChunks && totalChunks <= 10) return 1;
    else if (10 < totalChunks && totalChunks <= 100) return 10;
    else if (100 < totalChunks && totalChunks <= 500) return 20;
    else if (500 < totalChunks && totalChunks <= 1000) return 30;
    else return 40;
  };

  const getToken = async (chunkCount: number): Promise<string> => {
    let token: string = '';
    try {
      const result = await callApi<any>(
        'upload/get-token/',
        'POST',
        JSON.stringify({
          chunks_number: chunkCount,
          folder_upload_to: paramsKey || null,
        })
      );
      token = result.token;
      if (result.status !== 200) {
        setServerError(result.message);
      }
    } catch (err: any) {
      setServerError(err.message);
    } finally {
      setUploadLoading(false);
    }
    return token;
  };

  const progressHandler = (e: ProgressEvent, i: number): void => {
    const loaded = e.loaded;
    setFilesUploadProgressArr((prevState) => [...prevState, { [i]: loaded }]);
  };

  const successHandler = (e: any): void => {
    const responseObj = JSON.parse(e.target.responseText);
    if (responseObj.status === SUCCESS_UPLOAD_CHUNKS_STATUS) {
      setExistingFilesName(
        responseObj.data
          .filter((file: any) => file.IsAlreadyExisting)
          .map((file: any) => file.Name) || []
      );
    }
    setUploadedBatches((prevState) => prevState + 1);
    setUploadStatus(e.target?.status);
  };

  const errorHandler = (): void => {
    setUploadErrorMessage('upload failed!!');
  };
  const abortHandler = (): void => {
    setUploadAbortMessage('upload aborted!!');
  };

  const getStatus = async (token: string): Promise<void> => {
    try {
      const result = await callApiUpload<any>(
        'upload/get-status/',
        'GET',
        token,
        null
      );
      setCompleteBatches(result?.chunks_uploaded || 0);
      const error = result?.errorAtStage || '';
      const message = result?.errorExplanation || '';
      error && setUploadError({ error, message });
    } catch (err: any) {
      setServerError(err.message);
    } finally {
      setUploadLoading(false);
    }
  };

  const doUpload = async (
    fileArray: File[],
    isEncrypt: boolean = false,
    filesKey: string[] = []
  ): Promise<void> => {
    resetProgress();
    const fileCount: number = fileArray.length;
    if (filesKey.length !== 0 && fileCount !== filesKey.length) {
      throw new Error(
        'The number of files and the number of file keys do not match'
      );
    }
    if (isEncrypt) {
      setIsEncryption(isEncrypt);
      if (filesKey.length !== 0) {
        setKeys(filesKey);
      }
    }
    const chunksArray: any[] = [];
    for await (const file of fileArray) {
      chunksArray.push(
        ...(await splitFileIntoChunks(file, CHUNK_SIZE, isEncrypt))
      );
    }
    const totalChunks = chunksArray.length;
    const tempBatchSize = getTempBatchSize(totalChunks);
    setBatchSize(tempBatchSize);
    const batchArray = Array.from(
      { length: Math.ceil(chunksArray.length / tempBatchSize) },
      (value, index) =>
        chunksArray.slice(
          index * tempBatchSize,
          index * tempBatchSize + tempBatchSize
        )
    );
    const tempNumberOfBatches = batchArray.length;
    setNumberOfBatches(tempNumberOfBatches);
    try {
      const token = await getToken(tempNumberOfBatches);
      if (token) {
        setUploadToken(token);
        setFilesToUpload(fileCount);
        setUploading(true);
        const xhr: XMLHttpRequest[] = [];
        for (const batch of batchArray) {
          setRequestFiles(true);
          const formData: FormData = new FormData();
          for (const chunkData of batch) {
            formData.append('file', chunkData.chunk, chunkData.name);
            formData.append('checksum', chunkData.hash);
            setTotalFilesSize((prevState) => prevState + chunkData.chunk.size);
          }
          await callApiUploadXhr(
            formData,
            xhr,
            token,
            batchArray.indexOf(batch),
            progressHandler,
            successHandler,
            errorHandler,
            abortHandler
          );
          await getStatus(token);
        }
      }
    } catch (err: any) {
      setServerError(err.message);
      alert(err);
      setUploading(false);
    } finally {
      setRequestFiles(false);
      setUploadLoading(false);
    }
  };

  const decryptHandler = async (
    file: any,
    fileName: string,
    fileDecryptionParams: FileDecryptionParamsShape
  ): Promise<File> => {
    const chunksArray: any[] = [];
    const decryptedChunks = [];
    chunksArray.push(...(await splitFileIntoChunks(file, CHUNK_SIZE_DECRYPT)));
    for await (const chunkObj of chunksArray) {
      const chunkArrayBuffer = await chunkObj.chunk.arrayBuffer();
      const decryptedChunk = new Blob([
        await syncDecrypt(
          chunkArrayBuffer,
          fileDecryptionParams.password,
          fileDecryptionParams.salt,
          fileDecryptionParams.iv
        ),
      ]);
      decryptedChunks.push(decryptedChunk);
    }
    const fileBlob = new Blob(decryptedChunks);
    return new File([fileBlob], fileName);
  };

  const downloadHandler = async (
    previewSrc: string,
    fileName: string,
    isDecrypt: boolean = false,
    fileDecryptionParams?: FileDecryptionParamsShape
  ): Promise<void> => {
    let fileResult;
    const fileData = await fetch(previewSrc);
    const blob = await fileData.blob();
    if (isDecrypt && fileDecryptionParams) {
      const file = new File([blob], `${fileName}`, { type: blob.type });
      fileResult = await decryptHandler(file, fileName, fileDecryptionParams);
    } else {
      fileResult = blob;
    }
    const blobUrl = URL.createObjectURL(fileResult);
    const download = document.createElement('a');
    download.setAttribute('href', blobUrl);
    download.setAttribute('download', fileName);
    document.body.appendChild(download);
    download.click();
    download.remove();
    setDownloadLoading(false);
  };

  const doDownload = async (
    data: FileShape,
    isDecrypt: boolean = false,
    fileDecryptionParams?: FileDecryptionParamsShape
  ): Promise<void> => {
    try {
      setDownloadLoading(true);
      setDownloadError('');
      if (isDecrypt && fileDecryptionParams) {
        let previewSrc;
        const preview = document.getElementById('preview');
        if (preview) {
          previewSrc = preview.getAttribute('src');
        } else previewSrc = `${process.env.REACT_APP_API_IPFS_URL}${data.hash}`;
        if (previewSrc) {
          await downloadHandler(
            previewSrc,
            data.name,
            true,
            fileDecryptionParams
          );
        }
      } else {
        const preview = document.getElementById('preview');
        if (preview) {
          const previewSrc = preview.getAttribute('src');
          if (previewSrc) {
            await downloadHandler(previewSrc, data.name);
          }
        } else {
          window.open(`${process.env.REACT_APP_API_IPFS_URL}${data.hash}`);
        }
      }
    } catch (error) {
      setDownloadError('An error occurred while downloading');
      setDownloadLoading(false);
    }
  };

  return <useFilesTransferShape>{
    doUpload,
    doDownload,
    uploading,
    setUploading,
    filesToUpload,
    uploadError,
    batchSize,
    batchesToUpload: `${
      numberOfBatches - completeBatches
    } / ${numberOfBatches}`,
    uploadErrorMessage,
    uploadAbortMessage,
    progress:
      (filesUploadProgress / totalFilesSize) * 100 > 100
        ? 100
        : (filesUploadProgress / totalFilesSize) * 100,
    serverError,
    uploadLoading,
    uploadedBatches,
    numberOfBatches,
    isProgressValid: (completeBatches / numberOfBatches) * 100 <= 100,
    downloadLoading,
    downloadError,
    existingFilesName,
  };
};

export default useFilesTransfer;
