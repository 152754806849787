import { ReactElement } from 'react';

export interface TextShape {
  title: string;
  text: string;
}

export interface PropsShape {
  text: TextShape[];
}

const TextGrid = ({ text }: PropsShape): ReactElement => {
  return (
    <>
      <div className='TextGrid'>
        <div className='TextGrid--center'>
          <div className='TextGrid--grid'>
            {text?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <div className='TextGrid--title'>{item.title}</div>
                  <div className='TextGrid--text'>{item.text}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export { TextGrid };
