import { ReactElement } from 'react';
import { Button } from './Button';
import { useGlobalUserState } from '../hooks/useGlobalUserState';

export interface PropsShape {
  title: string;
  subTitle: string;
  buttonText: string;
}

const Jumbo = ({ title, subTitle, buttonText }: PropsShape): ReactElement => {
  const { setUserState } = useGlobalUserState();
  const doLink = (): void => {
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        requestInvite: true,
      },
    }));
  };
  return (
    <div className='Jumbo'>
      <div className='Jumbo--center'>
        <h1 className='Jumbo--title'>{title}</h1>
        <div></div>
        {subTitle && <h2 className='Jumbo--sub-title'>{subTitle}</h2>}
        <div></div>
        <div className='Jumbo--button'>
          <Button name={buttonText} click={doLink} />
        </div>
      </div>
    </div>
  );
};
export { Jumbo };
