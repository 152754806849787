import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export interface MenuItemShape {
  name: string;
  path: string;
}

export interface PropsShape {
  items: MenuItemShape[];
  selected: string;
}

const Menu = ({ items, selected }: PropsShape): ReactElement => {
  const navigate = useNavigate();

  return (
    <div className='Menu'>
      <div className='Menu--menu-container'>
        <ul className='Menu--menu-items'>
          {items?.map((item: MenuItemShape, index: number) => {
            return (
              <li
                onClick={(): void => {
                  if (
                    item.name.toLocaleLowerCase() !==
                    selected.toLocaleLowerCase()
                  ) {
                    navigate(item.path);
                  }
                }}
                key={index}
                className={
                  item.name.toLocaleLowerCase() === selected.toLocaleLowerCase()
                    ? 'Menu--menu-item-selected'
                    : 'Menu--menu-item'
                }
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export { Menu };
