const getAuthToken = async (): Promise<string | null> => {
  const userData = localStorage.getItem('userState') || '';
  if (!userData) return null;
  const json = JSON.parse(userData);
  return json?.data?.accessToken || null;
};

async function callApi<T>(
  url: string,
  method: 'DELETE' | 'GET' | 'POST' | 'PATCH' | 'PUT' = 'GET',
  body: null | string = null,
  header: null | string = null
): Promise<T | undefined | string> {
  try {
    const authToken = await getAuthToken();
    const myHeaders = new Headers();
    authToken && myHeaders.append('Authorization', `Bearer ${authToken}`);
    myHeaders.append('Content-Type', 'application/json');
    if (header) {
      myHeaders.append('x-webauthn-challenge', header);
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}${url}`,
      {
        method,
        headers: myHeaders,
        redirect: 'follow',
        body,
        credentials: 'include',
      }
    );
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('text/csv')) {
      return await response.text();
    }
    if (response.status === 204) {
      return;
    }
    const json = await response.json();
    if (json) {
      json.status = response.status;
      return json;
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

async function callApiUpload<T>(
  url: string,
  method: string,
  token: string,
  body: FormData | null
): Promise<T | undefined> {
  try {
    const authToken = await getAuthToken();
    const myHeaders = new Headers();
    authToken && myHeaders.append('Authorization', `Bearer ${authToken}`);
    myHeaders.append('x-dstor-upload-token', token);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}${url}`,
      {
        method,
        headers: myHeaders,
        redirect: 'follow',
        body,
      }
    );

    const json = await response.json();
    json.status = response.status;
    return json;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function callApiUploadXhr(
  formData: FormData,
  xhr: XMLHttpRequest[],
  token: string,
  i: number,
  progressHandler: Function,
  successHandler: any,
  errorHandler: any,
  abortHandler: any
): Promise<void> {
  const authToken = await getAuthToken();
  xhr[i] = new XMLHttpRequest();
  xhr[i].upload.addEventListener(
    'progress',
    (e) => progressHandler(e, i),
    false
  );
  xhr[i].addEventListener('load', successHandler, false);
  xhr[i].addEventListener('error', errorHandler, false);
  xhr[i].addEventListener('abort', abortHandler, false);
  xhr[i].open('POST', process.env.REACT_APP_API_BASE_URL + 'upload/', true);
  xhr[i].setRequestHeader('Authorization', `Bearer ${authToken}`);
  xhr[i].setRequestHeader('x-dstor-upload-token', token);
  xhr[i].send(formData);
}

export { callApi, callApiUpload, callApiUploadXhr };
