import { ReactElement, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

export interface PropsShape {
  value: string;
  type?: 'TEXT' | 'BUTTON';
  text?: string;
  overflow?: boolean;
}

const Copy = ({
  value,
  type = 'BUTTON',
  text,
  overflow = false,
}: PropsShape): ReactElement => {
  const copyNotification = useRef(null);
  const [isCopyNotificationVisible, setIsCopyNotificationVisible] =
    useState<boolean>(false);

  const doCopy = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value);
    }
    setIsCopyNotificationVisible(true);
    setTimeout(function () {
      setIsCopyNotificationVisible(false);
    }, 400);
  };
  return (
    <div className='Copy' onClick={doCopy}>
      {type === 'BUTTON' ? (
        <div
          className='Copy--button'
          style={
            overflow ? { overflow: 'hidden', textOverflow: 'ellipsis' } : {}
          }
        >
          <span>{text}</span>
        </div>
      ) : (
        <div className='Copy--text'>{text ? text : value}</div>
      )}
      <CSSTransition
        nodeRef={copyNotification}
        in={isCopyNotificationVisible}
        timeout={3000}
        classNames='Copy--NotificationTransition'
        unmountOnExit
      >
        <div className='Copy--notification' ref={copyNotification}>
          <div className='Copy--notification-title'>Copied to clipboard!</div>
        </div>
      </CSSTransition>
    </div>
  );
};
export { Copy };
