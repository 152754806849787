import { ReactElement } from 'react';
import { Header } from '../../components/Header';
import { Title } from '../../components/Title';
import { Footer } from '../../components/Footer';
import { Background } from '../../components/Background';

function Careers(): ReactElement {
  return (
    <div className='Pricing'>
      <Header />
      <Background color='#F8FAFC'>
        <>
          <Title
            title='Join dStor'
            subTitle='Help us to build the file storage layer for modern applications'
          />
        </>
      </Background>

      <Background color='#FFFFFF'>
        <Footer />
      </Background>
      <div className='Pricing--center'></div>
    </div>
  );
}

export default Careers;
