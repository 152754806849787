import { ReactElement, useState } from 'react';
import { Header } from '../components/Header';
import { Button } from '../components/Button';

function Home(): ReactElement {
  const [dstorSpeed, setDstorSpeed] = useState<number>();
  const [googleSpeed, setGoogleSpeed] = useState<number>();
  const [gcloudSpeed, setGcloudSpeed] = useState<number>();
  const [amazonSpeed, setAmazonSpeed] = useState<number>();
  const [acloudSpeed, setAcloudSpeed] = useState<number>();
  const [pinataSpeed, setPinataSpeed] = useState<number>();
  const [web3stSpeed, setWeb3stSpeed] = useState<number>();
  const [azureSpeed, setAzureSpeed] = useState<number>();
  const [storjSpeed, setStorjSpeed] = useState<number>();
  const [loading, setLoading] = useState<boolean>();

  const startTest = async (testUrl: string): Promise<number> => {
    const start = performance.now();
    await fetch(testUrl, { mode: 'no-cors' });
    const duration = performance.now() - start;
    return duration;
  };

  const sequenceTests = async (): Promise<void> => {
    setLoading(true);
    setDstorSpeed(
      await startTest(
        'https://api.dstor.cloud/ipfs/QmVBfVjUrR5WAE5TXCL8yMMNwJmzh9CqW61qTqWtCbmdE9/'
      )
    );
    setGoogleSpeed(
      await startTest(
        'https://drive.google.com/u/0/uc?id=1CnksOOkrletBl9ew6Xxtq6S-gMqkq-o3&export=download'
      )
    );
    setGcloudSpeed(
      await startTest(
        'https://dstor-testfiles-upload.s3.us-east-2.amazonaws.com/20210928_163905.jpg'
      )
    );
    setAmazonSpeed(
      await startTest(
        'https://dstor-testfiles-upload.s3.us-east-2.amazonaws.com/20210928_163905.jpg'
      )
    );
    setAcloudSpeed(
      await startTest(
        'https://d23074offgc3il.cloudfront.net/20210928_163905.jpg'
      )
    );
    setAzureSpeed(
      await startTest(
        'https://dstortest.blob.core.windows.net/newcontainer/20210928_163905.jpg'
      )
    );
    setStorjSpeed(
      await startTest(
        'https://link.storjshare.io/jvwdqnikxcv6lryef7tsh55l56lq/demo-bucket%2F20210928_163905.jpg'
      )
    );
    setPinataSpeed(
      await startTest(
        'https://gateway.pinata.cloud/ipfs/QmVBfVjUrR5WAE5TXCL8yMMNwJmzh9CqW61qTqWtCbmdE9'
      )
    );
    setWeb3stSpeed(
      await startTest(
        'https://bafybeial66lnf4nzyldwqvo5ctzzqarjpveunrdfhp46lah3kz5b5nkuoi.ipfs.w3s.link/20210928_163905.jpg'
      )
    );
    setLoading(false);
  };
  return (
    <div className='Home'>
      <Header />
      <div className='Home--center'>
        <div className='Home--speed'>
          <Button click={sequenceTests} name='Test Speed' loading={loading} />
          {dstorSpeed && <div>dStor Speed: {dstorSpeed.toFixed(2)}</div>}
          {googleSpeed && <div>G Drive Speed: {googleSpeed.toFixed(2)}</div>}
          {gcloudSpeed && <div>G Cloud Speed: {gcloudSpeed.toFixed(2)}</div>}
          {amazonSpeed && <div>Amazon Speed: {amazonSpeed.toFixed(2)}</div>}
          {acloudSpeed && <div>AmznCF Speed: {acloudSpeed.toFixed(2)}</div>}
          {azureSpeed && <div>MS Azure Speed: {azureSpeed.toFixed(2)}</div>}
          {storjSpeed && <div>Storj Speed: {storjSpeed.toFixed(2)}</div>}
          {pinataSpeed && <div>Pinata Speed: {pinataSpeed.toFixed(2)}</div>}
          {web3stSpeed && <div>FileCoin Speed: {web3stSpeed.toFixed(2)}</div>}
        </div>
      </div>
    </div>
  );
}

export default Home;
