import { ReactElement, useEffect } from 'react';
import { Header } from '../components/Header';
import { FileBrowser } from '../components/FileBrowser';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';

function Files(): ReactElement {
  const navigate = useNavigate();
  const { userState } = useGlobalUserState();

  useEffect(() => {
    !userState.data?.loggedIn && navigate('/');
  }, []);
  return (
    <div className='Files'>
      <Header />
      <div className='Files--center'>
        <FileBrowser />
      </div>
    </div>
  );
}

export default Files;
