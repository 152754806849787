import { ReactElement } from 'react';

export interface PropsShape {
  children: ReactElement | ReactElement[];
  title?: boolean;
  bottomArrow?: boolean;
  description?: string;
}

const SideDataGrid = ({
  children,
  title = false,
  bottomArrow = false,
  description,
}: PropsShape): ReactElement => {
  return (
    <div
      className={`SideDataGrid ${title && 'SideDataGrid--title'} ${
        bottomArrow && 'SideDataGrid--bottomArrow'
      }`}
    >
      {description && (
        <div className='SideDataGrid--description'>{description}</div>
      )}
      {children}
    </div>
  );
};
export { SideDataGrid };
