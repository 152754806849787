import {
  createContext,
  useContext,
  useState,
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { userStateShape } from '../interfaces/user';
import { useCookies } from 'react-cookie';

interface PropsShape {
  children: ReactElement;
}

interface StateShape {
  userState: userStateShape;
  setUserState: Dispatch<SetStateAction<userStateShape>>;
}

const initVal: StateShape = {
  userState: JSON.parse(`${localStorage.getItem('userState')}`) || {},
  setUserState: () => {},
};

// const initialState: userStateShape = {
//   currentOrganization: null,
//   isSwitchingAccount: false,
//   switchingError: null,
//   data: undefined,
// };

// const userReducer = (
//   state: userStateShape,
//   action: any
// ): userStateShape => {
//   switch (action.type) {
//     case 'SET_USER_DATA':
//       return {
//         ...state,
//         data: action.payload,
//       };
//     case 'SET_CURRENT_ACCOUNT':
//       return {
//         ...state,
//         currentOrganization: action.payload,
//       };
//     case 'SET_LOADING':
//       return {
//         ...state,
//         isSwitchingAccount: action.payload,
//       };
//     case 'SET_ERROR':
//       return {
//         ...state,
//         switchingError: action.payload,
//       };
//     default:
//       return state;
//   }
// };

const GlobalContext = createContext<StateShape>(initVal);

const useGlobalUserState = (): StateShape => {
  return useContext(GlobalContext);
};

const GlobalUserState = ({ children }: PropsShape): ReactElement => {
  const [userState, setUserState] = useState<userStateShape>(initVal.userState);
  const [cookie] = useCookies(['webAuthn']);

  useEffect(() => {
    localStorage.setItem('userState', JSON.stringify(userState));
  }, [userState]);

  useEffect(() => {
    const userStateString = localStorage.getItem('userState');
    if (userStateString && cookie.webAuthn)
      userStateString && setUserState(JSON.parse(userStateString));
    else if (userStateString) {
      const userStateToTransform = JSON.parse(userStateString);
      const propertiesToDelete = ['encryption', 'webauthn_pub_keys'];
      propertiesToDelete.forEach((prop) => {
        if (
          userStateToTransform.data &&
          Object.prototype.hasOwnProperty.call(userStateToTransform.data, prop)
        )
          delete userStateToTransform.data[prop];
      });
      setUserState(userStateToTransform);
    }
  }, []);

  const value = {
    userState,
    setUserState,
  };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export { GlobalUserState, useGlobalUserState };
