import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../components/Header';
import { Title } from '../components/Title';
import { Footer } from '../components/Footer';
import { Background } from '../components/Background';
import { TextGrid } from '../components/TextGrid';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';
import { Slider } from '../components/Slider';
import geometricHeader from '../images/geometric-header.png';

function Pricing(): ReactElement {
  const navigate = useNavigate();
  const { userState } = useGlobalUserState();

  const [regions, setRegions] = useState<number>(3);
  const [upload, setUpload] = useState<number>(400);
  const [download, setDownload] = useState<number>(900);
  const [stored, setStored] = useState<number>(600);

  const [dStorCost, setDstorCost] = useState<number>(0);
  const [awsCost, setAwsCost] = useState<number>(0);

  const toFixedTwo = (num: number): number => {
    return +(Math.round(num * 100) / 100).toFixed(2);
  };
  useEffect(() => {
    const dStorCosts = (): number => {
      const dStorStorageCost: number = 0.015;
      const dStorUploadCost: number = 0.015;
      const dStorDownloadCost: number = 0.05;
      const num =
        stored * dStorStorageCost +
        upload * dStorUploadCost +
        download * dStorDownloadCost;
      return toFixedTwo(num);
    };
    const calcAwsStorageCost = (
      i: Array<Array<{ price: number; limit: number }>>
    ): number[] => {
      const res: number[] = [];
      for (let x = 0; x < 9; x++) {
        res.push(
          toFixedTwo(
            (stored > i[x][0].limit
              ? i[x][0].limit * i[x][0].price
              : stored * i[x][0].price) +
              (stored > i[x][0].limit
                ? stored > i[x][1].limit
                  ? i[x][1].limit - i[x][0].limit * i[x][1].price
                  : stored - i[x][0].limit * i[x][1].price
                : 0) +
              (stored > i[x][1].limit
                ? stored - i[x][1].limit * i[x][2].price
                : 0)
          )
        );
      }
      return res;
    };

    const calcAwsDownloadCost = (
      i: Array<Array<{ price: number; limit: number }>>
    ): number[] => {
      const res: number[] = [];

      for (let x = 0; x < 9; x++) {
        res.push(
          toFixedTwo(
            (download > i[x][0].limit
              ? i[x][0].limit * i[x][0].price
              : download * i[x][0].price) +
              (download > i[x][0].limit
                ? download > i[x][1].limit
                  ? i[x][1].limit - i[x][0].limit * i[x][1].price
                  : (download - i[x][0].limit) * i[x][1].price
                : 0) +
              (download > i[x][1].limit
                ? download > i[x][2].limit
                  ? i[x][2].limit - i[x][1].limit * i[x][2].price
                  : (download - i[x][1].limit) * i[x][2].price
                : 0) +
              (download > i[x][2].limit
                ? download > i[x][3].limit
                  ? i[x][3].limit - i[x][2].limit * i[x][3].price
                  : (download - i[x][2].limit) * i[x][3].price
                : 0) +
              (download > i[x][3].limit
                ? download - i[x][3].limit * i[x][4].price
                : 0)
          )
        );
      }
      return res;
    };
    const awsCosts = (): number => {
      const AwsStorageCost: number[] = calcAwsStorageCost([
        [
          { price: 0.023, limit: 51200 },
          { price: 0.022, limit: 512000 },
          { price: 0.021, limit: 512000 },
        ],
        [
          { price: 0.026, limit: 51200 },
          { price: 0.025, limit: 512000 },
          { price: 0.024, limit: 512000 },
        ],
        [
          { price: 0.024, limit: 51200 },
          { price: 0.023, limit: 512000 },
          { price: 0.022, limit: 512000 },
        ],
        [
          { price: 0.025, limit: 51200 },
          { price: 0.024, limit: 512000 },
          { price: 0.023, limit: 512000 },
        ],
        [
          { price: 0.0405, limit: 51200 },
          { price: 0.039, limit: 512000 },
          { price: 0.037, limit: 512000 },
        ],
        [
          { price: 0.025, limit: 51200 },
          { price: 0.024, limit: 512000 },
          { price: 0.023, limit: 512000 },
        ],
        [
          { price: 0.0405, limit: 51200 },
          { price: 0.039, limit: 512000 },
          { price: 0.038, limit: 512000 },
        ],
        [
          { price: 0.025, limit: 51200 },
          { price: 0.024, limit: 512000 },
          { price: 0.023, limit: 512000 },
        ],
        [
          { price: 0.025, limit: 51200 },
          { price: 0.024, limit: 512000 },
          { price: 0.023, limit: 512000 },
        ],
      ]);
      const AwsDownloadCost: number[] = calcAwsDownloadCost([
        [
          { price: 0, limit: 102.4 },
          { price: 0.09, limit: 10240 },
          { price: 0.085, limit: 51200 },
          { price: 0.07, limit: 153600 },
          { price: 0.05, limit: 153600 },
        ],
        [
          { price: 0, limit: 0 },
          { price: 0.09, limit: 10240 },
          { price: 0.085, limit: 51200 },
          { price: 0.07, limit: 153600 },
          { price: 0.05, limit: 153600 },
        ],
        [
          { price: 0, limit: 0 },
          { price: 0.09, limit: 10240 },
          { price: 0.085, limit: 512000 },
          { price: 0.07, limit: 153600 },
          { price: 0.05, limit: 153600 },
        ],
        [
          { price: 0, limit: 0 },
          { price: 0.114, limit: 10240 },
          { price: 0.089, limit: 51200 },
          { price: 0.086, limit: 153600 },
          { price: 0.084, limit: 153600 },
        ],
        [
          { price: 0, limit: 0 },
          { price: 0.15, limit: 10240 },
          { price: 0.138, limit: 51200 },
          { price: 0.126, limit: 153600 },
          { price: 0.114, limit: 153600 },
        ],
        [
          { price: 0, limit: 0 },
          { price: 0.11, limit: 10240 },
          { price: 0.085, limit: 51200 },
          { price: 0.077, limit: 153600 },
          { price: 0.055, limit: 153600 },
        ],
        [
          { price: 0, limit: 0 },
          { price: 0.154, limit: 10240 },
          { price: 0.147, limit: 51200 },
          { price: 0.126, limit: 153600 },
          { price: 0.112, limit: 153600 },
        ],
        [
          { price: 0, limit: 0 },
          { price: 0.114, limit: 10240 },
          { price: 0.096, limit: 51200 },
          { price: 0.094, limit: 153600 },
          { price: 0.092, limit: 153600 },
        ],
        [
          { price: 0, limit: 0 },
          { price: 0.1093, limit: 10240 },
          { price: 0.085, limit: 51200 },
          { price: 0.082, limit: 153600 },
          { price: 0.08, limit: 153600 },
        ],
      ]);

      const awsTransferCost = [0];
      for (let x = 0; x < 8; x++) {
        awsTransferCost.push(upload * 0.02);
      }

      const sum = AwsStorageCost.map(function (num, idx) {
        return toFixedTwo(num + AwsDownloadCost[idx] + awsTransferCost[idx]);
      });
      const awsCost: number[] = [];
      for (let x = 0; x < 9; x++) {
        if (x === 0) {
          awsCost.push(sum[x]);
        } else {
          awsCost.push(sum[x] + awsCost[x - 1]);
        }
      }

      return toFixedTwo(awsCost[regions - 1]);
    };
    setDstorCost(dStorCosts());
    setAwsCost(awsCosts());
  }, [regions, upload, download, stored]);

  useEffect(() => {
    userState.data?.loggedIn && navigate('/files');
  }, [userState.data?.loggedIn]);

  useEffect(() => {
    window.location.href = '#top';
  }, []);

  return (
    <div className='Pricing' id='top'>
      <Header />
      <Background color='#30385E' image={geometricHeader}>
        <>
          <Title
            title='Everybody gets our lowest price'
            subTitle='dStor platform pricing'
            mode='dark'
          >
            <div className='Pricing--spacer'>
              <div className='Pricing--title-grid'>
                <div className='Pricing--title-root'>
                  <div className='Pricing--title-tablet'>
                    Ingest
                    <ul>
                      <li>Fast Uploads</li>
                      <li>File verification</li>
                      <li>Automatic file sharding</li>
                      <li>Unique file hashes</li>
                    </ul>
                    <div className='Pricing--title-price'>$0.015</div>
                    <div className='Pricing--title-per'>Per GiB per month</div>
                  </div>
                </div>
                <div className='Pricing--title-root'>
                  <div className='Pricing--title-tablet'>
                    Store
                    <ul>
                      <li>Forward replication</li>
                      <li>Global redundancy</li>
                      <li>Single price all-region</li>
                      <li>Mitigates DDoS</li>
                    </ul>
                    <div className='Pricing--title-price'>$0.015</div>
                    <div className='Pricing--title-per'>Per GiB per month</div>
                  </div>
                </div>
                <div className='Pricing--title-root'>
                  <div className='Pricing--title-tablet'>
                    Serve
                    <ul>
                      <li>Fast downloads</li>
                      <li>Access Anywhere</li>
                      <li>Stream with HLS</li>
                      <li>No fees for HTTP </li>
                    </ul>
                    <div className='Pricing--title-price'>$0.050</div>
                    <div className='Pricing--title-per'>Per GiB per month</div>
                  </div>
                </div>
              </div>
            </div>
          </Title>
        </>
      </Background>
      <Background color='#FFFFFF'>
        <>
          <div className='Pricing--overflow' />
          <Title
            title='Clear Invoices, Easy Payments'
            subTitle='Simple pricing, Stripe invoicing, file access control'
          />
          <TextGrid
            text={[
              {
                title: 'No pricing tiers',
                text: 'Startups and small businesses deserve price breaks just as much as industry giants so dStor has only has one pricing tier: the lowest possible. Our system optimizes performance and extends those savings to all of our customers. Paying for PUT and GET calls adds more to your costs, so at dStor, we just eliminate these charges.',
              },
              {
                title: 'Always all-region',
                text: 'The Internet is global, but cloud storage companies organize themselves around the regions where their specific data centers are built. dStor leverages underutilized data centers around the world to provide global coverage—at no additional cost. You don’t have to decide how many regions you can afford to pay for, with dStor, your are close to all of your users.',
              },
              {
                title: 'All features for base price',
                text: 'Other services like to add on a la carte pricing for each additional system tool you use. dStor keeps it simple: every special feature we create is available to every dStor customer: geofencing, IP white- and blacklists, NFT folder preparation, data migration tools and whatever we add next. You need it, you got it.',
              },
              {
                title: 'Blockchain stays behind the scenes',
                text: 'Blockchain tech is amazing but who wants to our users to have to think about any of it. dStor users never have to using special crypto tokens to pay (but you can if you really want to). We do use the Telos blockchain to automate instant, fair, fee-less payments to our storage node operators with effectively no cost of payment. This is an efficiency dStor passes on to our customers. ',
              },
            ]}
          />
        </>
      </Background>
      <Background color='#30385E'>
        <>
          <Title
            title='Cost and Savings Calculator'
            subTitle='Estimate your monthly costs and savings'
            mode='dark'
          />
          <div className='Pricing--center'>
            <div>
              <Slider
                name='Regions'
                setValue={setRegions}
                value={regions}
                minValue={1}
                maxValue={9}
              />
              <Slider
                name='Uploaded'
                setValue={setUpload}
                value={upload}
                minValue={1}
                maxValue={1024}
                unitOfMeasure='GiB'
                infoWidth={100}
              />
              <Slider
                name='Stored'
                setValue={setStored}
                value={stored}
                minValue={1}
                maxValue={1024}
                unitOfMeasure='GiB'
                infoWidth={100}
              />
              <Slider
                name='Downloaded'
                setValue={setDownload}
                value={download}
                minValue={1}
                maxValue={1024}
                unitOfMeasure='GiB'
                infoWidth={100}
              />
            </div>
          </div>
          <div className='Pricing--grid'>
            <div>
              <div>dStor Cost</div>
              <div className='Pricing--grid-value'>${dStorCost}</div>
            </div>
            <div>
              <div>AWS Cost</div>
              <div className='Pricing--grid-value'>${awsCost}</div>
            </div>
            <div>
              <div>dStor Savings</div>
              <div className='Pricing--grid-value'>
                ${toFixedTwo(awsCost - dStorCost)}
              </div>
            </div>
            <div>
              <div>dStor Savings %</div>
              <div className='Pricing--grid-value'>
                {toFixedTwo(((awsCost - dStorCost) / awsCost) * 100)} %
              </div>
            </div>
          </div>
          <div className='Pricing--description'>
            Calculations based on published AWS figures for S3 Standard storage.
            (https://calculator.aws/#/addService/S3) Regions 1-9 are,
            respectively: Virginia, California, London, Tokyo, Sao Paulo, UAE,
            Cape Town, Sydney, Mumbai.{' '}
          </div>
        </>
      </Background>
      <Background color='#FFFFFF'>
        <Footer />
      </Background>
    </div>
  );
}

export default Pricing;
