import { ReactElement, useState } from 'react';
import SideMenu from '../components/SideMenu';
import { DropDown } from '../components/DropDown';
import { TextImageMicro } from './TextImageMicro';
import navAbout from '../images/nav-about.svg';
// import navBlog from '../images/nav-blog.svg';
// import navCareers from '../images/nav-careers.svg';
// import navEvents from '../images/nav-events.svg';
// import navStories from '../images/nav-stories.svg';
// import navEnterprise from '../images/nav-enterprise.svg';
// import navDocumentation from '../images/nav-documentation.svg';
// import navIntegrating from '../images/nav-integrating.svg';
// import navCommunity from '../images/nav-community.svg';
import navNode from '../images/nav-node.svg';
import navGuide from '../images/nav-guide.svg';
import navWhat from '../images/nav-what.svg';
// import navWhy from '../images/nav-why.svg';
// import navStack from '../images/nav-stack.svg';

const TopNav = (): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <ul className='TopNav--desktop'>
        <DropDown title='Product' style='text'>
          <div className='TopNav--dropdown'>
            <TextImageMicro
              title='User Guide'
              text='Discover how the user interface works in depth'
              image={navGuide}
              link='https://goodblock.gitbook.io/dstor/'
            />
            <TextImageMicro
              title='What is dStor'
              text='Embrace distributed storage'
              image={navWhat}
              link='/product/what-is-dstor'
            />
            {/* <TextImageMicro
              title='Why dStor'
              text='dStor is a new class of storage'
              image={navWhy}
              link='/product/why-dstor'
            /> */}
            {/* <TextImageMicro
              title='dStor in your stack'
              text='Incorporate dStor to the extent that suits you'
              image={navStack}
            /> */}
          </div>
        </DropDown>
        <a href='/pricing' className='TopNav--pricing'>
          Pricing
        </a>

        <DropDown title='Developers' style='text'>
          <div className='TopNav--dropdown'>
            {/* <TextImageMicro
              title='Documentation'
              text='Refer to our technical docs to integrate dStor'
              image={navDocumentation}
              link='/developers/documentation'
            /> */}
            {/* <TextImageMicro
              title='Start Integrating'
              text='Setup dStor for your project'
              image={navIntegrating}
            />
            <TextImageMicro
              title='Community'
              text='Join the growing dStor community'
              image={navCommunity}
            /> */}
            <TextImageMicro
              title='Operate a storage node'
              text='Register your interest'
              image={navNode}
              link='/developers/storage-node'
            />
          </div>
        </DropDown>
        {/* <DropDown title='Use Cases' style='text'>
          <div className='TopNav--dropdown'>
            <TextImageMicro
              title='Customer Stories'
              text='Learn about applications build with dStor'
              image={navStories}
            />
            <TextImageMicro
              title='Enterprise'
              text='Level up your business applications with dStor'
              image={navEnterprise}
            />
          </div>
        </DropDown> */}
        <DropDown title='Company' style='text'>
          <div className='TopNav--dropdown'>
            <TextImageMicro
              title='About'
              text='Learn about the team behind dStor'
              image={navAbout}
              link='/company/about'
            />
            {/* <TextImageMicro
              title='Blog'
              text='Guides, announcements and articles about dStor'
              image={navBlog}
            /> */}
            {/* <TextImageMicro
              title='Careers'
              text='Help us to empower developers'
              image={navCareers}
              link='/company/careers'
            /> */}
            {/* <TextImageMicro
              title='Events'
              text='Find out when our next dStor event is '
              image={navEvents}
            /> */}
          </div>
        </DropDown>
      </ul>
      <div className='TopNav--mobile' onClick={(): void => setIsOpen(true)} />

      <SideMenu isOpen={isOpen} setIsOpen={setIsOpen} position='LEFT'>
        <>
          <div
            onClick={(): void => setIsOpen(false)}
            className='TopNav--close'
          />

          <div className='TopNav--title'>Product</div>
          <ul className='TopNav--list'>
            <a href='https://goodblock.gitbook.io/dstor/'>
              <li>User guide</li>
            </a>
            <a href='/product/what-is-dstor'>
              <li>What is dStor</li>
            </a>
            {/* <a href='/product/why-dstor'>
              <li>Why dStor</li>
            </a> */}
            {/* <a href='#'>
              <li>dStor in your stack</li>
            </a> */}
            <a href='/pricing'>
              <li>Pricing</li>
            </a>
          </ul>
          <div className='TopNav--title'>Developers</div>
          <ul className='TopNav--list'>
            {/* <a href='/developers/documentation'>
              <li>Documentation</li>
            </a> */}
            {/* <a href='#'>
              <li>Start integrating</li>
            </a>
            <a href='#'>
              <li>Community</li>
            </a> */}
            <a href='/developers/storage-node'>
              <li>Operate a storage node</li>
            </a>
          </ul>
          {/* <div className='TopNav--title'>Use Cases</div>
          <ul className='TopNav--list'>
            <a href='#'>
              <li>Customer stories</li>
            </a>
            <a href='#'>
              <li>Enterprise</li>
            </a>
          </ul> */}
          <div className='TopNav--title'>Company</div>
          <ul className='TopNav--list'>
            <a href='/company/about'>
              <li>About</li>
            </a>
            {/* <a href='#'>
              <li>Blog</li>
            </a> */}
            {/* <a href='/company/careers'>
              <li>Careers</li>
            </a> */}
            {/* <a href='#'>
              <li>Events</li>
            </a> */}
          </ul>
        </>
      </SideMenu>
    </>
  );
};
export { TopNav };
