import { ReactElement, useState, useEffect } from 'react';
import { FileShape } from '../interfaces/files';
import { DateTime } from './DateTime';
import { Copy } from './Copy';
import { useNavigate, useParams } from 'react-router-dom';
import SideMenu from './SideMenu';
import { Button } from './Button';
import { TextInput } from './TextInput';
import { callApi } from '../functions/callApi';
import { useGlobalUserState } from '../hooks/useGlobalUserState';

export interface PropsShape {
  data: FileShape;
}

const FolderView = ({ data }: PropsShape): ReactElement => {
  const { userState, setUserState } = useGlobalUserState();
  const params = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<string>('');
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [display, setDisplay] = useState<boolean>(false);

  useEffect(() => {
    setConfirmDelete('');
  }, [isOpen]);

  useEffect(() => {
    confirmDelete === 'delete' && setDeleteDisabled(false);
  }, [confirmDelete]);

  const doDelete = async (): Promise<void> => {
    setDeleteLoading(true);

    const url = `folder`;
    const body = {
      folder_hash: data.hash,
      folder_key: data.key,
      organization_key: userState.currentOrganization?.accountKey || null,
    };
    try {
      await callApi(url, 'DELETE', JSON.stringify(body));
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
      setDeleteLoading(false);
      setIsOpen(false);
    }
  };

  const canDisplay = (): void => {
    setDisplay(true);
  };
  return (
    <>
      <div
        className='FolderView'
        onClick={(): void => {
          let url = '';
          const urlParam = params['*'];
          if (urlParam) url += `${urlParam}/`;
          url += `${data.key}`;
          navigate(url);
        }}
        onContextMenu={(e: any): void => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <div className='FolderView--top'>
          <div
            className={
              data.hash ? 'FolderView--top-folder' : 'FolderView--top-directory'
            }
          ></div>
          <div className='FolderView--top-name'>
            {data.name}{' '}
            <div className='FolderView--top-date'>
              <DateTime showTime value={new Date(data.created)} />
            </div>
          </div>
        </div>
        <div className='FolderView--bottom'>
          {data.hash && (
            <Copy
              value={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
              type='TEXT'
              text={data.hash}
            />
          )}
          {!data.hash && (
            <div className='FolderView--desc'>{data.description}</div>
          )}
        </div>
      </div>
      <SideMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        position='RIGHT'
        width='REGULAR'
      >
        <div className='FileViewRow--grid'>
          <div>
            <div className='FileViewRow--section'>
              <img
                src={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
                id='preview'
                onLoad={(): void => canDisplay()}
                className={display ? 'FileViewRow--show' : 'FileViewRow--hide'}
              ></img>
              <div className='FileViewRow--info'>
                <div className='FileViewRow--info-left'>Name</div>
                <div className='FileViewRow--info-right'>{data.name}</div>
                <div className='FileViewRow--info-left'>Created</div>
                <div className='FileViewRow--info-right'>
                  <DateTime showTime value={new Date(data.created)} />
                </div>
                {data.hash && (
                  <>
                    <div className='FileViewRow--info-left'>Hash</div>
                    <div className='FileViewRow--info-right'>{data.hash}</div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div />

          <div>
            <hr className='FileViewRow--divider' />
            <div className='FileViewRow--section'>
              <TextInput
                name='Confirm delete'
                value={confirmDelete}
                setValue={setConfirmDelete}
                placeHolder="Enter 'delete' to confirm"
                label
              />
              <Button
                name={`Delete ${data.hash ? 'Folder' : 'Directory'}`}
                color='RED'
                disabled={deleteDisabled}
                click={doDelete}
                loading={deleteLoading}
              />
            </div>
          </div>
        </div>
      </SideMenu>
    </>
  );
};
export { FolderView };
