import { ReactElement } from 'react';

export interface PropsShape {
  topColor: string;
  bottomColor: string;
  tickArray: string[];
}

const BenefitBox = ({
  topColor,
  bottomColor,
  tickArray,
}: PropsShape): ReactElement => {
  return (
    <div
      className='BenefitBox'
      style={{
        background: `linear-gradient(180deg, ${topColor} 0%, ${topColor} 50%, ${bottomColor} 50%, ${bottomColor} 100%)`,
      }}
    >
      <div className='BenefitBox--center'>
        {tickArray?.map((item: any, index: number) => {
          return (
            <div key={index} className='BenefitBox--item'>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export { BenefitBox };
