import chevronDown from '../images/chevron-down.svg';
import React, { ReactElement, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { billingDataShape, filesUsageShape } from '../interfaces/user';

interface InvoicesDropdownProps {
  usageBillingData: billingDataShape[];
  filesUsageData: filesUsageShape[];
}

export const InvoicesDropdown: React.FC<InvoicesDropdownProps> = ({
  usageBillingData,
  filesUsageData,
}): ReactElement => {
  const menuTransition = useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { userState, setUserState } = useGlobalUserState();

  const setDashboard = (index: number): void => {
    const getSortedData = (
      data: billingDataShape[] | filesUsageShape[]
    ): [any, ...(billingDataShape[] | filesUsageShape[])] => {
      const temp = data[0];
      data[0] = data[index];
      data[index] = temp;
      return [
        data[0],
        ...data.slice(1).sort((a, b) => b.period_end - a.period_end),
      ];
    };

    if (usageBillingData && filesUsageData) {
      const sortedBillingData = getSortedData(usageBillingData);
      const sortedFilesUsageData = getSortedData(filesUsageData);
      if (userState.data?.isActive) {
        setUserState((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            usage: {
              ...prevState.data.usage,
              billing_data: sortedBillingData,
            },
            fileUsage: sortedFilesUsageData,
          },
        }));
      } else {
        setUserState((prevState: any) => ({
          ...prevState,
          currentOrganization: {
            ...prevState.currentOrganization,
            usage: {
              ...prevState.currentOrganization.usage,
              billing_data: sortedBillingData,
            },
            fileUsage: sortedFilesUsageData,
          },
        }));
      }
    }
  };

  return (
    <div className='InvoicesDropdown' onClick={(): void => setIsOpen(!isOpen)}>
      <div className='InvoicesDropdown--title'>
        {usageBillingData?.[0]?.current_billing_period
          ? 'Current Billing Period'
          : usageBillingData?.[0]?.period_name}
      </div>
      <img
        src={chevronDown}
        alt='chevron'
        style={{
          marginLeft: 8,
          transform: isOpen ? 'rotate(180deg)' : 'none',
        }}
        width={10}
        height={24}
      ></img>
      {isOpen && (
        <div
          className='Pulse--overlay'
          onClick={(): void => setIsOpen(!isOpen)}
        ></div>
      )}
      <CSSTransition
        nodeRef={menuTransition}
        in={isOpen}
        timeout={300}
        classNames={'InvoicesDropdown'}
        unmountOnExit
      >
        <div className='InvoicesDropdown--menu'>
          <div className='InvoicesDropdown--menu-title'>
            Select usage period to explore
          </div>
          <div className='InvoicesDropdown--menu-list'>
            {usageBillingData?.length &&
              usageBillingData.slice(1).map((billingPeriod, index) => (
                <div
                  key={index + 1}
                  className='InvoicesDropdown--menu-list-item'
                  onClick={(): void => setDashboard(index + 1)}
                >
                  {billingPeriod.period_name || ''}
                </div>
              ))}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
