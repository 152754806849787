import { Dispatch, ReactElement, SetStateAction } from 'react';
import SideMenu from './SideMenu';
import { Heading } from './Heading';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { AddMemberSection } from './AddMemberSection';

export interface PropsShape {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getActiveMember: () => void;
  getPendingInvites: () => void;
}

export interface OrganizationShape {
  name: string;
}

const AddMemberModal = ({
  isOpen,
  setIsOpen,
  getActiveMember,
  getPendingInvites,
}: PropsShape): ReactElement => {
  const { userState } = useGlobalUserState();

  return (
    <SideMenu
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position='CENTER'
      width='REGULAR'
    >
      <div className='Header--newfolder'>
        <Heading
          title='Add Member'
          subtitle='Find and add people to your organization.'
        />
        <AddMemberSection
          organizationKey={userState.currentOrganization?.accountKey || ''}
          setIsOpen={setIsOpen}
          getActiveMember={getActiveMember}
          getPendingInvites={getPendingInvites}
        />
      </div>
    </SideMenu>
  );
};

export { AddMemberModal };
