import { ReactElement } from 'react';

export interface PropsShape {
  showTime?: boolean;
  value: Date | undefined;
}

const DateTime = ({ value, showTime = false }: PropsShape): ReactElement => {
  const locale = (): string => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.language || 'en-gb';
    }
  };

  const addZeroBefore = (n: number): string => {
    return (n < 10 ? '0' : '') + n;
  };

  const month = value?.toLocaleDateString(locale(), {
    month: 'short',
  });
  const day = value?.toLocaleDateString(locale(), {
    day: 'numeric',
  });
  const year =
    `${new Date().getFullYear()}` ===
    value?.toLocaleDateString(locale(), { year: 'numeric' })
      ? ''
      : `, ${value?.toLocaleDateString(locale(), { year: 'numeric' })}`;

  let hours = value?.getHours()!;
  const amOrPm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;
  if (value)
    return (
      <>
        {`${month} ${day}${year}`}
        {showTime &&
          `, ${addZeroBefore(hours)}:${addZeroBefore(
            value?.getMinutes()
          )} ${amOrPm}`}
      </>
    );
  else return <></>;
};
export { DateTime };
