import { ReactElement } from 'react';

export interface PropsShape {
  name: string;
  click?: (click: string) => void;
  loading?: boolean;
  color?: 'BLUE' | 'GREEN' | 'RED' | 'GREY';
  size?: 'SMALL' | 'MEDIUM' | 'NORMAL';
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
  margin?: number;
  secondary?: boolean;
  icon?: string;
  iconPosition?: 'left' | 'right';
  noBorder?: boolean;
  dataTooltipId?: string;
}

const Button = ({
  name,
  click,
  loading,
  color = 'BLUE',
  size = 'NORMAL',
  type = 'button',
  disabled = false,
  margin = 12,
  secondary = false,
  icon,
  iconPosition = 'left',
  noBorder = false,
  dataTooltipId = '',
}: PropsShape): ReactElement => {
  const calStyle = (): string => {
    let className = `Button Button--${color.toLowerCase()} Button--${size.toLowerCase()}`;
    if (secondary) {
      className = `${className}  Button--secondary`;
    }
    if (noBorder) {
      className = `${className} Button--no-border`;
    }
    if (disabled) {
      return `${className} Button--disabled`;
    } else if (loading) {
      return `${className} Button--loading`;
    } else {
      return className;
    }
  };
  const doClick = (): void => {
    if (click && !disabled) {
      click(name);
    }
  };
  return (
    <button
      id={name}
      type={type}
      name={name}
      className={calStyle()}
      onClick={doClick}
      style={{ margin: `${margin}px 0` }}
      data-tooltip-id={dataTooltipId}
    >
      {icon && iconPosition === 'left' && (
        <img src={icon} className='icon-left' />
      )}
      {loading ? '' : <span className='button-text'>{name}</span>}
      {icon && iconPosition === 'right' && (
        <img src={icon} className='icon-right' />
      )}
    </button>
  );
};
export { Button };
