import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';
import { callApi } from '../../functions/callApi';
import { DateTime } from '../../components/DateTime';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { SettingsItem } from '../../components/SettingsItem';
import { Card } from '../../components/Card';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { Menu } from '../../components/Menu';
import { usersShape } from '../../interfaces/user';
import { SelectInput } from '../../components/SelectInput';
import { SideDataGrid } from '../../components/SideDataGrid';
import { SideDataGridItem } from '../../components/SideDataGridItem';
import { epochToDate } from '../../functions/formatters';
import { ServerError } from '../../components/ServerError';
import { useMainNav } from '../../hooks/useMainNav';

type ListType = 'organization' | 'invite';

function Organizations(): ReactElement {
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isRoleOpen, setIsRoleOpen] = useState<boolean>(false);
  const [isThresholdOpen, setIsThresholdOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('existing');
  const [selectedUser, setSelectedUser] = useState<any>();
  const [filterEmail, setFilterEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [newRole, setNewRole] = useState<string>('');
  const [maxThreshold, setMaxThreshold] = useState<string>('');
  const navList = useMainNav();

  useEffect(() => {
    newRole !== '' && setDisabled(false);
    maxThreshold !== '' && setDisabled(false);
  }, [newRole, maxThreshold]);

  const changeThreshold = async (): Promise<void> => {
    setIsThresholdOpen(true);
    setDisabled(true);
    setMaxThreshold('');
    setError('');
  };

  const doChangeThreshold = async (): Promise<void> => {
    setLoading(true);
    setIsThresholdOpen(true);
    setError('');
    await callApi<any>(
      `account/update-max-threshold`,
      'POST',
      JSON.stringify({
        account_id: selectedUser.account_key,
        maximum_threshold_usd: maxThreshold,
      })
    );
    setLoading(false);
    setIsThresholdOpen(false);
    setIsOpen(false);
    getList('organization', filterEmail ? filterEmail : '');
  };

  const doChangeUserRole = async (): Promise<void> => {
    setLoading(true);
    setIsRoleOpen(true);
    setError('');
    await callApi<any>(
      `account/update-role`,
      'POST',
      JSON.stringify({
        account_role_id: selectedUser.account_role.id,
        data: { type: newRole },
      })
    );
    setLoading(false);
    setIsRoleOpen(false);
    setIsOpen(false);
    getList('organization', filterEmail ? filterEmail : '');
  };

  const disableUser = async (): Promise<void> => {
    await callApi<any>(
      `account/update-properties`,
      'POST',
      JSON.stringify({
        account_key: selectedUser.account_key,
        changedSettings: { is_enabled: false },
      })
    );
    setIsOpen(false);
    getList('organization', filterEmail ? filterEmail : '');
  };
  // const acceptInvite = async (email: string, url: string): Promise<void> => {
  //   await callApi<any>(url, 'POST', JSON.stringify({ email }));
  //   setIsOpen(false);
  //   getList('developer', filterEmail ? filterEmail : '');
  // };

  const deleteInvite = async (email: string): Promise<void> => {
    await callApi<any>(
      `invite/dev/update-is-archived`,
      'POST',
      JSON.stringify({ email })
    );
    setIsOpen(false);
    getList('invite', filterEmail ? filterEmail : '');
  };

  const setListState = (type: ListType, list: Array<object>): void => {
    const globalParameter =
      type === 'organization' ? 'organizations' : 'orgInvites';
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        admin: {
          ...prevState.data.admin,
          [globalParameter]: list,
        },
      },
    }));
  };

  const getListUrl = (type: ListType): string =>
    type === 'organization'
      ? 'accounts/advanced-search'
      : 'invites/org-invites';

  const getList = async (
    type: ListType,
    search: string = ''
  ): Promise<void> => {
    const list = await callApi<any>(
      `${getListUrl(type)}?offset=0&limit=25`,
      'POST',
      JSON.stringify(
        type === 'organization'
          ? { searchParam: search, isOrganizations: true }
          : { searchParam: search, organization_key: null }
      )
    );
    setListState(type, list.results);
  };

  useEffect(() => {
    !userState.data?.loggedIn && navigate('/');
    getList('organization');
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      if (filter === 'existing')
        await getList('organization', filterEmail ? filterEmail : '');
      if (filter === 'orgInvites')
        await getList('invite', filterEmail ? filterEmail : '');
    })();
  }, [filter, filterEmail]);

  const selectUser = (e: any): void => {
    if (filter === 'existing') {
      const selected: usersShape = userState.data?.admin?.organizations[e];
      setSelectedUser(selected);
    }
    if (filter === 'orgInvites') {
      const pending: usersShape = userState.data?.admin?.orgInvites[e];
      setSelectedUser(pending);
    }
    setIsOpen(true);
  };

  return (
    <div className='Users'>
      <Header />
      <div className='Users--center'>
        <div className='Users--title'>Account</div>

        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='RIGHT'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title={
                selectedUser?.owner_name ||
                selectedUser?.info?.name ||
                selectedUser?.name
              }
              subtitle='You are editing this organizations settings.'
            />
            <div>
              <br></br>
              <br></br>
              {filter === 'existing' && (
                <>
                  <SideDataGrid description='Info'>
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Name: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedUser?.owner_name}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Email: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedUser?.owner_email}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Account Key: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedUser?.account_key}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Next billing Date: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: selectedUser?.is_archived
                              ? 'Archived account'
                              : `${epochToDate(
                                  selectedUser?.next_billing_date,
                                  true
                                )}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Max Threshold: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `$${selectedUser?.maximum_threshold_usd}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                  </SideDataGrid>

                  <Button
                    name='Change Threshold'
                    click={changeThreshold}
                    color='GREY'
                  />
                  <Button name='Disable' click={disableUser} />
                </>
              )}
              {filter === 'orgInvites' && (
                <>
                  <SideDataGrid description='Info'>
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Name: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedUser?.info?.name || ''}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Company: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedUser?.info?.company || ''}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'email: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedUser?.email || ''}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Invite Key: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedUser?.invite_key || ''}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                    <SideDataGridItem
                      data={{
                        display: [
                          {
                            value: 'Organization Key: ',
                            weight: 'normal',
                            align: 'right',
                            width: '1fr',
                          },
                          {
                            value: `${selectedUser?.organization_key || ''}`,
                            weight: 'bold',
                            align: 'left',
                            width: '1fr',
                          },
                        ],
                      }}
                    />
                  </SideDataGrid>
                  <Button
                    name='Delete Invite'
                    color='RED'
                    click={(): any => deleteInvite(selectedUser?.email)}
                  />
                </>
              )}
            </div>
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isThresholdOpen}
          setIsOpen={setIsThresholdOpen}
          position='CENTER'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Change Max Threshold'
              subtitle='you are about to change this users max threshold'
            />
            <ServerError error={error} />
            <TextInput
              name='Max threshold'
              value={maxThreshold}
              setValue={setMaxThreshold}
            />
            <Button
              name='Change'
              click={doChangeThreshold}
              loading={loading}
              disabled={disabled}
            />
          </div>
        </SideMenu>
        <SideMenu
          isOpen={isRoleOpen}
          setIsOpen={setIsRoleOpen}
          position='CENTER'
        >
          <div className='Header--newfolder'>
            <Heading
              title='Change Role'
              subtitle='you are about to change this users role, be careful!'
            />
            <ServerError error={error} />
            <SelectInput
              name='New role'
              value={newRole}
              setValue={setNewRole}
              options={[
                { value: 'NODE_HANDLER', name: 'Node Operator' },
                { value: 'CUSTOMER', name: 'Customer' },
                { value: 'ADMIN', name: 'Admin' },
                { value: 'SUPERADMIN', name: 'Super Admin' },
              ]}
            />
            <Button
              name='Change'
              click={doChangeUserRole}
              loading={loading}
              disabled={disabled}
            />
          </div>
        </SideMenu>

        <div className='Users--grid'>
          <div>
            <Menu items={navList} selected='Organizations' />
          </div>
          <div>
            <Card>
              <SettingsItem title='Filter' subtitle='Who do you want to see?'>
                <Card>
                  <SettingsItem
                    inner
                    border
                    title='Organizations or Invite Requests'
                    subtitle='Select organizations or pending invite requests'
                  >
                    <SelectInput
                      name='Sort by'
                      value={filter}
                      setValue={setFilter}
                      options={[
                        { value: 'existing', name: 'Organizations' },
                        { value: 'orgInvites', name: 'Invite Requests' },
                      ]}
                    />
                  </SettingsItem>
                  <SettingsItem
                    inner
                    title='Email'
                    subtitle='Search for an email'
                  >
                    <div style={{ width: '180px' }}>
                      <TextInput
                        name='Sort by'
                        value={filterEmail}
                        setValue={setFilterEmail}
                      />
                    </div>
                  </SettingsItem>
                </Card>
              </SettingsItem>
            </Card>
            <br />
            <Card>
              <SettingsItem
                title={`${
                  filter === 'existing' ? 'Organizations' : 'Pending Invite'
                }`}
                subtitle={`${
                  filter === 'existing'
                    ? 'Select an organization for more options'
                    : 'Select an invite request for more options'
                }`}
              >
                <Card>
                  {filter === 'existing' &&
                    userState?.data?.admin?.organizations?.map(
                      (key: any, index: any) => (
                        <div key={index}>
                          <SettingsItem
                            inner
                            border
                            title={
                              <div>
                                {key.owner_name} {'<'}
                                {key.owner_email}
                                {'>'}
                              </div>
                            }
                            subtitle={
                              <div>
                                Joined:{' '}
                                <DateTime
                                  showTime
                                  value={new Date(+key.created)}
                                />
                              </div>
                            }
                          >
                            <Button
                              name='Open'
                              margin={0}
                              click={(): void => selectUser(index)}
                            />
                          </SettingsItem>
                        </div>
                      )
                    )}
                  {filter === 'orgInvites' &&
                    userState?.data?.admin?.orgInvites?.map(
                      (key: any, index: any) => (
                        <div key={index}>
                          <SettingsItem
                            inner
                            border
                            title={
                              <div>
                                {key.info.name} {'<'}
                                {key.email}
                                {'>'}
                              </div>
                            }
                            subtitle={
                              <div>
                                Requested:{' '}
                                <DateTime
                                  showTime
                                  value={new Date(+key.date_requested)}
                                />
                              </div>
                            }
                          >
                            <Button
                              name='Open'
                              margin={0}
                              click={(): void => selectUser(index)}
                            />
                          </SettingsItem>
                        </div>
                      )
                    )}
                </Card>
              </SettingsItem>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organizations;
