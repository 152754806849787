import { ReactElement } from 'react';
import logoX from '../images/logoX.svg';
import logoGoogle from '../images/google.svg';

export interface PropsShape {
  name: string;
  click?: (click: string) => void;
  logo: 'X' | 'GOOGLE';
  color?: 'BLACK' | 'WHITE';
  size?: 'SMALL' | 'NORMAL';
  loading?: boolean;
  disabled?: boolean;
  margin?: number;
}

const IconButton = ({
  name,
  click,
  logo,
  color = 'WHITE',
  size = 'NORMAL',
  loading,
  disabled,
  margin = 12,
}: PropsShape): ReactElement => {
  const getLogo = (): string => {
    switch (logo) {
      case 'X':
        return logoX;
      case 'GOOGLE':
        return logoGoogle;
    }
  };

  const calTextStyle = (): string => {
    switch (logo) {
      case 'X':
        return loading
          ? 'IconButton--text--white Button--loading'
          : 'IconButton--text--white';
      case 'GOOGLE':
        return loading
          ? 'IconButton--text--black Button--loading-black'
          : 'IconButton--text--black';
    }
  };

  const calStyle = (): string => {
    if (disabled) {
      return `IconButton IconButton--${color.toLowerCase()} Button--${size.toLowerCase()} Button--disabled`;
    } else
      return `IconButton IconButton--${color.toLowerCase()} Button--${size.toLowerCase()}`;
  };

  const doClick = (): void => {
    if (click) {
      click(name);
    }
  };

  return (
    <div
      style={{ margin: `${margin}px 0` }}
      className={calStyle()}
      onClick={doClick}
    >
      <div>
        <img width='20px' height='20px' src={getLogo()} alt={`${logo} logo`} />
      </div>
      <div
        className={`IconButton--text ${calTextStyle()}`}
        style={{ minHeight: 39 }}
      >
        {loading ? '' : name}
      </div>
    </div>
  );
};

export default IconButton;
