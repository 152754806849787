import { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import { useGlobalUserState } from '../../hooks/useGlobalUserState';
import { useNavigate } from 'react-router-dom';
import { Menu } from '../../components/Menu';
import { callApi } from '../../functions/callApi';
import { readableBytes, centsToDollars } from '../../functions/formatters';
import { filesUsageShape, fileUsageShape } from '../../interfaces/user';
import SideMenu from '../../components/SideMenu';
import { Heading } from '../../components/Heading';
import { SideDataGrid } from '../../components/SideDataGrid';
import { SideDataGridItem } from '../../components/SideDataGridItem';
import { DateTime } from '../../components/DateTime';
import { InvoicesDropdown } from '../../components/InvoicesDropdown';
import { useBillingNav } from '../../hooks/useBillingNav';

function Usage(): ReactElement {
  const navigate = useNavigate();
  const { userState, setUserState } = useGlobalUserState();
  const [selectedFile, setSelectedFile] = useState<fileUsageShape>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [maxOffset, setMaxOffset] = useState<number>(0);
  const [usageData, setUsageData] = useState<any>();
  const [fileUsageData, setFileUsageData] = useState<filesUsageShape[]>([]);
  const billingNavItems = useBillingNav();

  const uploadDateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
  const formattedDate = (date: any, options: any): string | undefined => {
    if (date && new Date(date).getTime()) {
      return new Intl.DateTimeFormat('en-US', options).format(date);
    }
  };

  const downloadCsv = (csvData: string, fileName: string): void => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = async (
    periodStart: number | undefined,
    periodEnd: number | undefined
  ): Promise<void> => {
    const csvData = await callApi<any>(
      `billing/files-usage-csv`,
      'POST',
      JSON.stringify({
        period_start: periodStart,
        period_end: periodEnd,
      })
    );
    const fileName = 'file-usage.csv';
    downloadCsv(csvData, fileName);
  };

  const handleScroll = (e: any): void => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      getFileUsage(maxOffset + 20);
    }
  };

  const getFileUsage = async (offset: number): Promise<void> => {
    setMaxOffset(offset);
    const sendOffset = offset === 0 ? 0 : offset + 20;
    const fileUsage = await callApi<any>(
      `billing/files-usage?limit=20&offset=${sendOffset}`,
      'GET'
    );
    if (fileUsage.results.length > 0) {
      const fileUsageWithUploadDates = await getFileUploadDates(
        fileUsage.results
      );
      await setFileUsageState(fileUsageWithUploadDates, offset);
    }
  };

  const getFileUploadDates = async (
    filesUsagePeriods: Array<filesUsageShape>
  ): Promise<Array<filesUsageShape>> => {
    const filesKeys = filesUsagePeriods?.reduce(
      (accumulator: string[], period) => {
        const periodKeys = period.files.map((file) => file.file_key).slice(-20);
        return accumulator.concat(periodKeys);
      },
      []
    );
    const filesUploadedDates = await callApi<any>(
      'billing/files-upload-dates',
      'POST',
      JSON.stringify({
        files_keys: filesKeys,
      })
    );

    return filesUsagePeriods.map((period: filesUsageShape) => ({
      ...period,
      files: period.files.map((file) => ({
        ...file,
        upload_date:
          filesUploadedDates.find((f: any) => f.key === file.file_key)
            ?.created || '',
      })),
    }));
  };

  const setFileUsageState = async (
    filesWithUploadDates: Array<filesUsageShape>,
    offset: number
  ): Promise<void> => {
    if (offset !== 0) {
      const filesUsageState = userState.data?.isActive
        ? userState.data?.fileUsage
        : userState.currentOrganization?.fileUsage;
      filesWithUploadDates.map((period: filesUsageShape) => {
        const temp = filesUsageState?.find(
          (currentPeriodState: filesUsageShape) =>
            currentPeriodState.period_end === period.period_end &&
            currentPeriodState.period_start === period.period_start
        );
        if (temp) period.files.unshift(...temp.files);
      });
    }
    if (userState.data?.isActive) {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          fileUsage: filesWithUploadDates,
        },
      }));
    } else {
      setUserState((prevState: any) => ({
        ...prevState,
        currentOrganization: {
          ...prevState.currentOrganization,
          fileUsage: filesWithUploadDates,
        },
      }));
    }
  };

  const getUsage = async (): Promise<void> => {
    const usage = await callApi<any>('billing/overview', 'GET');
    if (userState.data?.isActive) {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          usage,
        },
      }));
    } else {
      setUserState((prevState: any) => ({
        ...prevState,
        currentOrganization: {
          ...prevState.currentOrganization,
          usage,
        },
      }));
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      await getUsage();
      await getFileUsage(0);
    })();
  }, []);

  useEffect(() => {
    !userState.data?.loggedIn && navigate('/');
  }, [userState]);

  useEffect(() => {
    if (userState.data?.isActive) {
      setUsageData(userState.data?.usage);
    } else {
      setUsageData(userState.currentOrganization?.usage);
    }
  }, [
    userState.data?.isActive,
    userState.data?.usage,
    userState.currentOrganization?.usage,
  ]);

  useEffect(() => {
    if (userState.data?.isActive) {
      setFileUsageData(userState.data?.fileUsage || []);
    } else {
      setFileUsageData(userState.currentOrganization?.fileUsage || []);
    }
  }, [
    userState.data?.isActive,
    userState.data?.fileUsage,
    userState.currentOrganization?.fileUsage,
  ]);

  const selectFile = (x: any): void => {
    const fileArr = userState?.data?.isActive
      ? userState?.data?.fileUsage?.[0]?.files
      : userState?.currentOrganization?.fileUsage?.[0]?.files;
    if (fileArr) {
      const selected: fileUsageShape = fileArr[x.target.id] as fileUsageShape;
      setSelectedFile(selected as fileUsageShape);
      setIsOpen(true);
    }
  };

  return (
    <div className='Usage'>
      <Header />
      <div className='Usage--center'>
        <div className='Usage--title'>Billing</div>
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          position='RIGHT'
          width='REGULAR'
        >
          <div className='Header--newfolder'>
            <Heading
              title={selectedFile?.file_name || ''}
              subtitle={`Usage date from ${
                usageData?.billing_data?.[0]?.period_name?.split(' - ')[0]
              } to ${
                usageData?.billing_data?.[0]?.period_name?.split(' - ')[1]
              } .`}
              rightTitle={`Upload Date: ${formattedDate(
                new Date(selectedFile?.upload_date || ''),
                uploadDateOptions
              )}`}
            />
            <div>
              <br></br>
              <br></br>
              <SideDataGrid bottomArrow description='Download'>
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Number of Downloads: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: ` ${selectedFile?.downloads_count}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Size: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: ` ${readableBytes(
                          selectedFile?.download_size_bytes!
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Cost: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `$ ${centsToDollars(
                          selectedFile?.download_price_cents!,
                          6
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
              </SideDataGrid>
              <SideDataGrid description='Storage' bottomArrow>
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Size: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `${readableBytes(
                          selectedFile?.storage_size_bytes!
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Days: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `${(
                          (selectedFile?.stored_milliseconds || 0) /
                          (24 * 60 * 60 * 1000)
                        ).toFixed(1)}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Cost: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `$ ${centsToDollars(
                          selectedFile?.storage_price_cents!,
                          6
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
              </SideDataGrid>
              <SideDataGrid description='Upload' bottomArrow>
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Size: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `${readableBytes(
                          selectedFile?.upload_size_bytes!
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Cost: ',
                        weight: 'normal',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `$ ${centsToDollars(
                          selectedFile?.upload_price_cents!,
                          6
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
              </SideDataGrid>
              <SideDataGrid title>
                <SideDataGridItem
                  data={{
                    display: [
                      {
                        value: 'Total cost: ',
                        weight: 'bold',
                        align: 'right',
                        width: '1fr',
                      },
                      {
                        value: `$ ${centsToDollars(
                          selectedFile?.total_price_cents!,
                          6
                        )}`,
                        weight: 'bold',
                        align: 'left',
                        width: '1fr',
                      },
                    ],
                  }}
                />
              </SideDataGrid>
            </div>
          </div>
        </SideMenu>
        <div className='Usage--grid'>
          <div>
            <Menu items={billingNavItems} selected='Usage' />
          </div>
          <div>
            <div className='Usage--info'>
              <div className='Usage--info-title'>
                <InvoicesDropdown
                  usageBillingData={usageData?.billing_data}
                  filesUsageData={fileUsageData}
                />
                {usageData?.next_billing_date && (
                  <div className='Usage--next-invoice'>
                    Next Invoice:{' '}
                    <b>
                      <DateTime
                        showTime
                        value={new Date(usageData?.next_billing_date!)}
                      />
                    </b>
                  </div>
                )}
              </div>
              <div className='Usage--info-title-subtext'>Cost per GB</div>
              <div className='Usage--rate-grid'>
                <div className='Usage--rate-item'>
                  <div className='Usage--rate-title'>
                    Storage {usageData?.billing_data?.[0]?.storage_month_gb}{' '}
                  </div>
                  <div className='Usage--rate-value'>
                    ${usageData?.current_rates?.storage || 0}
                  </div>
                </div>
                <div className='Usage--rate-item'>
                  <div className='Usage--rate-title'>
                    Upload{' '}
                    {usageData?.billing_data?.[0]?.uploaded_month_gb?.toFixed(
                      4
                    )}
                  </div>
                  <div className='Usage--rate-value'>
                    ${usageData?.current_rates?.upload || 0}
                  </div>
                </div>
                <div className='Usage--rate-item'>
                  <div className='Usage--rate-title'>
                    Download{' '}
                    {usageData?.billing_data?.[0]?.downloaded_month_gb?.toFixed(
                      4
                    )}
                  </div>
                  <div className='Usage--rate-value'>
                    ${usageData?.current_rates?.download || 0}
                  </div>
                </div>
              </div>
              <div className='Usage--info-title-subtext'>Total cost</div>
              <div className='Usage--rate-grid'>
                <div className='Usage--rate-item'>
                  <div className='Usage--rate-title'>
                    Storage {usageData?.billing_data?.[0]?.storage_month_gb}
                  </div>
                  <div className='Usage--rate-value'>
                    $
                    {centsToDollars(
                      usageData?.billing_data?.[0].storage_price_us_cents || 0
                    )}
                  </div>
                </div>
                <div className='Usage--rate-item'>
                  <div className='Usage--rate-title'>
                    Upload{' '}
                    {usageData?.billing_data?.[0]?.uploaded_month_gb?.toFixed(
                      4
                    )}
                  </div>
                  <div className='Usage--rate-value'>
                    $
                    {centsToDollars(
                      usageData?.billing_data?.[0].upload_price_us_cents || 0.0
                    )}
                  </div>
                </div>
                <div className='Usage--rate-item'>
                  <div className='Usage--rate-title'>
                    Download{' '}
                    {usageData?.billing_data?.[0]?.downloaded_month_gb?.toFixed(
                      4
                    )}
                  </div>
                  <div className='Usage--rate-value'>
                    $
                    {centsToDollars(
                      usageData?.billing_data?.[0].download_price_us_cents ||
                        0.0
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='Users--info'>
              <div className='Users--info-title'>
                <div className='Users--info-title-text'>Files</div>
              </div>
              {fileUsageData?.[0]?.files && (
                <div className='Users--info-header'>
                  <div>Name</div>
                  <div>Size</div>
                  <div>Cost</div>
                </div>
              )}
              <div className='Usage--scroll' onScroll={handleScroll}>
                {fileUsageData?.[0]?.files?.map((key: any, index: any) => (
                  <div
                    id={index}
                    key={index}
                    className='Users--info-row'
                    onClick={selectFile}
                  >
                    <div>{key.file_name}</div>
                    <div>{readableBytes(key.storage_size_bytes)}</div>
                    <div>${(key.total_price_cents! / 100).toFixed(6)}</div>
                  </div>
                ))}
              </div>
              {(!fileUsageData || fileUsageData.length === 0) && (
                <div className='Users--none'>
                  No file usage has been recorded since your last invoice.
                </div>
              )}

              {fileUsageData?.[0]?.files &&
                fileUsageData?.[0]?.files.length > 0 && (
                  <div
                    onClick={async (): Promise<void> =>
                      await handleDownload(
                        fileUsageData?.[0].period_start,
                        fileUsageData?.[0].period_end
                      )
                    }
                    style={{
                      padding: '12px',
                      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                      fontWeight: '700',
                      cursor: 'pointer',
                    }}
                  >
                    Download CSV
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Usage;
