import { ReactElement } from 'react';
import { Header } from '../../components/Header';
import { Title } from '../../components/Title';
import { Footer } from '../../components/Footer';
import { Background } from '../../components/Background';

function PrivacyPolicy(): ReactElement {
  return (
    <div className='About'>
      <Header />
      <Background color='#F8FAFC'>
        <>
          <Title
            title='Terms & Policies'
            subTitle='Find out where you stand with dStor'
          />
        </>
      </Background>

      <Background color='#FFFFFF'>
        <div className='About--center'>
          <ul>
            <a href='/about/terms-of-use'>
              <li>Terms of Use</li>
            </a>
            <a href='/about/privacy-policy'>
              <li>Privacy Policy</li>
            </a>
          </ul>
          <div>
            <div className='About--doc-title'>Privacy Policy</div>
            <p>Last updated 15 Nov 2022</p>
            <p>
              Thank you for being a dStor customer, brought to you by the team
              at GoodBlock Technologies, Inc. ("Company", "we", "us", "our",
              “dStor”). We are committed to protecting your personal information
              and your right to privacy. If you have any questions or concerns
              about this privacy policy, or our practices with regards to your
              personal information, please contact us at{' '}
              <a href='mailto:info@goodblock.io'>info@goodblock.io</a>.
            </p>
            <p>
              When you visit dstor.cloud (the "Website") and more generally, use
              any of our services (the "Services"), we acknowledge and
              appreciate that you are trusting us with your personal
              information. We take your privacy very seriously. In this privacy
              policy, we seek to explain to you in the clearest way possible
              what information we collect, how we use it and what rights you
              have in relation to it. We hope you take some time to read through
              it carefully, as it is important. If there are any terms in this
              privacy policy that you do not agree with, please discontinue use
              of our Services immediately.
            </p>
            <p>
              This privacy policy applies to all information collected through
              our Services as well as, any related services, sales, marketing or
              events.
            </p>
            <p>
              Please read this privacy policy carefully as it will help you
              understand what we do with the information that we collect.
            </p>
            <div className='About--section-title'>
              WHAT INFORMATION DO WE COLLECT?
            </div>
            <div className='About--section-subtitle'>
              Personal information you disclose to us
            </div>
            <p>
              In Short: We collect personal information that you provide to us.
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when you express an interest in obtaining information about us or
              our products and Services, when you participate in activities on
              the Services (such as posting messages in our online forums or
              entering competitions, contests or giveaways) or otherwise when
              you contact us.
            </p>
            <p>
              The personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make
              and the products and features you use. The personal information we
              collect may include the following:
            </p>
            <ul>
              <li>
                Personal Information provided by you. We may collect names;
                email addresses; and other similar information.
              </li>
              <li>
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
              </li>
            </ul>
            <div className='About--section-subtitle'>
              Information automatically collected
            </div>
            <p>
              In Short: Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — may be
              collected automatically when you visit dstor.cloud and/or use our
              Services.
            </p>
            <p>
              We may automatically collect certain information when you visit,
              use or navigate the Services. This information does not reveal
              your specific identity (like your name or contact information) but
              may include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
            <p>The information we collect may include:</p>
            <ul>
              <li>
                Log and Usage Data. Log and usage data is service-related,
                diagnostic, usage and performance information our servers
                automatically collect when you access or use our Services and
                which we record in log files. Depending on how you interact with
                us, this log data may include your IP address, device
                information, browser type and settings and information about
                your activity in the Services (such as the date/time stamps
                associated with your usage, pages and files viewed, searches and
                other actions you take such as which features you use), device
                event information (such as system activity, error reports
                (sometimes called 'crash dumps') and hardware settings).
              </li>
              <li>
                Device Data. We may collect device data such as information
                about your computer, phone, tablet or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model, Internet service
                provider and/or mobile carrier, operating system and system
                configuration information.
              </li>
              <li>
                Location Data. We may collect location data such as information
                about your device's location, which can be either precise or
                imprecise. How much information we collect depends on the type
                and settings of the device you use to access the Services. For
                example, we may use GPS and other technologies to collect
                geolocation data that tells us your current location (based on
                your IP address). You can opt out of allowing us to collect this
                information either by refusing access to the information or by
                disabling your Location setting on your device. Note however, if
                you choose to opt out, you may not be able to use certain
                aspects of the Services.
              </li>
            </ul>
            <div className='About--section-title'>
              HOW DO WE USE YOUR INFORMATION?
            </div>
            <p>
              In Short: We process your information for purposes based on
              legitimate business interests, the fulfillment of our contract
              with you, compliance with our legal obligations, and/or your
              consent.
            </p>
            <p>
              We may use personal information collected via our Services for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </p>
            <p>We may use the information we collect or receive:</p>
            <ul>
              <li>
                To facilitate account creation and logon process. If you choose
                to link your account with us to a third-party account (such as
                your Anchor or Google account), we use the information you
                allowed us to collect from those third parties to facilitate
                account creation and logon process for the performance of the
                contract.
              </li>
              <li>
                To post testimonials. We may post testimonials on our Services
                that may contain personal information. Prior to posting a
                testimonial, we will obtain your consent to use your name and
                the content of the testimonial. If you wish to update, or delete
                your testimonial, please contact us at
                <a href='mailto:info@goodblock.io'> info@goodblock.io</a> and be
                sure to include your name, testimonial location, and contact
                information.
              </li>
              <li>
                Request feedback. We may use your information to request
                feedback and to contact you about your use of our Services.
              </li>
              <li>
                To enable user-to-user communications. We may use your
                information in order to enable user-to-user communications with
                each user's consent.
              </li>
              <li>
                To manage user accounts. We may use your information for the
                purposes of managing your account and keeping it in working
                order.
              </li>
              <li>
                To send administrative information to you. We may use your
                personal information to send you product, service and new
                feature information and/or information about changes to our
                terms, conditions, and policies.
              </li>
              <li>
                To protect our Services. We may use your information as part of
                our efforts to keep our Services safe and secure (for example,
                for fraud monitoring and prevention).
              </li>
              <li>
                To enforce our terms, conditions and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
              </li>
              <li>
                To respond to legal requests and prevent harm. If we receive a
                subpoena or other legal request, we may need to inspect the data
                we hold to determine how to respond.
              </li>
              <li>
                Fulfill and manage your orders. We may use your information to
                fulfill and manage your orders, payments, returns, and exchanges
                made through the Services.
              </li>
              <li>
                Administer prize draws and competitions. We may use your
                information to administer prize draws and competitions when you
                elect to participate in our competitions.
              </li>
              <li>
                To deliver and facilitate delivery of services to the user. We
                may use your information to provide you with the requested
                service.
              </li>
              <li>
                To respond to user inquiries/offer support to users. We may use
                your information to respond to your inquiries and solve any
                potential issues you might have with the use of our Services.
              </li>
              <li>
                To send you marketing and promotional communications. We and/or
                our third-party marketing partners may use the personal
                information you send to us for our marketing purposes, if this
                is in accordance with your marketing preferences. For example,
                when expressing an interest in obtaining information about us or
                our Services, subscribing to marketing or otherwise contacting
                us, we will collect personal information from you. You can
                opt-out of our marketing emails at any time (see the "WHAT ARE
                YOUR PRIVACY RIGHTS?" below).
              </li>
              <li>
                Deliver targeted advertising to you. We may use your information
                to develop and display personalized content and advertising (and
                work with third parties who do so) tailored to your interests
                and/or location and to measure its effectiveness.
              </li>
              <li>
                For other business purposes. We may use your information for
                other business purposes, such as data analysis, identifying
                usage trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Services, products,
                marketing and your experience. We may use and store this
                information in aggregated and anonymized form so that it is not
                associated with individual end users and does not include
                personal information. We will not use identifiable personal
                information without your consent.
              </li>
            </ul>
            <div className='About--section-title'>
              WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </div>
            <p>
              In Short: We only share information with your consent, to comply
              with laws, to provide you with services, to protect your rights,
              or to fulfill business obligations.
            </p>
            <p>
              We may process or share your data that we hold based on the
              following legal basis:
            </p>
            <ul>
              <li>
                Consent: We may process your data if you have given us specific
                consent to use your personal information for a specific purpose.
              </li>
              <li>
                Legitimate Interests: We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
              </li>
              <li>
                Performance of a Contract: Where we have entered into a contract
                with you, we may process your personal information to fulfill
                the terms of our contract.
              </li>
              <li>
                Legal Obligations: We may disclose your information where we are
                legally required to do so in order to comply with applicable
                law, governmental requests, a judicial proceeding, court order,
                or legal process, such as in response to a court order or a
                subpoena (including in response to public authorities to meet
                national security or law enforcement requirements).
              </li>
              <li>
                Vital Interests: We may disclose your information where we
                believe it is necessary to investigate, prevent, or take action
                regarding potential violations of our policies, suspected fraud,
                situations involving potential threats to the safety of any
                person and illegal activities, or as evidence in litigation in
                which we are involved.
              </li>
            </ul>
            <p>
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </p>
            <ul>
              <li>
                Business Transfers. We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </li>
              <li>
                Affiliates. We may share your information with our affiliates,
                in which case we will require those affiliates to honor this
                privacy policy. Affiliates include our parent company and any
                subsidiaries, joint venture partners or other companies that we
                control or that are under common control with us.
              </li>
              <li>
                Other Users. When you share personal information (for example,
                by posting comments, contributions or other content to the
                Services) or otherwise interact with public areas of the
                Services, such personal information may be viewed by all users
                and may be publicly made available outside the Services in
                perpetuity. Similarly, other users will be able to view
                descriptions of your activity, communicate with you within our
                Services, and view your profile.
              </li>
            </ul>
            <div className='About--section-title'>
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </div>
            <p>
              In Short: We may use cookies and other tracking technologies to
              collect and store your information, but do not at this time
              outside of our payment provider.
            </p>
            <p>
              We may use cookies and similar tracking technologies to access or
              store information. Currently we do not use any of these
              technologies in any way to track users. Users who opt to utilize
              services that require payment are subject to the our payment
              provider cookies policy found here.
              <a href='https://stripe.com/legal/cookies-policy' target='_blank'>
                https://stripe.com/legal/cookies-policy
              </a>
            </p>
            <div className='About--section-title'>
              HOW LONG DO WE KEEP YOUR INFORMATION?
            </div>
            <div>
              In Short: We may keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy policy unless
              otherwise required by law.
            </div>
            <div>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy policy, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              policy will require us keeping your personal information for
              longer than 6 months.
            </div>
            <div>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </div>
            <div className='About--section-title'>
              HOW DO WE KEEP YOUR INFORMATION SAFE?
            </div>
            <p>
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures.
            </p>
            <p>
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security, and improperly collect,
              access, steal, or modify your information. Although we will do our
              best to protect your personal information, transmission of
              personal information to and from our Services is at your own risk.
              You should only access the Services within a secure environment.
            </p>
            <div className='About--section-title'>
              DO WE COLLECT INFORMATION FROM MINORS?
            </div>
            <p>
              In Short: We do not knowingly collect data from or market to
              children under 18 years of age.
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at
              <a href='mailto:info@goodblock.io'> info@goodblock.io</a>.
            </p>
            <div className='About--section-title'>
              WHAT ARE YOUR PRIVACY RIGHTS?
            </div>
            <p>
              In Short: In some regions, such as the European Economic Area
              (EEA) and United Kingdom (UK), you have rights that allow you
              greater access to and control over your personal information. You
              may review, change, or terminate your account at any time.
            </p>
            <p>
              In some regions (like the EEA and UK), you have certain rights
              under applicable data protection laws. These may include the right
              (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. To make such a request, please use the contact
              details provided below. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </p>
            <p>
              If we are relying on your consent to process your personal
              information, you have the right to withdraw your consent at any
              time. Please note however that this will not affect the lawfulness
              of the processing before its withdrawal, nor will it affect the
              processing of your personal information conducted in reliance on
              lawful processing grounds other than consent.
            </p>
            <p>
              If you are a resident in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:
              <a href='http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
              </a>
            </p>
            <p>
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available here:
              <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>
                https://www.edoeb.admin.ch/edoeb/en/home.html
              </a>
              .
            </p>
            <p>
              Cookies and similar technologies: Most Web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Services. To opt-out of
              interest-based advertising by advertisers on our Services visit
              <a href='http://www.aboutads.info/choices/'>
                http://www.aboutads.info/choices/
              </a>
              .
            </p>
            <div className='About--section-title'>
              CONTROLS FOR DO-NOT-TRACK FEATURES
            </div>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy policy.{' '}
            </p>
            <div className='About--section-title'>
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </div>
            <p>
              In Short: Yes, if you are a resident of California, you are
              granted specific rights regarding access to your personal
              information.
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with a Service, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </p>
            <div className='About--section-subtitle'>CCPA Privacy policy</div>
            <p>The California Code of Regulations defines a "resident" as:</p>
            <ul>
              <li>
                every individual who is in the State of California for other
                than a temporary or transitory purpose and
              </li>
              <li>
                every individual who is domiciled in the State of California who
                is outside the State of California for a temporary or transitory
                purpose.
              </li>
            </ul>
            <p>
              All other individuals are defined as "non-residents." If this
              definition of "resident" applies to you, we must adhere to certain
              rights and obligations regarding your personal information.
            </p>
            <p>What categories of personal information do we collect?</p>
            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Examples</th>
                  <th>Collected</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A. Identifiers</td>
                  <td>
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address and account name
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>
                    B. Personal information categories listed in the California
                    Customer Records statute
                  </td>
                  <td>
                    Name, contact information, education, employment, employment
                    history and financial information
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    C. Protected classification characteristics under California
                    or federal law
                  </td>
                  <td>Gender and date of birth</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>D. Commercial information</td>
                  <td>
                    Transaction information, purchase history, financial details
                    and payment information
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>E. Biometric information</td>
                  <td>Fingerprints and voiceprints</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>F. Internet or other similar network activity</td>
                  <td>
                    Browsing history, search history, online behavior, interest
                    data, and interactions with our and other websites,
                    applications, systems and advertisements
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>G. Geolocation data</td>
                  <td>Device location</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    H. Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </td>
                  <td>
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>I. Professional or employment-related information</td>
                  <td>
                    Business contact details in order to provide you our
                    services at a business level, job title as well as work
                    history and professional qualifications if you apply for a
                    job with us
                  </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>J. Education Information</td>
                  <td>Student records and directory information</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>K. Inferences drawn from other personal information</td>
                  <td>
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individual’s preferences and
                    characteristics
                  </td>
                  <td>No</td>
                </tr>
              </tbody>
            </table>
            <p>
              We may also collect other personal information outside of these
              categories instances where you interact with us in-person, online,
              or by phone or mail in the context of:
            </p>
            <ul>
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests; and</li>
              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>
            <p>How do we use and share your personal information?</p>
            <p>
              More information about our data collection and sharing practices
              can be found in this privacy policy.
            </p>
            <p>
              You may contact us by email at{' '}
              <a href='mailto:info@goodblock.io'>info@goodblock.io</a>, or by
              referring to the contact details at the bottom of this document.
            </p>
            <p>
              If you are using an authorized agent to exercise your right to
              opt-out we may deny a request if the authorized agent does not
              submit proof that they have been validly authorized to act on your
              behalf.
            </p>
            <p>Will your information be shared with anyone else?</p>
            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </p>
            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal data.
            </p>
            <p>
              GoodBlock Technologies, Inc. has not disclosed or sold any
              personal information to third parties for a business or commercial
              purpose in the preceding 12 months. dStor will not sell personal
              information in the future belonging to website visitors, users and
              other consumers.
            </p>
            <div className='About--section-subtitle'>
              Your rights with respect to your personal data
            </div>
            <p>
              Right to request deletion of the data - Request to delete You can
              ask for the deletion of your personal information. If you ask us
              to delete your personal information, we will respect your request
              and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation or
              any processing that may be required to protect against illegal
              activities. To request deletion of personal information, contact
              <a href='mailto:info@goodblock.io'>info@goodblock.io</a>.
            </p>
            <p>
              Right to be informed - Request to know Depending on the
              circumstances, you have a right to know:
            </p>
            <ul>
              <li>whether we collect and use your personal information;</li>
              <li>the categories of personal information that we collect;</li>
              <li>
                the purposes for which the collected personal information is
                used;
              </li>
              <li>
                whether we sell your personal information to third parties;
              </li>
              <li>
                the categories of personal information that we sold or disclosed
                for a business purpose;
              </li>
              <li>
                the categories of third parties to whom the personal information
                was sold or disclosed for a business purpose; and
              </li>
              <li>
                the business or commercial purpose for collecting or selling
                personal information.
              </li>
            </ul>
            <p>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </p>
            <p>
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights We will not discriminate against you if you
              exercise your privacy rights.
            </p>
            <p>Verification process</p>
            <p>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g. phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </p>
            <p>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. If, however, we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity, and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </p>
            <p>Other privacy rights</p>
            <ul>
              <li>you may object to the processing of your personal data</li>
              <li>
                you may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the data
              </li>
              <li>
                you can designate an authorized agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorized agent that does not submit proof that they have been
                validly authorized to act on your behalf in accordance with the
                CCPA.
              </li>
              <li>
                you may request to opt-out from future selling of your personal
                information to third parties. Upon receiving a request to
                opt-out, we will act upon the request as soon as feasibly
                possible, but no later than 15 days from the date of the request
                submission.
              </li>
            </ul>
            <p>
              To exercise these rights, you can contact us by email at
              info@goodblock.io, or by referring to the contact details at the
              bottom of this document. If you have a complaint about how we
              handle your data, we would like to hear from you.
            </p>
            <div className='About--section-title'>
              DO WE MAKE UPDATES TO THIS POLICY?
            </div>
            <p>
              In Short: Yes, we will update this policy as necessary to stay
              compliant with relevant laws.
            </p>
            <p>
              We may update this privacy policy from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy policy, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy policy frequently to be informed of how we are
              protecting your information.
            </p>
            <div className='About--section-title'>
              HOW CAN YOU CONTACT US ABOUT THIS POLICY?
            </div>
            <p>
              If you have questions or comments about this policy, you may email
              us at <a href='mailto:info@goodblock.io'>info@goodblock.io</a> or
              by mail to:
            </p>
            <p>
              <b>GoodBlock Technologies, Inc.</b>
              <br /> 1420 NW Gilman Blvd #2264 <br />
              Issaquah, WA 98027 <br />
              United States
            </p>
            <div className='About--section-title'>
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </div>
            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please send an email to{' '}
              <a href='mailto:info@goodblock.io'>info@goodblock.io</a>.
            </p>
          </div>
        </div>
        <Footer />
      </Background>
    </div>
  );
}

export default PrivacyPolicy;
