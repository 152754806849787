import { ReactElement, useRef, Dispatch, SetStateAction } from 'react';
import { CSSTransition } from 'react-transition-group';

export interface PropsShape {
  children: ReactElement;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  position?: 'LEFT' | 'CENTER' | 'RIGHT';
  width?: 'NARROW' | 'REGULAR' | 'SUPER';
  backgroundColor?: string;
}

const SideMenu = ({
  children,
  isOpen,
  setIsOpen,
  position = 'CENTER',
  width = 'NARROW',
  backgroundColor = '#FFFFFF',
}: PropsShape): ReactElement => {
  const menuTransition = useRef(null);
  const lowerPosition = position.toLowerCase();
  const lowerWidth = width.toLowerCase();

  return (
    <CSSTransition
      nodeRef={menuTransition}
      in={isOpen}
      timeout={width === 'NARROW' ? 300 : 400}
      classNames={`SideMenu--transition`}
      unmountOnExit
    >
      <div
        className='SideMenu'
        ref={menuTransition}
        onMouseDown={(): void => {
          setIsOpen(false);
        }}
      >
        <div
          className={`SideMenu--menu-${lowerPosition}-${lowerWidth}`}
          style={{ backgroundColor }}
        >
          <div
            onMouseDown={(e: any): void => {
              e.stopPropagation();
            }}
            style={{ height: '100%', cursor: 'default' }}
          >
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default SideMenu;
