import { ReactElement } from 'react';

export interface PropsShape {
  color: string;
  image?: any;
  children: any;
  borderTop?: boolean;
}

const Background = ({
  color,
  children,
  image,
  borderTop,
}: PropsShape): ReactElement => {
  return (
    <div
      className='Background'
      style={{
        backgroundColor: `${color}`,
        backgroundImage: image ? `url(${image})` : 'none',
        borderTop: borderTop ? '1px solid #E5E5E5' : 'none',
      }}
    >
      {children}
    </div>
  );
};
export { Background };
